import React from 'react';
import LicenseGroupPageWrapper from 'app/components/LicenseGroupPageWrapper';
import ContactUsPage from './ContactUsPage';

export const AdminContactUsPageConfig = {
	settings: {
		layout: {
			config: {
				adminPage: true
			}
		}
	},
	routes: [
		{
			path: '/admin/contact-us',
			exact: true,
			component: () => (
				<LicenseGroupPageWrapper>
					<ContactUsPage />
				</LicenseGroupPageWrapper>
			)
		}
	]
};

export const PublicContactUsPageConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	routes: [
		{
			path: '/contact-us',
			exact: true,
			component: React.lazy(() => import('./ContactUsPage'))
		}
	]
};