import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import React from 'react';

const useStyles = makeStyles(theme => ({
	button: {
		'&.MuiButton-root': {
			padding: '0 16px',
			height: 36.5
		},
		'&.MuiButton-outlinedSecondary': {
			border: '1px solid rgba(102, 63, 128, 0.5)'
		},
		'&.MuiButton-outlinedSecondary.Mui-disabled': {
			background: 'transparent', // 'rgba(0, 0, 0, 0.12)',
			// opacity: 0.87,
			borderColor: 'rgba(0, 0, 0, 0.26)' // 'transparent'
		},
		'&.MuiButton-outlinedSecondary:hover': {
			border: '1px solid #a48adb',
			background: '#a48adb',
			color: 'white'
		},
		'&.MuiButton-containedSecondary': {
			// border: '1px solid rgba(102, 63, 128, 0.5)'
		},
		'&.MuiButton-containedSecondary.Mui-disabled': {
			opacity: 0.87
			// borderColor: 'transparent'
		},
		'&.MuiButton-containedSecondary:hover': {
			// border: '1px solid #a48adb',
			background: '#a48adb'
		}
	}
}));

type Props = {
	btnText: any | undefined;
	onClick?: Function;
	btnType?: 'primary' | 'secondary';
	leftIcon?: React.ReactNode;
	rightIcon?: React.ReactNode;
	disabled?: boolean;
	className?: string;
	style?: object;
};

function StandardButton(props: Props) {
	const classes = useStyles();

	return (
		<Button
			className={`${props.className} ${classes.button}`}
			color="secondary"
			variant={props.btnType === 'secondary' ? 'outlined' : 'contained'}
			onClick={(e: any) => {
				if (props.onClick) {
					e.preventDefault();
					props.onClick(e);
				}
			}}
			// autoFocus={props.btnType !== 'secondary'}
			startIcon={props.leftIcon}
			endIcon={props.rightIcon}
			disabled={props.disabled}
			style={props.style}
		>
			{props.btnText}
		</Button>
	);
}

export default StandardButton;
