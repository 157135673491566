import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import clsx from 'clsx';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';

type Props = {
	endDate: moment.Moment | Parameters<typeof moment>[0];
	onComplete?: () => void;
	className?: string;
};
function FuseCountdown(props: Props) {
	const { onComplete } = props;
	const { t } = useTranslation();
	const [endDate] = useState(moment.isMoment(props.endDate) ? props.endDate : moment(props.endDate));
	const [countdown, setCountdown] = useState<{
		days?: string | number;
		hours?: string | number;
		minutes?: string | number;
		seconds?: string | number;
	}>({
		days: undefined,
		hours: undefined,
		minutes: undefined,
		seconds: undefined
	});
	const intervalRef = useRef<any>();

	const complete = useCallback(() => {
		window.clearInterval(intervalRef.current);
		if (onComplete) {
			onComplete();
		}
	}, [onComplete]);

	const tick = useCallback(() => {
		const currDate = moment();
		const diff = endDate.diff(currDate, 'seconds');
		if (diff < 0) {
			complete();
			return;
		}
		const timeLeft = moment.duration(diff, 'seconds');
		setCountdown({
			days: timeLeft.asDays().toFixed(0),
			hours: timeLeft.hours(),
			minutes: timeLeft.minutes(),
			seconds: timeLeft.seconds()
		});
	}, [complete, endDate]);

	useEffect(() => {
		intervalRef.current = setInterval(tick, 1000);
		return () => {
			clearInterval(intervalRef.current);
		};
	}, [tick]);

	return (
		<div className={clsx('flex items-center', props.className)}>
			<div className="flex flex-col items-center justify-center px-12">
				<Typography variant="h4" className="mb-4">
					{countdown.days ?? <Skeleton width="1em" variant="rect" />}
				</Typography>
				<Typography variant="caption" color="textSecondary">
					{t('time:days')}
				</Typography>
			</div>
			<div className="flex flex-col items-center justify-center px-12">
				<Typography variant="h4" className="mb-4">
					{countdown.hours ?? <Skeleton width="1em" variant="rect" />}
				</Typography>
				<Typography variant="caption" color="textSecondary">
					{t('time:hours')}
				</Typography>
			</div>
			<div className="flex flex-col items-center justify-center px-12">
				<Typography variant="h4" className="mb-4">
					{countdown.minutes ?? <Skeleton width="1em" variant="rect" />}
				</Typography>
				<Typography variant="caption" color="textSecondary">
					{t('time:minutes')}
				</Typography>
			</div>
			<div className="flex flex-col items-center justify-center px-12">
				<Typography variant="h4" className="mb-4">
					{countdown.seconds ?? <Skeleton width="1em" variant="rect" />}
				</Typography>
				<Typography variant="caption" color="textSecondary">
					{t('time:seconds')}
				</Typography>
			</div>
		</div>
	);
}

FuseCountdown.propTypes = {
	endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.number]).isRequired,
	onComplete: PropTypes.func
};

FuseCountdown.defaultProps = {
	endDate: moment().add(15, 'days')
};

export default React.memo(FuseCountdown);
