// Despite the name, this is being used for all admin pages

import FuseLoading from '@fuse/core/FuseLoading';
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'app/modules/react-redux';
import {
	getProfile,
	getSelectedLicenseGroupData
} from 'app/store/reducers';
import ExpiredView from 'app/components/ExpiredView';
import ExpiredBanner from 'app/components/ExpiredBanner';
import Error404Page from 'app/main/error404/Error404Page';
import * as Actions from 'app/store/actions';
import usePrevious from 'app/hooks/usePrevious';

type Status = 'loading' | 'success' | 404 | 403;

type Props = {
	children: React.ReactElement;
};

const LicenseGroupPageWrapper = ({ children }: Props) => {
	const location = useLocation();
	const dispatch = useDispatch();

	// const [onboardingUiOpen, setOnboardingUiOpen] = useState<any>(localStorage.getItem('mockNoRegion'));

	const [status, setStatus] = useState<Status>('loading');

	const profile = useSelector(getProfile) as ReturnType<typeof getProfile> | undefined; // HACK::use actual type here - assume never undefined within the wrapped app

	// const adminedLicenseGroups = useSelector(getAdminedLicenseGroups) as
	// 	| ReturnType<typeof getAdminedLicenseGroups>
	// 	| undefined; // HACK::use actual type here - assume never undefined within the wrapped app

	// const selectedLicenseGroupId = useSelector(getSelectedLicenseGroupId) as
	// 	| ReturnType<typeof getSelectedLicenseGroupId>
	// 	| undefined; // HACK::use actual type here - assume never undefined within the wrapped app

	const licenseGroupData = useSelector(getSelectedLicenseGroupData) as
		| ReturnType<typeof getSelectedLicenseGroupData>
		| undefined; // HACK::use actual type here - assume never undefined within the wrapped app

	const selectedLicenseGroupSlug = window.location.host.match(/^(.+)\.tenant\./)?.[1];

	const profileId = profile?.id; // DEV NOTE::need this primitive for the `useEffect` below (otherwise change the profile token triggers a re-fetch)

	useEffect(() => {
		if (!selectedLicenseGroupSlug || !profileId) {
			return;
		}

		(async () => {
			try {
				await dispatch(Actions.getSelectedLicenseGroupIdBySlug(selectedLicenseGroupSlug));
			} catch (error) {
				setStatus(404);
				return;
			}
			// DEV NOTE::we're treating a 403 from this endpoint as a 404 page because it contains basic tenant details. This maybe need to change?
			try {
				await dispatch(Actions.getSelectedLicenseGroupData());
				setStatus('success');
			} catch (error) {
				setStatus(403);
			}
		})();
	}, [dispatch, profileId, selectedLicenseGroupSlug]);

	const [windowIsFocused, setWindowIsFocused] = useState(true);
	const prevWindowIsFocused = usePrevious(windowIsFocused);

	const onFocus = () => {
		setWindowIsFocused(true);
	};

	const onBlur = () => {
		setWindowIsFocused(false);
	};

	useEffect(() => {
		window.addEventListener('focus', onFocus);
		window.addEventListener('blur', onBlur);

		return () => {
			window.removeEventListener('focus', onFocus);
			window.removeEventListener('blur', onBlur);
		};
	}, []);

	useEffect(() => {
		if (status !== 'success') {
			return;
		}

		if (windowIsFocused && !prevWindowIsFocused) {
			dispatch(Actions.getSelectedLicenseGroupData());
		}
	}, [dispatch, prevWindowIsFocused, status, windowIsFocused]);

	// useEffect(() => {
	// 	const getProfileLatest = async () => {
	// 		try {
	// 			// HACK-ish::need to get new values directly from API call to avoid race condition
	// 			// FIXME::why is `.then` not working?
	// 			const profileLatest: Profile | undefined = await dispatch(Actions.getProfile() as any).then(
	// 				(profileResponse: Profile | undefined) => profileResponse
	// 			);

	// 			if (!profileLatest) {
	// 				return;
	// 			}
	// 			if (profileLatest.tokens.expirationDate < Date.now()) {
	// 				throw new Error('Error: session token expiration date is less than current datetime');
	// 			}

	// 			const timeUntilNextCall = profileLatest.tokens.expirationDate - Date.now() + 1000; // add a second of buffer
	// 			setTimeout(getProfileLatest, timeUntilNextCall);
	// 		} catch (error) {
	// 			console.error(error);
	// 			console.error('Error: Failed to re-fetch profile');
	// 		}
	// 	};
	// 	getProfileLatest();
	// }, [dispatch]);

	if (!selectedLicenseGroupSlug) {
		return <Error404Page />;
	}

	if (!profile) {
		window.location.replace(`/api/sso/login?redirect=${encodeURIComponent(location.pathname)}`);
		return <FuseLoading />;
	}

	// if (onboardingUiOpen) {
	// 	return <Redirect to="/admin/onboarding" />;
	// }

	if (status === 404 || status === 403) {
		return <Error404Page />;
	}

	if (!licenseGroupData || !licenseGroupData.dataLoaded) {
		return <FuseLoading />;
	}

	return (
		<ExpiredView>
			<ExpiredBanner />
			{children}
		</ExpiredView>
	);
};

export default LicenseGroupPageWrapper;
