import localForage from 'localforage';
import i18n from 'i18n';
import _ from '@lodash';
import { fade } from '@material-ui/core/styles/colorManipulator';
import themesConfig from 'app/fuse-configs/themesConfig';
import { persistReducer } from 'redux-persist';
import { RootState } from 'app/store';
import { ProfileState, ProfileActionTypes, UserPreferences, languages } from 'app/store/types';
import * as Actions from '../actions';

const initialState: ProfileState = {
	info: undefined!, // HACK::assume never undefined and handle check only in <LicenseGroupWrapper /> and <ToolbarLayoutAdmin />
	previousProfileId: undefined
};

const profileReducer = (state = initialState, action: ProfileActionTypes) => {
	switch (action.type) {
		case Actions.GET_PROFILE_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				info: data?.user,
				previousProfileId: data?.user?.id ?? undefined
			};
		}
		case Actions.PURGE_STATE:
			return initialState;
		default: {
			return state;
		}
	}
};

export default persistReducer(
	{
		key: 'profile',
		storage: localForage,
		whitelist: ['previousProfileId'] // wipe user info on refresh
	},
	profileReducer
);

const currentLanguage = (languages.includes(i18n.language.slice(0, 2) as any)
	? i18n.language.slice(0, 2)
	: 'en') as UserPreferences['language'];

export const defaultUserPreferencesStarter: UserPreferences = {
	startPage: 'workflows',
	homePageLayout: 'rows',
	quickLinks: [undefined, undefined, undefined],
	appearance: 'light',
	siteBackground: 'colors/white.svg',
	tableHeaderBackground: 'standard',
	tableHeaderStandardBackgroundOpacity: 100,
	tableHeaderColorBackgroundOpacity: 100,
	pageHeaderBackgroundOpacity: 100,
	navbarBackgroundOpacity: 100,
	language: currentLanguage
};
export const defaultUserPreferencesEnterprise: UserPreferences = {
	startPage: 'home',
	homePageLayout: 'rows',
	quickLinks: [undefined, undefined, undefined],
	appearance: 'light',
	siteBackground: 'tropical-image.jpg',
	tableHeaderBackground: 'standard',
	tableHeaderStandardBackgroundOpacity: 80,
	tableHeaderColorBackgroundOpacity: 80,
	pageHeaderBackgroundOpacity: 80,
	navbarBackgroundOpacity: 80,
	language: currentLanguage
};

const getUserPreferencesValuesOrDefaults = (preferences: UserPreferences) => {
	return localStorage.getItem('enterprise')
		? !_.isEmpty(preferences)
			? preferences
			: defaultUserPreferencesEnterprise
		: defaultUserPreferencesStarter;
};

export const calculatedUserPreferences = (preferences: UserPreferences) => {
	const appearance =
		preferences.appearance === 'system'
			? window.matchMedia('(prefers-color-scheme: dark)').matches
				? 'dark'
				: 'light'
			: preferences.appearance;

	const lightBackgroundColor = themesConfig.default.palette.background.paper;
	const darkBackgroundColor = themesConfig.defaultDark.palette.background.paper;
	const backgroundColor = appearance === 'dark' ? darkBackgroundColor : lightBackgroundColor;

	return {
		startPage: preferences.startPage,
		homePageLayout: preferences.homePageLayout,
		quickLinks: preferences.quickLinks.filter(
			(quickLink): quickLink is NonNullable<UserPreferences['quickLinks'][number]> => !!quickLink
		),
		appearance,
		siteBackground:
			preferences.siteBackground === '%CUSTOM%'
				? // ? 'https://path-with-profile-id-change-me.com'
				  'http://placekitten.com/1920/1080'
				: `${process.env.PUBLIC_URL}/assets/images/featured-backgrounds/${preferences.siteBackground}`,
		tableHeaderBackgroundColors:
			preferences.tableHeaderBackground === 'standard'
				? [fade(backgroundColor, preferences.tableHeaderStandardBackgroundOpacity / 100)]
				: [
						fade('#663F80', preferences.tableHeaderColorBackgroundOpacity / 100),
						fade('#4CAF50', preferences.tableHeaderColorBackgroundOpacity / 100),
						fade('#FFE500', preferences.tableHeaderColorBackgroundOpacity / 100),
						fade('#198DDB', preferences.tableHeaderColorBackgroundOpacity / 100)
				  ],
		pageHeaderBackgroundColor: fade(backgroundColor, preferences.pageHeaderBackgroundOpacity / 100),
		navbarBackgroundColor: fade(backgroundColor, preferences.navbarBackgroundOpacity / 100),
		language: preferences.language
	};
};

export const getProfile = ({ profile }: RootState) => profile.info;
export const getUserPreferencesValues = ({ profile }: RootState) =>
	getUserPreferencesValuesOrDefaults(profile.info?.preferences);
export const getUserPreferences = (state: RootState) => {
	const preferences = getUserPreferencesValues(state);
	return calculatedUserPreferences(preferences);
};
export const getPreviousProfileId = ({ profile }: RootState) => profile.previousProfileId;
