import React from 'react';
import clsx from 'clsx';
import { useSelector, useDispatch } from 'app/modules/react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import {
	getSelectedLicenseGroupData,
	getHideExpiredBanner,
	getHideSuspendedBanner,
	getTimezoneOffset
} from 'app/store/reducers';
import { getLicenseCapacityExceeded, getLocalTime } from 'app/utils/helpers';
import { ExpiredIcon } from 'app/components/Icons';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Collapse from '@material-ui/core/Collapse';
import * as Actions from 'app/store/actions';
import { LicenseGroupData } from 'app/store/types';

const EXPIRED_WARNING_PERIOD: Record<LicenseGroupData['orderType'], number> = {
	PRODUCTION: 30 * (24 * 3600 * 1000), // 30 days
	DEMO: 15 * (24 * 3600 * 1000), // 15 days
	NFR: 30 * (24 * 3600 * 1000) // 30 days
};

const useStyles = makeStyles(theme => ({
	closeButtonWrapper: {
		position: 'absolute',
		top: 0,
		right: 0,
		margin: '0.2rem'
	},
	expiredIcon: {
		position: 'absolute',
		left: 0,
		top: '50%',
		transform: 'translateY(-50%)',
		marginLeft: '2rem',
		'& > svg': {
			fontSize: '4.4rem'
		}
	}
}));

const ExpiredBanner = () => {
	const { t } = useTranslation();
	const classes = useStyles();
	const dispatch = useDispatch();

	const licenseGroupData = useSelector(getSelectedLicenseGroupData);
	const hideExpiredBanner = useSelector(getHideExpiredBanner);
	const hideSuspendedBanner = useSelector(getHideSuspendedBanner);
	const timezoneOffset = useSelector(getTimezoneOffset);

	const isLicenseCapacityExceeded = licenseGroupData ? getLicenseCapacityExceeded(licenseGroupData) : false;

	if (licenseGroupData.expirationDate && Date.now() > licenseGroupData.expirationDate) {
		return (
			<AppBar position="static">
				<Toolbar className="flex flex-col w-full items-center py-6 bg-red-500">
					<Typography variant="h4" color="inherit" className="lg:px-60">
						{t('expired:title')}
					</Typography>
					<Typography variant="subtitle1" color="inherit" className="lg:px-60">
						{t('expired:message')} {t('expired:message subtext')}
					</Typography>
					<div className={clsx(classes.expiredIcon, 'invisible lg:visible')}>
						<ExpiredIcon />
					</div>
				</Toolbar>
			</AppBar>
		);
	}

	if (isLicenseCapacityExceeded) {
		return (
			<AppBar position="static">
				<Collapse in={!hideExpiredBanner}>
					<Toolbar className="flex flex-col w-full items-center py-6 bg-amber-600">
						<Typography variant="h4" color="inherit" className="lg:px-60">
							{t('free trial:usage limit exceed:title')}
						</Typography>
						<Typography variant="subtitle1" color="inherit" className="lg:px-60">
							{t('free trial:usage limit exceed:message')}{' '}
							{t('free trial:usage limit exceed:message subtext')}
						</Typography>
						<div className={classes.closeButtonWrapper}>
							<IconButton onClick={() => dispatch(Actions.hideExpiredBanner())} color="inherit">
								<CloseIcon />
							</IconButton>
						</div>
						<div className={clsx(classes.expiredIcon, 'invisible lg:visible')}>
							<ExpiredIcon />
						</div>
					</Toolbar>
				</Collapse>
			</AppBar>
		);
	}

	if (
		licenseGroupData.expirationDate &&
		Date.now() > licenseGroupData.expirationDate - EXPIRED_WARNING_PERIOD[licenseGroupData.orderType]
	) {
		return (
			<AppBar position="static">
				<Collapse in={!hideExpiredBanner}>
					<Toolbar className="flex flex-col w-full items-center py-6 bg-amber-600">
						<Typography variant="h4" color="inherit" className="lg:px-60">
							{t('expired soon:title')}
						</Typography>
						<Typography variant="subtitle1" color="inherit" className="lg:px-60">
							{t('expired soon:message', {
								date: getLocalTime(licenseGroupData.expirationDate, undefined, timezoneOffset)
							})}{' '}
							{t('expired:message subtext')}
						</Typography>
						<div className={classes.closeButtonWrapper}>
							<IconButton onClick={() => dispatch(Actions.hideExpiredBanner())} color="inherit">
								<CloseIcon />
							</IconButton>
						</div>
						<div className={clsx(classes.expiredIcon, 'invisible lg:visible')}>
							<ExpiredIcon />
						</div>
					</Toolbar>
				</Collapse>
			</AppBar>
		);
	}

	if (licenseGroupData.suspension?.viewOnly || licenseGroupData.suspension?.disableAccess) {
		return (
			<AppBar position="static">
				<Collapse in={!hideSuspendedBanner}>
					<Toolbar className="flex flex-col w-full items-center py-6 bg-red-500">
						<Typography variant="h4" color="inherit" className="lg:px-60">
							{t('suspended:title')}
						</Typography>
						<Typography variant="subtitle1" color="inherit" className="lg:px-60">
							{t('suspended:message')} {t('suspended:message subtext')}
						</Typography>
						<div className={classes.closeButtonWrapper}>
							<IconButton onClick={() => dispatch(Actions.hideSuspendedBanner())} color="inherit">
								<CloseIcon />
							</IconButton>
						</div>
						{/* <div className={clsx(classes.expiredIcon, 'invisible lg:visible')}>
							<ExpiredIcon />
						</div> */}
					</Toolbar>
				</Collapse>
			</AppBar>
		);
	}

	return <></>;
};

export default ExpiredBanner;
