import localForage from 'localforage';
import { persistReducer } from 'redux-persist';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { DevicesState, DevicesActionTypes } from 'app/store/types';
import * as Actions from '../actions';

const initialState: DevicesState = {};

const devicesReducer = (state = initialState, action: DevicesActionTypes) => {
	switch (action.type) {
		case Actions.GET_LICENSE_GROUP_DATA_SUCCESS: {
			const {
				licenseGroupId,
				data: { devices }
			} = action.payload;

			return {
				...state,
				[licenseGroupId]: {
					byId: devices
				}
			};
		}
		case Actions.LOGGED_OUT_USER:
		case Actions.PURGE_STATE:
			return initialState;
		default: {
			return state;
		}
	}
};

export default persistReducer(
	{
		key: 'devices',
		storage: localForage
	},
	devicesReducer
);

// Selectors
export const getDevicesById = ({ app: { selectedLicenseGroupId }, devices }: RootState) =>
	devices[selectedLicenseGroupId].byId;

export const getDevices = createSelector([getDevicesById], devicesById => Object.values(devicesById));
