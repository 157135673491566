import FuseAnimate from '@fuse/core/FuseAnimate';
import { Typography, makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import { getUserPreferences } from 'app/store/reducers';
import { useSelector } from 'app/modules/react-redux';

const useStyles = makeStyles(() => ({
	pageHeader: {
		// position: 'relative', // for some inexplicable reason this breaks typescript...so this styling is added with tailwind
		padding: '0.8rem 0rem',
		color: '#7D61BC',
		'&::after': {
			position: 'absolute',
			content: '""',
			height: 1,
			bottom: '0.7rem',
			left: 0,
			marginLeft: '0rem',
			width: '100%',
			// maxWidth: 1100,
			background:
				'linear-gradient(to right, rgba(112,112,112,1), rgba(112,112,112,1),rgba(112,112,112,0.3),rgba(112,112,112,0),rgba(112,112,112,0),rgba(112,112,112,0),rgba(112,112,112,0),rgba(112,112,112,0))'
		}
	},
	iconWrapper: {
		'& .MuiSvgIcon-root': {
			fontSize: '2.8rem'
		}
	}
}));

type Props = {
	lineIcon: React.ReactNode;
	fillIcon: React.ReactNode;
	title: string;
	extra?: React.ReactNode;
	desc?: string;
	buttons?: React.ReactNode;
	searchField?: React.ReactElement;
};

function PageHeading(props: Props) {
	const classes = useStyles();

	const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);

	const { pageHeaderBackgroundColor } = useSelector(getUserPreferences);

	useEffect(() => {
		const onResize = () => {
			setScreenWidth(window.innerWidth);
		};

		window.addEventListener('resize', onResize);
		return () => {
			window.removeEventListener('resize', onResize);
		};
	}, []);

	return localStorage.getItem('enterprise') ? (
		<div
			className="w-full mx-24 my-24 rounded py-8 pl-24 pr-16"
			style={{
				backgroundColor: pageHeaderBackgroundColor
			}}
		>
			<div className="flex justify-between items-center">
				<div>
					<div className="flex">
						<div className="flex items-center">
							<span className={`mr-12 ${classes.iconWrapper}`}>{props.lineIcon}</span>
						</div>
						<Typography variant="h5" className="font-bold">
							{props.title}
						</Typography>
					</div>
					<div>
						<Typography className="mt-4 pl-52 font-medium text-sm">{props.desc}</Typography>
					</div>
				</div>
				{props.extra}
				<div className="flex flex-shrink-0">
					{props.searchField && (
						<div className="mx-12">
							{React.cloneElement(props.searchField, {
								variant: 'fadeIn'
							})}
						</div>
					)}
					{props.buttons}
				</div>
			</div>
		</div>
	) : (
		<div className="w-full mx-48 my-36 pb-0 rounded">
			<header
				className={`flex flex-1 items-center justify-between relative ${classes.pageHeader}`}
				style={{ paddingBottom: '1.3rem' }}
			>
				<div className="flex items-stretch w-full">
					<div className="flex items-center">
						<FuseAnimate animation="transition.expandIn" delay={300}>
							<span className={`mr-12 ${classes.iconWrapper}`}>{props.fillIcon}</span>
						</FuseAnimate>
					</div>
					<FuseAnimate animation="transition.slideLeftIn" delay={300}>
						<div className="flex items-center">
							<Typography variant="h4" className="mr-56 font-semibold">
								{props.title}
							</Typography>
						</div>
					</FuseAnimate>
					{props.extra}
				</div>
			</header>
			{props.desc && (
				<FuseAnimate animation="transition.fadeIn" delay={300}>
					<Typography className="mt-12 pl-40 font-medium text-sm">{props.desc}</Typography>
				</FuseAnimate>
			)}
			{(props.buttons || props.searchField) && (
				<div className={`pt-16 pl-40 ${screenWidth < 875 ? '' : 'flex justify-between'}`}>
					<div className={`flex ${screenWidth < 875 ? '' : 'mb-16 flex-1'}`}>{props.buttons}</div>
					{props.searchField && (
						<div className={screenWidth < 875 ? 'my-16' : 'mb-20'}>{props.searchField}</div>
					)}
				</div>
			)}
		</div>
	);
}

export default PageHeading;
