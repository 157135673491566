import * as Actions from 'app/store/actions';
import { PurgeStateAction } from './licenseGroups.types';

export const startPages = [
	'home',
	'inbox',
	'reports',
	'job-queue',
	'tracker',
	'dashboard',
	'workflows',
	'reports',
	'devices',
	'users',
	'forms',
	'logs'
] as const;

export const quickLinks = [
	'add-task',
	'create-workflow',
	'import-devices',
	'invite-users',
	'customize',
	'help'
] as const;

export const languages = ['en', 'ja', 'fr', 'es', 'de', 'it'] as const;

export type UserPreferences = {
	startPage: typeof startPages[number];
	homePageLayout: 'rows' | 'columns' | 'grid' | 'left';
	quickLinks: [
		typeof quickLinks[number] | undefined,
		typeof quickLinks[number] | undefined,
		typeof quickLinks[number] | undefined
	];
	appearance: 'dark' | 'light' | 'system';
	// background image URL
	siteBackground: string;
	tableHeaderBackground: 'standard' | 'color';
	tableHeaderStandardBackgroundOpacity: number;
	tableHeaderColorBackgroundOpacity: number;
	pageHeaderBackgroundOpacity: number;
	navbarBackgroundOpacity: number;
	language: typeof languages[number];
};

export type Profile = {
	id: string;
	firstName: string;
	lastName: string;
	email: string;
	mpData: {
		firstName: string;
		lastName: string;
		email: string;
	};
	mpId?: string;
	country: string;
	awsRegion: string;
	tenantId?: string;
	tokens: {
		accessToken: string;
		idToken: string;
		expirationDate: number;
	};
	preferences: UserPreferences;
};

export type ProfileState = {
	info: Profile;
	previousProfileId: string | undefined;
};

type GetProfileSuccessAction = {
	type: typeof Actions.GET_PROFILE_SUCCESS;
	payload: {
		data: {
			user: Profile;
		};
	};
};

export type ProfileActionTypes = GetProfileSuccessAction | PurgeStateAction;
