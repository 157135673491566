import * as Actions from 'app/store/actions';
import { PurgeStateAction } from './licenseGroups.types';

export type Receipt = {
	token: string;
	status: 'SUCCESS';
	order: {
		subtotal: number;
		tax: number;
		total: number;
		items: {
			[catalogId: number]: {
				name: string;
				quantity: number;
			};
		};
	};
};
// TODO: UPDATE PUBLIC IDS
export enum PublicId {
	FreeTrial = '012FA085-C7A7-4117-BBFF-8D55BFF07AAF',
	NFR = 'AF53EC30-C8B8-48B7-82E3-E4574C708A18',
	StarterMonthly = '00919D56-7103-4397-B9DD-4E8AA42F2863',
	StarterYearly = 'FAKE0000-0000-0000-0000-000000000001',
	StarterYearlyTerm = '43253F38-F3E2-402B-B725-41DAEE6B7A93',
	BusinessMonthly = '35F75645-0FA2-4CAF-A25C-71F52BDDD8EC',
	BusinessYearly = 'FAKE0000-0000-0000-0000-000000000003',
	BusinessYearlyTerm = 'A2A861B3-D24D-447F-A1B4-6AC6F2A0666C',
	EnterpriseMonthly = '3348C5FD-7123-4332-ABD0-4D5C644CE3A7',
	EnterpriseYearly = 'FAKE0000-0000-0000-0000-000000000005',
	EnterpriseYearlyTerm = '706832BE-B413-48A8-AEFE-8434D72F78B6',
	TermFiveYear = 'A0C7C4C6-FF94-44D1-AD3F-16E5274385F5',
	TermFourYear = '3A14C409-D4CB-4C16-A346-B5D732570A17',
	TermThreeYear = '2B4EEAA7-9576-43C8-820D-25C09DAC99B3',
	TermTwoYear = '04847520-17F3-4582-A1DA-827D7D4D2B52',
	TermOneYear = 'F34EB719-1E3E-4AA9-B31B-AE691B0AB090',
	TermOneMonthOneTime = '1E92B38B-EEA4-4B88-9DAB-CBDE4A9D472B',
	TermDealerBundle = 'CB73A7BE-5C65-41D0-9312-D95DA2301E3B',
	TermBUSBundleOneYear = 'C3F39D09-6091-4302-8C31-C78A81F1ADAE',
	TermBUSBundleTwoYear = '1293F292-D870-4578-9ED0-50D151A46820',
	TermBUSBundleThreeYear = '6852DB6D-DD15-4910-A68F-63CAEE12EC17',
	TermBUSBundleFourYear = 'ED4FA711-9159-4437-9398-1AEC3CA4B196',
	TermBUSBundleFiveYear = '0FA99FF0-16D2-4971-9CD5-157F99F2ED29'
}

export type CatalogItem = {
	publicId: PublicId;
	name: string;
	showPrice: boolean;
	defaultPrice: number;
	priceRanges: {
		amount: number;
		min: number;
		// sap_id: string;
	}[];
	billing: {
		uid: string;
		// name: string;
		// duration: number;
		// months: boolean;
		// gracePeriod: number;
	};
	counter: {
		min: number;
		max: number;
		step: number;
		// lock: boolean;
	};
};

export type MarketState = {
	currency: string;
	catalog: {
		[publicId in PublicId]: CatalogItem;
	};
};

type GetCatalogSuccessAction = {
	type: typeof Actions.GET_CATALOG_SUCCESS;
	payload: {
		currency: string;
		catalog: {
			[publicId in PublicId]: CatalogItem;
		};
	};
};

export type MarketActionTypes = GetCatalogSuccessAction | PurgeStateAction;
