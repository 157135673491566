/* eslint-disable import/no-cycle */
import * as Actions from 'app/store/actions';
import { PublicId } from './market.types';
import { Schedule } from './workflows.types';

const userPermissions = ['tenant-admin'] as const;
type UserPermission = typeof userPermissions[number];
export const isValidUserPermission = (permission: string): permission is UserPermission =>
	userPermissions.includes(permission as any);

export type User = {
	id: string;
	email: string;
	firstName: string;
	lastName: string;
	dateAdded: number;
	userGroupId?: UserGroup['id'][];
	blocked: boolean;
	permissions: { [permission in UserPermission]?: boolean | undefined };
	// HACK-ish::we often use (User | Pending User) - adding these undefined properties suppresses errors
	pending?: boolean | undefined;
	username: string;
	roles: string[];
};

export type PendingUser = {
	id: string;
	email: string;
	token: string;
	dateAdded: number;
	userGroupId?: UserGroup['id'][];
	blocked: boolean;
	permissions: { [permission in UserPermission]?: boolean | undefined };
	pending: boolean;
	// HACK-ish::we often use (User | Pending User) - adding these undefined properties suppresses errors
	firstName?: undefined;
	lastName?: undefined;
	roles: string[];
};

export type UserGroup = {
	id: string;
	name: string;
	dateUpdated: number;
	groupOrder?: number;
	collapse?: boolean;
	roles: string[];
	defaultUser?: string;
};

export type Device = {
	serial: string; // TODO::change this to `id` (use `serialNumber` if actually comparing serial numbers)
	friendlyName?: string;
	name: string;
	model: string;
	location: string;
	STCApp: boolean;
	ipAddress: string;
	serialNumber: string;
	description: string;
	licensed: boolean;
	deviceGroupId: Array<DeviceGroup['id']>;
	deviceStatus: string;
};

export type Role = {
	id: string;
	name: string;
	permissions: {
		[permissionGroup: string]: {
			[permissionSection: string]: {
				[permission: string]: boolean
			}
		}
	};
	systemRole?: string;
}

export type DeviceGroup = {
	id: string;
	name: string;
	dateUpdated: number;
	groupOrder?: number;
	collapse?: boolean;
};

// export type Form = {
// 	id: string;
// 	title: string;
// 	creator: User['id']; // where does "creator" value come from? Someone else in the group? What if they leave?
// 	editor: User['id'];
// 	dateAdded: number;
// 	dateUpdated: number;
// 	status: 'draft' | 'published';
// 	active: boolean;
// 	formGroupId?: FormGroup['id'];
// 	formDefinition: {
// 		fields: {
// 			id: string;
// 			variableName: string;
// 			label: string;
// 			value: string;
// 			required: boolean;
// 			readOnly: boolean;
// 			ignoreFormMetadata: boolean;
// 			enabledHelpText: boolean;
// 			cssClass: string;
// 		};
// 	};
// 	cssDefinition: {
// 		css: string;
// 	};
// };

export type TenantGroup = {
	id: string;
	name: string;
	parentId?: string;
	parentName?: string;
	isGroup?: boolean;
	children?: Array<LicenseGroupData> | Array<TenantGroup>;
	managerEmails?: Array<ManagerEmails>;
	managerEvents: Array<string>;
};

export type TenantGroupInfo = {
	groupId: string;
	groupName: string;
	parentId?: string;
	managers: Array<ManagerEmails>;
};

export type FormOverview = {
	id: string;
	title: string;
	creator: string;
	editor: string;
	created: number;
	updated: number;
	formStatus: 'draft' | 'published';
	valid: boolean;
	metadataKeys: string;
	formGroup: {
		id: string;
	};
};

export type Form = FormOverview & {
	formDefinition: {
		fields: [
			{
				id: string;
				variableName: string;
				label: string;
				value: string;
				required: boolean;
				readonly: boolean;
				ignoreFormMetadata: boolean;
				enabledHelpText: boolean;
				helpText: string;
				cssClass: string;
			}
		];
	};
	cssDefinition: {
		css: string;
	};
};

export type FormGroup = {
	id: string;
	title: string;
	dateUpdated?: number;
	groupOrder?: number;
	collapse?: boolean;
};

export type AdminContact = {
	fullName?: string;
	email?: string;
	phone?: string;
	address?: string;
	apt?: string | undefined;
	country?: string;
	city?: string;
	state?: string;
	zip?: string;
	notes?: string;
};
export type AdminInfo = {
	primary?: AdminContact;
	secondary?: AdminContact;
};

export type WorkflowOverview = {
	id: string;
	name: string;
	status: 'running' | 'paused' | 'stopped' | 'draft';
	workflowGroupId: WorkflowGroup['id'] | undefined;
	previews: string[];
	logs?: Log[];
	dateUpdated: number;
	schedule?: undefined;
	acl: {
		users: {
			name: User['id'];
			// user list also has (exactly) one owner
			role: 'owner' | 'view' | 'edit';
		}[];
		groups: {
			name: WorkflowGroup['id'];
			role: 'view' | 'edit';
		}[];
		devices: {
			name: Device['serial'];
			role: 'view' | 'edit';
		}[];
		deviceGroups: {
			name: DeviceGroup['id'];
			role: 'view' | 'edit';
		}[];
	};
	rowOrder?: number;
};

// DEV NOTE::any of the additional data fetched from the `/{guid}.json` endpoint
export type WorkflowData = Omit<WorkflowOverview, 'schedule'> & {
	schedule: Schedule;
};

export type Workflow = WorkflowOverview | WorkflowData;

export type WorkflowGroup = {
	id: string;
	name: string;
	dateUpdated: number;
	dateCreated?: any;
	groupOrder?: number;
	collapse?: boolean;
	acl: {
		users: {
			name: User['id'];
			// user list also has (exactly) one owner
			role: 'owner' | 'view' | 'edit';
		}[];
		groups: {
			name: UserGroup['id'];
			role: 'view' | 'edit';
		}[];
		devices: {
			name: Device['serial'];
			role: 'view' | 'edit';
		}[];
		deviceGroups: {
			name: DeviceGroup['id'];
			role: 'view' | 'edit';
		}[];
	};
};

export type Log = {
	id: string;
	type: 'device' | 'policy' | 'user';
	dateCreated: number;
	severity: 'high' | 'low';
	messageKey: string;
	event: string;
	logLevel: string;
	info: {
		[key: string]: string | undefined;
	} & {
		policyFails?: {
			// msg: string;
			msgKey: string;
			deviceSetting: any;
			requiredSetting: any;
			policyItemKey: string;
		}[];
	};
};

export type EmailStatus = {
	email: string;
	status: string;
};

export type ManagerEmails = EmailStatus & {
	firstName: string;
	lastName: string;
	id?: string;
};

export type LicenseGroupData = {
	id: string;
	slug: string;
	name: string;
	parentId?: string;
	quantity: number;
	capacity: number;
	catalogPublicId: PublicId;
	orderType: 'PRODUCTION' | 'DEMO' | 'NFR';
	orderNumber: string;
	purchaseCode: string;
	expirationDate: number | undefined;
	isCoolOffPeriod: boolean;
	region: string | undefined;
	status?: '' | 'UNCONFIGURED' | 'PENDING' | 'CONFIGURED';
	timezone: string;
	timezoneOffset: number | null;
	managerEmails?: Array<EmailStatus>;
	adminData?: Array<EmailStatus>;
	contactInfo?: AdminInfo;
	dateCreated: number;
	// HACK-ish::add prop to say additional data has loaded
	dataLoaded?: boolean;
	authMethod?: 'mp' | 'local';
	licenseUsage?: {
		dateUpdated: number;
		fileCount: number;
		fileSizeTotal: number;
	};
	suspension?: any;
	preferences?: any;
	plan?: string;
};

export type ManagerLicenseGroup = {
	id: string;
	name: string;
	parent?: string;
	children: LicenseGroupData[] | ManagerLicenseGroup[];
	managerEvents?: Record<string, boolean>;
};

export type LicenseGroupsState = {
	adminedById: {
		[licenseGroupId: string]: LicenseGroupData;
	};
	managedById: {
		[licenseGroupId: string]: LicenseGroupData;
	};
	paragonData: {
		login: string;
		token: string;
		client: string;
		secret: string;
	};
	managedGroups: { [id: string]: TenantGroup };
	loadingGroups: boolean;
	tenantGroupDetailsById: { [id: string]: TenantGroupInfo };
};

export type GetAdminedLicenseGroupsSuccessAction = {
	type: typeof Actions.GET_ADMINED_LICENSE_GROUPS_SUCCESS;
	payload: {
		data: {
			[licenseGroupId: string]: LicenseGroupData;
		};
	};
};

export type GetManagedLicenseGroupsSuccessAction = {
	type: typeof Actions.GET_MANAGED_LICENSE_GROUPS_SUCCESS;
	payload: {
		data: {
			[licenseGroupId: string]: LicenseGroupData;
		};
	};
};

export type GetTenantManagerGroups = {
	type: typeof Actions.GET_TENANT_MANAGER_GROUPS_SUCCESS;
	payload: {
		data: {
			[id: string]: TenantGroup;
		};
	};
};

export type GetTenantManagerGroupsById = {
	type: typeof Actions.GET_TENANT_GROUP_BY_ID_SUCCESS;
	payload: {
		id: string;
		data: TenantGroup;
	};
};

export type GetGroupManagerInfoById = {
	type: typeof Actions.GET_GROUP_MANAGER_INFO_BY_ID_SUCCESS;
	payload: {
		groupId: string;
		data: ManagerEmails[];
	};
};

export type setLoadingManagerGroups = {
	type: typeof Actions.SET_LOADING_MANAGER_GROUPS;
	payload: {
		loading: boolean;
	};
};

export type GetTenantGroupDetails = {
	type: typeof Actions.GET_SELECTED_TENANT_GROUP_INFO_SUCCESS;
	payload: {
		tenantId: string;
		data: TenantGroupInfo;
	};
};

export type GetTenantByIdSuccessAction = {
	type: typeof Actions.GET_TENANT_BY_ID_SUCCESS;
	payload: {
		id: string;
		adminInfo: AdminInfo;
	};
};

export type GetParagonDataSuccessAction = {
	type: typeof Actions.GET_PARAGON_DATA_SUCCESS;
	payload: {
		login: string;
		token: string;
		client: string;
		secret: string;
	};
};

export type ForgetAdminedLicenseGroupSuccessAction = {
	type: typeof Actions.FORGET_ADMINED_LICENSE_GROUP_SUCCESS;
	payload: {
		licenseGroupId: string;
	};
};

export type GetLicenseGroupDataSuccessAction = {
	type: typeof Actions.GET_LICENSE_GROUP_DATA_SUCCESS;
	payload: {
		licenseGroupId: string;
		data: {
			details: LicenseGroupData;
			users: {
				[userId: string]: User;
			};
			contactInfo: AdminInfo;
			pendingUsers: {
				[email: string]: PendingUser;
			};
			userGroups: {
				[userGroupId: string]: UserGroup;
			};
			devices: {
				[serial: string]: Device;
			};
			deviceGroups: {
				[deviceGroupId: string]: DeviceGroup;
			};
			roles: {
				[id: string]: Role;
			};
			forms: {
				[formId: string]: Form;
			};
			formGroups: {
				[formGroupId: string]: FormGroup;
			};
			workflows: {
				[workflowId: string]: Workflow;
			};
			workflowGroups: {
				[workflowGroupId: string]: WorkflowGroup;
			};
			logs: {
				[logId: string]: Log;
			};
			entitlements: {
				permissions: Role["permissions"];
				features: {[feature: string]: boolean}
			}
		};
	};
};

export type LoggedOutUserAction = {
	type: typeof Actions.LOGGED_OUT_USER;
};
export type PurgeStateAction = {
	type: typeof Actions.PURGE_STATE;
};

export type LicenseGroupsActionTypes =
	| GetAdminedLicenseGroupsSuccessAction
	| GetManagedLicenseGroupsSuccessAction
	| ForgetAdminedLicenseGroupSuccessAction
	| GetLicenseGroupDataSuccessAction
	| GetTenantManagerGroups
	| GetTenantManagerGroupsById
	| GetGroupManagerInfoById
	| GetTenantByIdSuccessAction
	| GetParagonDataSuccessAction
	| setLoadingManagerGroups
	| GetTenantGroupDetails
	| LoggedOutUserAction
	| PurgeStateAction;
