import localForage from 'localforage';
import { persistReducer } from 'redux-persist';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { UsersState, UsersActionTypes } from 'app/store/types';
import * as Actions from '../actions';

const initialState: UsersState = {};

const usersReducer = (state = initialState, action: UsersActionTypes) => {
	switch (action.type) {
		case Actions.GET_LICENSE_GROUP_DATA_SUCCESS: {
			const {
				licenseGroupId,
				data: { users, pendingUsers }
			} = action.payload;

			return {
				...state,
				[licenseGroupId]: {
					byId: users,
					pendingById: pendingUsers
				}
			};
		}
		case Actions.LOGGED_OUT_USER:
		case Actions.PURGE_STATE:
			return initialState;
		default: {
			return state;
		}
	}
};

export default persistReducer(
	{
		key: 'users',
		storage: localForage
	},
	usersReducer
);

// Selectors
export const getUsersById = ({ app: { selectedLicenseGroupId }, users }: RootState) =>
	users[selectedLicenseGroupId].byId;

export const getUsers = createSelector([getUsersById], usersById => Object.values(usersById));

export const getPendingUsersById = ({ app: { selectedLicenseGroupId }, users }: RootState) =>
	users[selectedLicenseGroupId].pendingById;

export const getAllUsersById = createSelector([getUsersById, getPendingUsersById], (usersById, pendingUsersById) => ({
	...usersById,
	...pendingUsersById
}));

export const getAllUsers = createSelector([getAllUsersById], allUsersById => Object.values(allUsersById));
