import FusePageSimple from '@fuse/core/FusePageSimple';
import React, { useCallback, useEffect, useState } from 'react';
import usePageTitle from 'app/hooks/usePageTitle';
import * as Actions from 'app/store/actions';

import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'app/modules/react-redux';
import { FormControl, FormControlLabel, FormLabel, makeStyles, MenuItem, Radio, RadioGroup, TextField, Typography } from '@material-ui/core';
import { ContactUsIcon } from 'app/components/Icons';
import PageHeading from 'app/components/PageHeading';
import StandardButton from 'app/components/StandardButton';
import { isValidEmail } from 'app/utils/helpers';
import countries from 'app/utils/countries';
import { states, provinces } from 'app/utils/states'
import { isEmpty } from 'lodash';
import AlertDialog from 'app/components/AlertDialog';

const useStyles = makeStyles(() => ({
	input: {
		'&::-webkit-search-cancel-button': {
			color: '#663F80'
		}
	},
	label: {
		top: '-12px',
		left: '14px',
		'&.MuiInputLabel-shrink': {
			top: '-7px',
			left: '14px'
		}
	},
	errorable: {
		'& .MuiOutlinedInput-root.Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline': {
			borderColor: '#f44336'
		}
	}
}));

const ContactUsPage = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const classes = useStyles();
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [companyName, setCompanyName] = useState('');
	const [job, setJob] = useState('');
	const [email, setEmail] = useState('');
	const [phone, setPhone] = useState('');
	const [country, setCountry] = useState<any>('');
	const [stateProvince, setStateProvince] = useState<any>('');
	const [postal, setPostal] = useState('');
	const [timeline, setTimeline] = useState<any>('');
	const [customer, setCustomer] = useState('true')
	const [fieldError, setFieldError] = useState({
		firstName: false,
		lastName: false,
		companyName: false,
		job: false,
		email: false,
		phone: false,
		country: false,
		stateProvince: false,
		postal: false,
		timeline: false,
		customer: false,
	})
	const [alert, setAlert] = useState(false);
	const [disableButton, setDisableButton] = useState(true);

	usePageTitle('Contact Us');

	const validate = () => {
		const validated = {
			firstName: isEmpty(firstName),
			lastName: isEmpty(lastName),
			companyName: isEmpty(companyName),
			job: isEmpty(job),
			email: isEmpty(email) || !isValidEmail(email),
			phone: isEmpty(phone) || !/^[0-9]*$/.test(phone),
			country: country === '',
			stateProvince: stateProvince === '',
			postal: isEmpty(postal),
			timeline: timeline === '',
			customer: isEmpty(customer),
		}
		// setFieldError(validated)
		return !Object.values(validated).includes(true)
	}

	// const validateText = (text: string, allowNum: boolean = false) => {
	// 	//letters, the hyphen, the single quote, the period and the space if the field has multiple words
	// 	if (allowNum) {
	// 		if (/[^A-Za-z0-9\-'\. ]/.test(text)) {
	// 			return false;
	// 		}
	// 	} else {
	// 		if (/[^A-Za-z\-'\. ]/.test(text)) {
	// 			return false;
	// 		}
	// 	}
	// 	return true;
	// }

	const resetForm = () => {
		setFirstName('');
		setLastName('');
		setCompanyName('');
		setJob('');
		setEmail('');
		setPhone('');
		setCountry('');
		setStateProvince('');
		setPostal('');
		setTimeline('');
		setCustomer('true');
		setDisableButton(true);
	};

	const onSubmit = () => {
		// NOTE: These options are in English since only SEC receives
		const timelineOptions = ['0 to 3 Months', '4 to 6 Months', '7 Months to 1 Year', '1 Year or More'];
		const isCurrentCustomer = customer === 'true';
		const formData = {
			buyingTimeline: timelineOptions[timeline],
			company: companyName,
			country,
			email: email.trim().toLowerCase(),
			firstName,
			isCurrentCustomer,
			lastName,
			phone,
			postalCode: postal,
			state: stateProvince,
			title: job,
		};
		dispatch(Actions.sendCustomerInquiry(formData));
		setAlert(true);
		resetForm();
	};

	const cbValidate = useCallback(validate, [firstName, lastName, companyName, job, email, phone, country, stateProvince, postal, timeline, customer]);

	useEffect(() => {
		cbValidate() ? setDisableButton(false) : setDisableButton(true);
	}, [firstName, lastName, companyName, job, email, phone, country, stateProvince, postal, timeline, customer, cbValidate])

	return (
		<FusePageSimple
			classes={{
				header: 'min-h-128 h-auto mb-0'
			}}
			header={
				<PageHeading
					lineIcon={<ContactUsIcon />}
					fillIcon={<ContactUsIcon />}
					title={t('Contact Us')}
				/>
			}
			content={
				<div className="w-full">
					<div>
						<div>
							<Typography style={{whiteSpace: 'pre-wrap'}}>
								<Trans i18nKey="contact us page:description" />
							</Typography>
						</div>
						<div className="flex w-5/6 pt-48">
							<div className="flex">
								<img alt="illustration" src={`${process.env.PUBLIC_URL}/assets/images/contact-us-illustration.svg`}/>
							</div>
							<div className='grid gap-16 grid-flow-row grid-cols-2 flex-grow' >
								<div className='col-span-2'>
									<Typography className="text-red font-semibold" variant='caption'>
										{t('contact us page:fields required')}
									</Typography>
								</div>
								<div className='col-span-1'>
									<TextField 
										className={`${classes.errorable} w-full`} 
										label='First Name' 
										variant='outlined'
										onChange={(e) => {
											if (e.target.value.trim() === '' && firstName.length === 0) {
												return;
											}
											if (isEmpty(e.target.value)) {
												setFieldError({...fieldError, firstName: true})
											}
											else {
												setFieldError({...fieldError, firstName: false})
											}
											setFirstName(e.target.value)
										}}
										value={firstName}
										error={fieldError['firstName']}
										helperText={fieldError['firstName'] ? t('contact us page:first name:error') : undefined}
										inputProps={{maxLength: 100}}
										// required
									/>
								</div>
								<div className='col-span-1'>
									<TextField 
										className={`${classes.errorable} w-full`} 
										label='Last Name' 
										variant='outlined'
										onChange={(e) => {
											if (e.target.value.trim() === '' && lastName.length === 0) {
												return;
											}
											if (isEmpty(e.target.value)) {
												setFieldError({...fieldError, lastName: true})
											}
											else {
												setFieldError({...fieldError, lastName: false})
											}
											setLastName(e.target.value)
										}}
										value={lastName}
										error={fieldError['lastName']}
										helperText={fieldError['lastName'] ? t('contact us page:last name:error') : undefined}
										inputProps={{maxLength: 100}}
										// required
									/>
								</div>
								<div className='col-span-1'>
									<TextField 
										className={`${classes.errorable} w-full`} 
										label='Company Name' 
										variant='outlined'
										onChange={(e) => {
											if (e.target.value.trim() === '' && companyName.length === 0) {
												return;
											}
											if (isEmpty(e.target.value)) {
												setFieldError({...fieldError, companyName: true})
											}
											else {
												setFieldError({...fieldError, companyName: false})
											}
											setCompanyName(e.target.value)
										}}
										value={companyName}
										error={fieldError['companyName']}
										helperText={fieldError['companyName'] ? t('contact us page:company name:error') : undefined}
										inputProps={{maxLength: 250}}
										// required
									/>
								</div>
								<div className='col-span-1'>
									<TextField 
										className={`${classes.errorable} w-full`} 
										label='Job Title' 
										variant='outlined'
										onChange={(e) => {
											if (e.target.value.trim() === '' && job.length === 0) {
												return;
											}
											if (isEmpty(e.target.value)) {
												setFieldError({...fieldError, job: true})
											}
											else {
												setFieldError({...fieldError, job: false})
											}
											setJob(e.target.value)
										}} 
										value={job}
										error={fieldError['job']}
										helperText={fieldError['job'] ? t('contact us page:job title:error') : undefined}
										inputProps={{maxLength: 100}}
										// required
									/>
								</div>
								<div className='col-span-1'>
									<TextField 
										className={`${classes.errorable} w-full`} 
										label='Business Email' 
										variant='outlined'
										onChange={(e) => {
											if (e.target.value.trim() === '' && email.length === 0) {
												return;
											}
											if (isValidEmail(e.target.value) && !isEmpty(e.target.value)) {
												setFieldError({...fieldError, email: false});
											} else {
												setFieldError({...fieldError, email: true});
											}
											setEmail(e.target.value)
										}} 
										value={email}
										error={fieldError['email']}
										helperText={fieldError['email'] ? t('contact us page:email:error') : undefined}
										inputProps={{maxLength: 100}}
										// required
									/>
								</div>
								<div className='col-span-1'>
									<TextField
										className={`${classes.errorable} w-full`} 
										label='Contact Phone' 
										variant='outlined'
										onChange={(e) => {
											if (!isEmpty(e.target.value) && e.target.value.length >= 10) {
												setFieldError({...fieldError, phone: false});
											} else {
												setFieldError({...fieldError, phone: true});
											}
											if ((e.target.value.trim() === '' && phone.length === 0) || !/^[0-9]*$/.test(e.target.value)) {
												return;
											}
											setPhone(e.target.value)
										}}
										value={phone}
										error={fieldError['phone']}
										helperText={fieldError['phone'] ? t('contact us page:phone number:error') : undefined}
										inputProps={{ maxLength: 15 }}
										// required
									/>
								</div>
								<div className='col-span-2'>
									<TextField 
										className={`${classes.errorable} w-full`} 
										label='Business Country' 
										variant='outlined'
										onChange={(e) => {
											if (isEmpty(e.target.value)) {
												setFieldError({...fieldError, country: true})
											}
											else {
												setFieldError({...fieldError, country: false})
											}
											setCountry(e.target.value)
											setStateProvince('');
										}}
										// onKeyPress={(e) => {
										// 	const filtered = countries.filter((item) => {
										// 		return item.startsWith(e.key.toUpperCase())
										// 	})
										// 	const index = filtered.indexOf(country)
										// 	if (index > -1) {
										// 		filtered.length === index + 1 ? setCountry(filtered[0]) : setCountry(filtered[index + 1]);
										// 	} else {
										// 		setCountry(filtered[0])
										// 	}
										// 	setStateProvince('');
										// }}
										value={country}
										error={fieldError['country']}
										helperText={fieldError['country'] ? t('Required field.') : undefined}
										select
										// required
									>
										{
											countries.map((country: string) => {
												return (
													<MenuItem value={country}>{country}</MenuItem>
												)
											})
										}
									</TextField>
								</div>
								<div className='col-span-1'>
									<TextField 
										className={`${classes.errorable} w-full`} 
										label='Business State/Province' 
										variant='outlined'
										onChange={(e) => {
											console.log(e.target.value);
											if (isEmpty(e.target.value)) {
												setFieldError({...fieldError, stateProvince: true})
											}
											else {
												setFieldError({...fieldError, stateProvince: false})
											}
											setStateProvince(e.target.value)
										}}
										value={stateProvince}
										error={fieldError['stateProvince']}
										helperText={fieldError['stateProvince'] ? t('Required field.') : undefined}
										select
										disabled={country === ''}
										// required
									>
										{
												country === 'United States' ? states.map((state: string) => {
													return (
														<MenuItem key={state} value={state}>{state}</MenuItem>
													)
												}) : country === 'Canada' ? provinces.map((province: string) => {
													return (
														<MenuItem key={province} value={province}>{province}</MenuItem>
													)
												}) : 
												<MenuItem key={'Outside US/Canada'} value={'Outside US/Canada'}>Outside US/Canada</MenuItem>
										}
									</TextField>
								</div>
								<div className='col-span-1'>
									<TextField 
										className={`${classes.errorable} w-full`} 
										label='Business Postal Code'
										variant='outlined'
										onChange={(e) => {
											if (e.target.value.trim() === '' && postal.length === 0) {
												return;
											}
											if (isEmpty(e.target.value)) {
												setFieldError({...fieldError, postal: true})
											}
											else {
												setFieldError({...fieldError, postal: false})
											}
											setPostal(e.target.value)
										}} 
										value={postal}
										error={fieldError['postal']}
										helperText={fieldError['postal'] ? t('contact us page:postal:error') : undefined}
										inputProps={{maxLength: 50}}
										// required
									/>
								</div>
								<div className='col-span-1'>
									<TextField 
										className={`${classes.errorable} w-full`} 
										label='Buying Timeline' 
										variant='outlined'
										onChange={(e) => {
											if (e.target.value === '') {
												setFieldError({...fieldError, timeline: true})
											}
											else {
												setFieldError({...fieldError, timeline: false})
											}
											setTimeline(e.target.value)
										}}
										value={timeline}
										error={fieldError['timeline']}
										helperText={fieldError['timeline'] ? t('Required field.') : undefined}
										select
										// required
									>
										<MenuItem value={0}>0 to 3 Months</MenuItem>
										<MenuItem value={1}>4 to 6 Months</MenuItem>
										<MenuItem value={2}>7 Months to 1 Year</MenuItem>
										<MenuItem value={3}>1 Year or More</MenuItem>
									</TextField>
								</div>
								<div className='col-span-2'>
									<FormControl className="flex-row items-center">
										<FormLabel className="pr-16">Are you a current Konica Minolta customer?</FormLabel>
										<RadioGroup
											defaultValue="yes"
											name="radio-buttons-group"
											row
											onChange={(e)=> {
												if (isEmpty(e.target.value)) {
													setFieldError({...fieldError, postal: true})
												}
												else {
													setFieldError({...fieldError, postal: false})
												}
												setCustomer(e.target.value)
											}}
											value={customer}
										>
											<FormControlLabel value="true" control={<Radio />} label="Yes" />
											<FormControlLabel value="false" control={<Radio />} label="No" />
										</RadioGroup>
									</FormControl>
								</div>
								<div className='col-span-2 text-right'>
									<StandardButton
										btnText={'Submit'}
										onClick={(e: any) => {
											if(validate()) {
												onSubmit();
											}
										}}
										disabled={disableButton}
									/>
								</div>
							</div>
						</div>
					</div>

					<AlertDialog
						open={!!alert}
						setOpen={open =>
							setAlert(open)
						}
						secondaryButton={false}
						title={t('contact us page:alert:title')}
						content={
							<Typography style={{whiteSpace: 'pre-wrap'}}>
								<Trans i18nKey="contact us page:alert:description" />
							</Typography>
						}
						buttonsText={['Close']}
						action={() => {}}
					/>
				</div>
			}
		/>
	);
};

export default ContactUsPage;
