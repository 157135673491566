// HACK::use the URL to determine which site "section" you're in
// Unfortunately, we have several way of doing this but they're all flawed so...here's one more. This is very inappropriate and fragile...but it works.
import { useLocation } from 'react-router-dom';

// HACK-ish::this check is used outside of React - however the subdomain cannot change through site navigation so we can just use `window.location`, so despite this being a hook file we support exporting this check alone
const isLicenseGroupDomain = /^.+\.tenant\./.test(window.location.host); // this is *confusing*, yes. This refers to pages like the EULA within the license group side of things but which are not "true" admin pages (yes it's also not a section, but I don't know where else to put this check)

const useGetPageSection = () => {
	const location = useLocation();

	const isPublicProfilePage = /^\/profile(\/.*)?/.test(location.pathname);
	const isAdminPage = /^\/admin(\/.*)?/.test(location.pathname);
	const isManagerPage = /^\/manager(\/.*)?/.test(location.pathname);
	const isPricingPage = /^\/features(\/.*)?/.test(location.pathname);
	const isWelcomePage = location.pathname === '/welcome';
	const isPublicPage = !(isPublicProfilePage || isAdminPage || isManagerPage); // also true when `isPricingPage` or `isWelcomePage`

	return {
		publicProfile: isPublicProfilePage,
		admin: isAdminPage,
		licenseGroupDomain: isLicenseGroupDomain,
		manager: isManagerPage,
		pricing: isPricingPage,
		welcome: isWelcomePage,
		public: isPublicPage
	};
};

export default useGetPageSection;

export { isLicenseGroupDomain as licenseGroupDomain };
