import { useEffect } from 'react';
import { useDispatch } from 'app/modules/react-redux';
import * as Actions from 'app/store/actions';

const useHideNavbar = (hide = true) => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(Actions.hideNavbar(hide));

		return () => {
			dispatch(Actions.hideNavbar(false));
		};
	}, [dispatch, hide]);
};

export default useHideNavbar;
