/* eslint-disable import/no-cycle */
import axios from 'app/client';
import { AxiosError } from 'axios';

import { getProfile, getSelectedLicenseGroupId, getTenantManagerGroupsById } from 'app/store/reducers';
import { EXPIRED_GRACE_PERIOD, getFormsUrl, getLogApiUrl, getWfxUrl, responseError } from 'app/utils/helpers';

import _ from '@lodash';
import { AppThunk } from 'app/store';
import {
	AdminInfo,
	Device,
	DeviceGroup,
	FormGroup,
	FormOverview,
	LicenseGroupData,
	Log,
	PendingUser,
	Profile,
	PublicId,
	TenantGroup,
	User,
	UserGroup,
	Workflow,
	WorkflowGroup,
	isValidUserPermission,
	Role
} from 'app/store/types';
import { getTimeZones } from '@vvo/tzdb';
import { UNGROUPED_FORMS_GROUP_ID } from 'app/utils/default-groups';
import * as appActions from './app.actions';

// HACK::allow switching license group plan for testing
const mockSelectedLicenseGroupPlan = localStorage.getItem('mockPlan') as PublicId | undefined;

// HACK::allow switching order type for testing
const mockSelectedLicenseGroupOrderType = localStorage.getItem('mockOrderType') as
	| LicenseGroupData['orderType']
	| undefined;

// HACK::allow switching to expired license for testing
const mockExpired = localStorage.getItem('mockExpired');

// HELPER: set isCoolOffPeriod based on the order type and the expiration date
const getCoolOffPeriod = (
	orderType: LicenseGroupData['orderType'],
	expirationDate: LicenseGroupData['expirationDate']
): boolean => {
	if (
		orderType === 'PRODUCTION' ||
		orderType === 'NFR' ||
		expirationDate === undefined ||
		Date.now() < expirationDate
	) {
		return false;
	}

	return Date.now() < expirationDate + EXPIRED_GRACE_PERIOD[orderType];
};

const getTimezoneOffset = async (timezoneName: string) => {
	const timeZones = await getTimeZones({ includeUtc: true });
	const userTimeZoneData = timeZones.find(tz => tz.name === timezoneName);
	return userTimeZoneData ? userTimeZoneData.currentTimeOffsetInMinutes : null;
};
// HACK::reshape data returned from backend
const massageRawLicenseGroup = (rawLicenseGroup: any) => {
	const licenseGroupData: LicenseGroupData = {
		id: rawLicenseGroup.id,
		slug: rawLicenseGroup.slug,
		name: rawLicenseGroup.name,
		quantity: rawLicenseGroup.quantity,
		capacity: rawLicenseGroup.capacity,
		// HACK::publicId can come in upper- or lower-case
		catalogPublicId: mockSelectedLicenseGroupPlan ?? rawLicenseGroup.catalogPublicId.toUpperCase(),
		orderType: mockSelectedLicenseGroupOrderType ?? rawLicenseGroup.orderType.toUpperCase(),
		orderNumber: rawLicenseGroup.orderNumber,
		status: rawLicenseGroup.status,
		purchaseCode: rawLicenseGroup.purchaseCode,
		expirationDate: mockExpired ? +mockExpired : rawLicenseGroup.expirationDate,
		isCoolOffPeriod:
			getCoolOffPeriod(
				mockSelectedLicenseGroupOrderType ?? rawLicenseGroup.orderType.toUpperCase(),
				mockExpired ? +mockExpired : rawLicenseGroup.expirationDate
			) ?? false,
		region: rawLicenseGroup.region,
		timezone: rawLicenseGroup.timezone,
		timezoneOffset: rawLicenseGroup.timezoneOffset ?? 0,
		managerEmails: rawLicenseGroup.managerEmails,
		parentId: rawLicenseGroup?.parent,
		adminData: rawLicenseGroup.adminData,
		dateCreated: rawLicenseGroup.dateAdded,
		authMethod: rawLicenseGroup.authMethod,
		licenseUsage: rawLicenseGroup.licenseUsage,
		suspension: rawLicenseGroup.suspensions ?? {},
		preferences: rawLicenseGroup.preferences ?? {},
		plan: rawLicenseGroup.plan
	};

	return licenseGroupData;
};

const massageRawTenantManagerGroup = (rawTenantGroup: any) => {
	const tenantGroup: TenantGroup = {
		id: rawTenantGroup.id,
		parentId: rawTenantGroup?.parentId,
		children: rawTenantGroup?.children,
		parentName: rawTenantGroup?.parentName,
		managerEmails: rawTenantGroup?.managerEmails?.map((manager: any) => ({
			firstName: manager?.firstName,
			lastName: manager?.lastName,
			email: manager?.email,
			status: manager?.status
		})),
		name: rawTenantGroup.name,
		managerEvents: rawTenantGroup?.preferences,
		isGroup: true
	};

	return tenantGroup;
};

const massageRawAdminInfo = (rawAdminInfo: any) => {
	const adminInfo: AdminInfo = {
		primary: rawAdminInfo.primary,
		secondary: rawAdminInfo.secondary
	};

	return adminInfo;
};

const massageRawUser = (rawUser: any) => {
	const user: User = {
		id: rawUser.id,
		email: rawUser.email,
		// HACK-ish::fall back to `username` and empty string for firstName and lastName - added for cognito users
		firstName: rawUser.firstName || rawUser.username || rawUser.email.split('@')[0] || '',
		lastName: rawUser.lastName || '',
		dateAdded: rawUser.dateAdded,
		userGroupId: rawUser.groupList,
		blocked: rawUser.status === 'BLOCKED',
		// DEV NOTE::in theory this should always be false (because `massageRawPendingUser` handled pending users)
		pending: rawUser.status === 'PENDING',
		permissions: (rawUser.roles as string[]).reduce((acc, role) => {
			if (isValidUserPermission(role)) {
				acc[role] = true;
			}
			return acc;
		}, {} as User['permissions']),
		username: rawUser.userName,
		roles: rawUser.roles
	};

	return user;
};

const massageRawPendingUser = (rawPendingUser: any) => {
	const pendingUser: PendingUser = {
		id: rawPendingUser.id,
		email: rawPendingUser.email,
		token: rawPendingUser.token,
		dateAdded: rawPendingUser.dateAdded,
		userGroupId: rawPendingUser.groupList ? rawPendingUser.groupList : [],
		permissions: (rawPendingUser.roles as string[]).reduce((acc, role) => {
			if (isValidUserPermission(role)) {
				acc[role] = true;
			}
			return acc;
		}, {} as User['permissions']),
		blocked: false,
		// add `pending` prop for distinguishing if this list gets mixed with regular users
		pending: true,
		roles: rawPendingUser.roles
	};

	return pendingUser;
};

const massageRawUserGroup = (rawUserGroup: any) => {
	const userGroup: UserGroup = {
		id: rawUserGroup.id,
		name: rawUserGroup.name,
		dateUpdated: rawUserGroup.updatedAt,
		groupOrder: rawUserGroup.groupOrder,
		collapse: rawUserGroup.collapse,
		roles: rawUserGroup?.roles ?? [],
		defaultUser: rawUserGroup.defaultUser
	};

	return userGroup;
};

const massageRawDevice = (rawDevice: any) => {
	const device: Device = {
		serial: rawDevice.id,
		friendlyName: rawDevice.name,
		name: rawDevice.model ? `bizhub ${rawDevice.model}` : '',
		model: rawDevice.model,
		location: rawDevice.location,
		STCApp: rawDevice.STCApp,
		ipAddress: rawDevice.ip,
		serialNumber: rawDevice.serial,
		description: rawDevice.description,
		licensed: rawDevice.licensed,
		deviceGroupId: rawDevice.groupList.length === 1 && rawDevice.groupList[0] === '' ? [] : rawDevice.groupList,
		deviceStatus: rawDevice.status ? rawDevice.status.toLowerCase() : 'complete'
	};
	return device;
};

const massageRawDeviceGroup = (rawDeviceGroup: any) => {
	const deviceGroup: DeviceGroup = {
		id: rawDeviceGroup.id,
		name: rawDeviceGroup.name,
		dateUpdated: rawDeviceGroup.dateUpdated
	};

	return deviceGroup;
};

const massageRawRole = (rawRole: any) => {
	const role: Role = {
		id: rawRole.id,
		name: rawRole.name,
		permissions: rawRole.permissions,
		systemRole: rawRole?.systemRole
	};

	return role;
};

const massageRawForm = (rawForms: any[]): { [id: string]: FormOverview } => {
	const forms: { [id: string]: FormOverview } = {};

	rawForms.forEach((rawForm: any, i) => {
		const form: FormOverview = {
			id: rawForm.id,
			title: rawForm.title || `Form-${i + 1}`,
			creator: rawForm.creator,
			editor: rawForm.editor,
			created: rawForm.created,
			updated: rawForm.updated,
			formStatus: rawForm.formStatus,
			valid: rawForm.valid,
			metadataKeys: rawForm.metadataKeys,
			formGroup: { id: rawForm.formGroup.id }
		};

		forms[rawForm.id] = form;
	});

	return forms;
};

const massageRawFormGroup = (rawFormGroups: any): { [id: string]: FormGroup } => {
	const formGroups: { [id: string]: FormGroup } = {};

	rawFormGroups.forEach((rawFormGroup: any) => {
		const formGroup: FormGroup = {
			id: rawFormGroup.id,
			title: rawFormGroup.title,
			groupOrder: rawFormGroup.sortOrder
		};

		formGroups[rawFormGroup.id] = formGroup;
	});

	formGroups[UNGROUPED_FORMS_GROUP_ID] = {
		id: UNGROUPED_FORMS_GROUP_ID,
		title: UNGROUPED_FORMS_GROUP_ID
	};

	return formGroups;
};

const getPageCount = (rawWorkflow: any) => {
	let pageCount = 0;

	if (rawWorkflow.options && rawWorkflow.options.PreviewPageCount) {
		pageCount = +rawWorkflow.options.PreviewPageCount;

		if (Number.isNaN(pageCount)) {
			pageCount = 1;
		}
	}

	return pageCount;
};

export const massageRawWorkflow = (rawWorkflow: any, profileId?: Profile['id'], awsRegion = 'us-east-1') => {
	const pageCount = getPageCount(rawWorkflow);
	const wfxApiUrl = getWfxUrl(awsRegion);
	const workflow: Workflow = {
		id: rawWorkflow.id,
		name: rawWorkflow.name,
		status: rawWorkflow.status,
		previews: _.range(0, pageCount).map(
			count =>
				`${wfxApiUrl}/api/wfx/${rawWorkflow.id}/preview/${count + 1}.png?${Date.parse(rawWorkflow.modified)}`
		),
		// have a set shape - and always set yourself as the owner if no one is set yet
		acl: {
			users: rawWorkflow.acl?.users ?? [{ name: profileId, role: 'owner' }],
			groups: rawWorkflow.acl?.groups ?? [],
			devices: rawWorkflow.acl?.devices ?? [],
			deviceGroups: rawWorkflow.acl?.deviceGroups ?? []
		},
		workflowGroupId: rawWorkflow.options?.Group || rawWorkflow.options?.group || '',
		dateUpdated: Date.parse(rawWorkflow.modified)
	};

	return workflow;
};

const massageRawWorkflowGroup = (rawWorkflowGroup: any, profileId?: Profile['id']) => {
	const workflowGroup: WorkflowGroup = {
		id: rawWorkflowGroup.id,
		name: rawWorkflowGroup.name,
		dateUpdated: new Date(rawWorkflowGroup.modified).getTime(),
		dateCreated: rawWorkflowGroup.created ? new Date(rawWorkflowGroup.created).getTime() : undefined,
		groupOrder: rawWorkflowGroup.groupOrder,
		collapse: rawWorkflowGroup.collapse,
		acl: {
			users: rawWorkflowGroup.acl?.users ?? [{ name: profileId, role: 'owner' }],
			groups: rawWorkflowGroup.acl?.groups ?? [],
			devices: rawWorkflowGroup.acl?.devices ?? [],
			deviceGroups: rawWorkflowGroup.acl?.deviceGroups ?? []
		}
	};

	return workflowGroup;
};

const massageRawLog = (rawLog: any) => {
	const log: Log = {
		id: rawLog.SK,
		type: rawLog.Type,
		dateCreated: rawLog.DateAdded,
		severity: rawLog.severity,
		messageKey: rawLog.MsgKey,
		event: rawLog.Action,
		info: rawLog.Info ?? {},
		logLevel: rawLog.LogLevel
	};

	return log;
};

const massageRawEntitlements = (rawEntitlement: any) => {
	const { permissions } = rawEntitlement;
	const { features } = rawEntitlement;

	return {
		permissions,
		features
	};
};

// at some point this may be broken into more specific API calls
export const GET_ADMINED_LICENSE_GROUPS_SUCCESS = 'GET_ADMINED_LICENSE_GROUPS_SUCCESS';
export const GET_MANAGED_LICENSE_GROUPS_SUCCESS = 'GET_MANAGED_LICENSE_GROUPS_SUCCESS';
export const GET_TENANT_MANAGER_GROUPS_SUCCESS = 'GET_TENANT_MANAGER_GROUPS_SUCCESS';
export const FORGET_ADMINED_LICENSE_GROUP_SUCCESS = 'FORGET_ADMINED_LICENSE_GROUP_SUCCESS';
export const GET_LICENSE_GROUP_DATA_SUCCESS = 'GET_LICENSE_GROUP_DATA_SUCCESS';
export const GET_TENANT_BY_ID_SUCCESS = 'GET_TENANT_BY_ID_SUCCESS';
export const GET_PARAGON_DATA_SUCCESS = 'GET_PARAGON_DATA_SUCCESS';
export const GET_TENANT_GROUP_BY_ID_SUCCESS = 'GET_TENANT_GROUP_BY_ID_SUCCESS';
export const GET_GROUP_MANAGER_INFO_BY_ID_SUCCESS = 'GET_GROUP_MANAGER_INFO_BY_ID_SUCCESS';
export const GET_SELECTED_TENANT_GROUP_INFO_SUCCESS = 'GET_SELECTED_TENANT_GROUP_INFO_SUCCESS';
export const SET_LOADING_MANAGER_GROUPS = 'SET_LOADING_MANAGER_GROUPS';

// export const getAdminedLicenseGroups = (): AppThunk => async dispatch => {
// 	try {
// 		// TODO::actually populate list
// 		const { data: rawLicenseGroups } = await Promise.resolve({ data: {} });

// 		const licenseGroups: { [id: string]: LicenseGroupData } = _.mapValues(rawLicenseGroups, massageRawLicenseGroup);

// 		dispatch({
// 			type: GET_ADMINED_LICENSE_GROUPS_SUCCESS,
// 			payload: {
// 				data: licenseGroups
// 			}
// 		});
// 	} catch (error) {
// 		if (error.response?.status === 401) {
// 			// if user not logged in
// 			// dispatch({
// 			// 	type: GET_ADMINED_LICENSE_GROUPS_SUCCESS,
// 			// 	payload: {
// 			// 		data: undefined
// 			// 	}
// 			// });
// 			return;
// 		}
// 		dispatch(appActions.handleError(error));
// 		// re-throw error for handling in <InitializeApp />
// 		throw error;
// 	}
// };

export const getManagedLicenseGroups = (): AppThunk => async dispatch => {
	try {
		const { data: rawLicenseGroups } = await axios.get('/api/tenants');

		const licenseGroups: { [id: string]: LicenseGroupData } = _.mapValues(rawLicenseGroups, massageRawLicenseGroup);

		dispatch({
			type: GET_MANAGED_LICENSE_GROUPS_SUCCESS,
			payload: {
				data: licenseGroups
			}
		});
	} catch (error) {
		if (error instanceof AxiosError && error.response?.status === 401) {
			// if user not logged in
			dispatch({
				type: GET_MANAGED_LICENSE_GROUPS_SUCCESS,
				payload: {
					data: undefined
				}
			});
			return;
		}
		dispatch(appActions.handleError(error));
		// re-throw error for handling in <InitializeApp />
		throw error;
	}
};

export const getTenantManagerGroups = (): AppThunk => async dispatch => {
	try {
		const { data: rawTenantLicenseGroups } = await axios.get('/api/tenants/tenant-manager-groups');
		// const tenantLicenseGroups: any[] = massageRawTenantManagerGroup(rawTenantLicenseGroups);
		const tenantGroups: { [id: string]: TenantGroup } = _.mapValues(
			_.keyBy(rawTenantLicenseGroups, 'id'),
			massageRawTenantManagerGroup
		);
		dispatch({
			type: GET_TENANT_MANAGER_GROUPS_SUCCESS,
			payload: {
				data: tenantGroups
			}
		});
	} catch (error) {
		if (error instanceof AxiosError && error.response?.status === 401) {
			// if user not logged in
			dispatch({
				type: GET_TENANT_MANAGER_GROUPS_SUCCESS,
				payload: {
					data: []
				}
			});
			return;
		}
		dispatch(appActions.handleError(error));
		// re-throw error for handling in <InitializeApp />
		throw error;
	}
};

export const getEditedTenantManagerGroups = (id: string): AppThunk => async dispatch => {
	const [{ data: rawTenantLicenseGroups }, { data: EmailInfo }] = await Promise.all([
		axios.get('/api/tenants/tenant-manager-groups'),
		axios.get(`/api/tenants/tenant-manager-group/${id}`)
	]);
	const tenantGroups: { [id: string]: TenantGroup } = _.mapValues(
		_.keyBy(rawTenantLicenseGroups, 'id'),
		massageRawTenantManagerGroup
	);
	dispatch({
		type: GET_TENANT_MANAGER_GROUPS_SUCCESS,
		payload: {
			data: tenantGroups
		}
	});
	dispatch({
		type: GET_GROUP_MANAGER_INFO_BY_ID_SUCCESS,
		payload: {
			groupId: id,
			data: EmailInfo
		}
	});
};

// export const forgetAdminedLicenseGroup = (licenseGroupId: LicenseGroupData['id']): AppThunk => async (
// 	dispatch,
// 	getState
// ) => {
// 	dispatch({
// 		type: FORGET_ADMINED_LICENSE_GROUP_SUCCESS,
// 		payload: {
// 			licenseGroupId
// 		}
// 	});
// };
export const forgetAdminedLicenseGroup = (licenseGroupId: LicenseGroupData['id']): AppThunk => async dispatch => {
	try {
		await axios.delete(`/api/user/user-tenants/${licenseGroupId}`);
	} catch (error) {
		dispatch(appActions.handleError(error));
	}
};

export const addAdminedLicenseGroup = (slug: LicenseGroupData['slug']): AppThunk => async (dispatch, _getState) => {
	try {
		await axios.patch('/api/user/user-tenants', { slug });
	} catch (error) {
		if (error instanceof AxiosError) {
			if (error?.response?.status === 404) {
				dispatch(appActions.alert('license group to add not found', 'error'));
				throw error;
			} else if (error?.response?.status === 409) {
				dispatch(appActions.alert('license group already added', 'error'));
				throw error;
			}
		}
		dispatch(appActions.handleError(error));
		throw error;
	}
};

export const getSelectedLicenseGroupData = (successFn?: () => void): AppThunk => async (dispatch, getState) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());
	const profile = getProfile(getState());
	const logApiUrl = getLogApiUrl(profile?.awsRegion);
	const wfxApiUrl = getWfxUrl(profile?.awsRegion);
	const formsApiUrl = getFormsUrl(profile?.awsRegion);

	try {
		const [
			{
				data: {
					adminInfo: rawAdminInfo,
					details: rawLicenseGroup,
					users: rawUsers,
					pendingUsers: rawPendingUsers,
					userGroups: rawUserGroups,
					devices: rawDevices,
					deviceGroups: rawDeviceGroups,
					roles: rawRoles
					// workflows: rawWorkflows,
					// workflowGroups: rawWorkflowGroups
					// logs: rawLogs
				}
			},
			{ data: rawWorkflows },
			{ data: rawWorkflowGroups },
			{ data: rawLogs },
			{ data: rawForms }, // Added this line to get the forms data from the new endpoint
			{ data: rawFormGroups }, // Added this line to get the form groups data from the new endpoint
			{ data: rawEntitlements }
			// { data: testData }
		] = await Promise.all([
			axios.get(`/api/tenants/${licenseGroupId}`),
			axios.get(`${wfxApiUrl}/api/wfx`),
			axios.get(`${wfxApiUrl}/api/groups`),
			// Comment above and uncomment below to test groups sharing
			// axios.get(`https://feature-groups.wfx.stratus.lol/api/groups`),
			// axios.get(`/api/tenants/logs/${licenseGroupId}`)
			axios.get(`${logApiUrl}/log/${licenseGroupId}/message`),
			axios.get(`${formsApiUrl}/api/forms`),
			axios.get(`${formsApiUrl}/api/groups`),
			axios.get(`/api/user/${licenseGroupId}/user/entitlements`)
		]);

		const offset = await getTimezoneOffset(rawLicenseGroup.timezone);

		// HACK::apply various hacks
		const licenseGroup = massageRawLicenseGroup(rawLicenseGroup);
		licenseGroup.timezoneOffset = offset;
		const users: { [id: string]: User } = _.mapValues(rawUsers, massageRawUser);
		const pendingUsers: { [id: string]: PendingUser } = _.mapValues(rawPendingUsers, massageRawPendingUser);
		const userGroups: { [id: string]: UserGroup } = _.mapValues(rawUserGroups, massageRawUserGroup);
		const devices: { [id: string]: Device } = _.mapValues(rawDevices, massageRawDevice);
		const deviceGroups: { [id: string]: DeviceGroup } = _.mapValues(rawDeviceGroups, massageRawDeviceGroup);
		const roles: { [id: string]: Role } = _.mapValues(rawRoles, massageRawRole);
		const contactInfo: { [id: string]: AdminInfo } = _.mapValues(rawAdminInfo, massageRawAdminInfo);
		// TODO::add back real data (and remove weird conditional hack) when endpoints are complete
		const forms: { [id: string]: FormOverview } = massageRawForm(rawForms);

		const formGroups: { [id: string]: FormGroup } = massageRawFormGroup(rawFormGroups);
		const workflows: { [id: string]: Workflow } = _.mapValues(_.keyBy(rawWorkflows, 'id'), rawWorkflow =>
			massageRawWorkflow(rawWorkflow, profile.id, profile?.awsRegion)
		);
		const workflowGroups: { [id: string]: WorkflowGroup } = _.mapValues(
			_.keyBy(rawWorkflowGroups, 'id'),
			rawWorkflowGroup => massageRawWorkflowGroup(rawWorkflowGroup, profile.id)
		);
		// const logs: { [id: string]: Log } = _.mapValues(rawLogs, massageRawLog);
		// const workflowGroups: { [id: string]: WorkflowGroup } = localStorage.getItem('mock')
		// 	? _.mapValues(rawWorkflowGroups, massageRawWorkflowGroup)
		// 	: {};
		const logs: { [id: string]: Log } = localStorage.getItem('mock') ? _.mapValues(rawLogs, massageRawLog) : {};
		const entitlements: {
			permissions: Role['permissions'];
			features: { [feature: string]: boolean };
		} = massageRawEntitlements(rawEntitlements);

		dispatch({
			type: GET_LICENSE_GROUP_DATA_SUCCESS,
			payload: {
				licenseGroupId,
				data: {
					details: licenseGroup,
					contactInfo,
					users,
					pendingUsers,
					userGroups,
					devices,
					deviceGroups,
					roles,
					forms,
					formGroups,
					workflows,
					workflowGroups,
					logs,
					entitlements
				}
			}
		});
		if (successFn) successFn();
	} catch (error) {
		if (error instanceof AxiosError && error.response?.status === 403) {
			// re-throw error for handling in <LicenseGroupPageWrapper /> if 403
			throw error;
		}
		dispatch(appActions.handleError(error));
	}
};

export const getTenantId = (): AppThunk => async (_dispatch, getState) => {
	const id = getSelectedLicenseGroupId(getState());
	return id;
};

export const getTenantById = (id: string): AppThunk => async (dispatch, _getState) => {
	try {
		const {
			data: { adminInfo }
		} = await axios.get(`/api/tenants/${id}`);

		dispatch({
			type: GET_TENANT_BY_ID_SUCCESS,
			payload: {
				id,
				adminInfo
			}
		});
	} catch (error) {
		dispatch(appActions.handleError(error));
	}
};

export const editContactInfo = (data: AdminInfo, id: string): AppThunk => async (dispatch, _getState) => {
	try {
		await axios.patch(`/api/tenants/${id}/contact-data`, data);
		dispatch(appActions.alert('contact info updated', 'success'));
		dispatch(getManagedLicenseGroups());
	} catch (error) {
		dispatch(appActions.handleError(error));
	}
};

export const editSelectedTenant = ({ name, timezone }: { name?: string; timezone?: string }): AppThunk => async (
	dispatch,
	getState
) => {
	const tenantId = getSelectedLicenseGroupId(getState());

	const data = {
		name,
		timezone
	};

	try {
		const response = await axios.patch(`/api/tenants/${tenantId}/settings`, data);
		// @ts-ignore
		if (responseError(response)) {
			dispatch(appActions.alert('failed to update settings', 'warning'));
		} else {
			dispatch(appActions.alert('settings updated', 'success'));
		}
		dispatch(getSelectedLicenseGroupData());
	} catch (error) {
		dispatch(appActions.handleError(error));
	}
};

// export const configureTenant = ({ name }: { name: string }, { region }: { region: string }): AppThunk => async (dispatch, getState) => {
// 	const tenantId = getSelectedLicenseGroupId(getState());

// 	const data = {
// 		name
// 	};

// 	try {
// 		const response = await axios.patch(`/api/v1/tenant/${tenantId}/settings`, data);
// 		// @ts-ignore
// 		if (responseError(response)) {
// 			dispatch(appActions.alert('failed to update settings', 'warning'));
// 		} else {
// 			dispatch(appActions.alert('settings updated', 'success'));
// 		}
// 		dispatch(getSelectedLicenseGroupData());
// 	} catch (error) {
// 		dispatch(appActions.handleError(error));
// 	}
// };

export const addPrimaryAdmin = ({ name }: { name: string }, action: string): AppThunk => async (
	dispatch,
	_getState
) => {
	const data = {
		name
	};

	try {
		const response = await axios.patch('/api/license-groups/mock-admin-invite/devices', data);
		// @ts-ignore
		if (responseError(response)) {
			dispatch(appActions.alert('failed to update settings', 'warning'));
		} else if (action === 'SEND') {
			dispatch(
				appActions.alert('admin invite sent', 'success', {
					anchorOrigin: { vertical: 'top', horizontal: 'right' }
				})
			);
		} else if (action === 'CANCEL') {
			dispatch(
				appActions.alert('admin invite cancelled', 'success', {
					anchorOrigin: { vertical: 'top', horizontal: 'right' }
				})
			);
		}
		// dispatch(getSelectedLicenseGroupData());
	} catch (error) {
		dispatch(appActions.handleError(error));
	}
};

export const modifySelectedTenant = ({
	newPublicId,
	newCapacity
}: {
	newPublicId?: PublicId;
	newCapacity: number;
}): AppThunk => async (dispatch, getState) => {
	const tenantId = getSelectedLicenseGroupId(getState());

	try {
		await axios.post(`/api/tenants/${tenantId}/registration`, {
			capacity: newCapacity,
			...(newPublicId && { catalogItemId: newPublicId })
		});
		dispatch(appActions.alert('license plan updated', 'success'));
		dispatch(getSelectedLicenseGroupData());
	} catch (error) {
		if (error instanceof AxiosError) {
			if (error?.response?.data?.message === 'Invalid plan upgrade') {
				dispatch(appActions.alert('license plan invalid', 'warning'));
			} else if (error?.response?.data?.message === 'MAX_CAPACITY_EXCEEDED') {
				dispatch(appActions.alert('license count warning', 'warning'));
			} else {
				dispatch(appActions.handleError(error));
			}
		} else {
			dispatch(appActions.handleError(error));
		}
		dispatch(getSelectedLicenseGroupData());
	}
};

export const configureTenant = (
	data: {
		// roles: string[];
		name: string;
		region: string;
		localAdminEmail?: string;
		slug: string;
		authMethod: string;
		fromManager?: boolean;
		userRoles: string[]; // tenant-admin or tenant-manager
	},
	id: string,
	token?: string
): AppThunk => async (dispatch, getState) => {
	// console.log('configureTenant', data, id, token);
	const { name, region, localAdminEmail, slug, authMethod, fromManager = false, userRoles } = data;
	try {
		const payload = { name, region, localAdminEmail, slug, authMethod, fromManager, userRoles };
		await axios.patch(`/api/tenants/${id}/configure${token ? `?token=${token}` : ''}`, payload);
		// dispatch(appActions.alert('license plan updated', 'success'));
		dispatch(getManagedLicenseGroups());
	} catch (error) {
		dispatch(appActions.handleError(error));
	}
};

export const copyTenantData = ({
	targetTenantId,
	copyDataTypes
}: {
	targetTenantId: string;
	copyDataTypes: any;
}): AppThunk => async (dispatch, getState) => {
	const tenantId = getSelectedLicenseGroupId(getState());

	try {
		await axios.patch(`/api/v1/tenant/${tenantId}/copy`, {
			targetTenantId,
			copyDataTypes
		});
		dispatch(appActions.alert('Tenant data copied', 'success'));
	} catch (error) {
		dispatch(appActions.handleError(error));
	}
};

export const inviteManager = ({
	managerEmail,
	tenantId,
	onSuccess = () => {},
	onError = () => {}
}: {
	managerEmail: string;
	tenantId: string;
	onSuccess?: () => void;
	onError?: () => void;
}): AppThunk => async (dispatch, getState) => {
	try {
		await axios.patch(`/api/tenants/${tenantId}/invite-manager`, { managerEmail });
		dispatch(getTenantGroupInfo());
		dispatch(appActions.alert('tenants:invite group manager', 'success'));
		onSuccess();
	} catch (error) {
		dispatch(appActions.handleError(error));
		onError();
	}
};

export const resendManagerInvite = ({
	managerEmail,
	tenantId,
	onSuccess = () => {},
	onError = () => {}
}: {
	managerEmail: string;
	tenantId: string;
	onSuccess?: () => void;
	onError?: () => void;
}): AppThunk => async (dispatch, getState) => {
	try {
		await axios.patch(`/api/tenants/${tenantId}/resend-manager-invite`, { managerEmail });
		dispatch(getTenantGroupInfo());
		dispatch(appActions.alert('tenants:invite group manager', 'success'));
		onSuccess();
	} catch (error) {
		dispatch(appActions.handleError(error));
		onError();
	}
};

export const cancelManagerInvite = ({
	managerEmail,
	tenantId,
	onSuccess = () => {},
	onError = () => {}
}: {
	managerEmail: string;
	tenantId: string;
	onSuccess?: () => void;
	onError?: () => void;
}): AppThunk => async (dispatch, getState) => {
	try {
		await axios.patch(`/api/tenants/${tenantId}/cancel-manager-invite`, { managerEmail });
		dispatch(getTenantGroupInfo());
		dispatch(appActions.alert('tenants:invite canceled', 'success'));
		onSuccess();
	} catch (error) {
		dispatch(appActions.handleError(error));
		onError();
	}
};

export const inviteAdmin = (data: {
	adminEmail: string;
	tenantId: string;
	managerInfo: { email: string; firstName: string; lastName: string };
}): AppThunk => async (dispatch, getState) => {
	const { adminEmail, tenantId, managerInfo } = data;
	// const tenantId = getSelectedLicenseGroupId(getState());
	// const { name } = getSelectedLicenseGroupData(getState());
	try {
		await axios.patch(`/api/tenants/${tenantId}/invite-admin`, { adminEmail, managerInfo });
		dispatch(appActions.alert('tenants:invite tenant admin', 'success'));
		dispatch(getManagedLicenseGroups());
	} catch (error) {
		dispatch(appActions.handleError(error));
	}
};
export const resendAdminInvite = (data: {
	adminEmail: string;
	tenantId: string;
	managerInfo: { email: string; firstName: string; lastName: string };
}): AppThunk => async (dispatch, getState) => {
	const { adminEmail, tenantId, managerInfo } = data;
	try {
		await axios.patch(`/api/tenants/${tenantId}/resend-invite`, { adminEmail, managerInfo });
		dispatch(appActions.alert('tenants:invite tenant admin', 'success'));
	} catch (error) {
		dispatch(appActions.handleError(error));
	}
};

export const inviteTenantGroupManager = (
	managerEmail: string,
	tenantGroupId: string,
	groupName: string
): AppThunk => async (dispatch, getState) => {
	try {
		await axios.patch(`/api/tenants/tenant-group/${tenantGroupId}/invite-manager`, { managerEmail, groupName });
		dispatch(appActions.alert('Tenant manager invited', 'success'));
		dispatch(getTenantManagerGroupById(tenantGroupId));
	} catch (error) {
		dispatch(appActions.alert('user:error:invite user', 'warning'));
	}
};

export const resendGroupManagerInvite = (groupId: string, managerEmail: string, groupName: string): AppThunk => async (
	dispatch,
	getState
) => {
	try {
		await axios.patch(`/api/tenants/tenant-group/${groupId}/resend-invite`, { managerEmail, groupName });
		dispatch(appActions.alert('Tenant manager invited', 'success'));
		dispatch(getTenantManagerGroupById(groupId));
	} catch (error) {
		dispatch(appActions.alert('user:error:resend invite', 'warning'));
	}
};

export const cancelGroupManagerInvite = (groupId: string, managerEmail: string): AppThunk => async (
	dispatch,
	getState
) => {
	try {
		await axios.patch(`/api/tenants/tenant-group/${groupId}/cancel-invite`, { managerEmail });
		dispatch(appActions.alert('tenants:invite canceled', 'success'));
		dispatch(getTenantManagerGroupById(groupId));
	} catch (error) {
		dispatch(appActions.handleError(error));
		dispatch(appActions.alert('user:error:cancel invite', 'warning'));
	}
};

export const removeGroupManager = (groupId: string, managerEmail: string): AppThunk => async (dispatch, getState) => {
	const groupDetails = getTenantManagerGroupsById(getState())[groupId];
	const managerUserId = groupDetails?.managerEmails?.filter(item => item.email === managerEmail);

	try {
		await axios.patch(`/api/tenants/tenant-group/${groupId}/remove-manager`, {
			managerUserId: managerUserId?.[0]?.id
		});
		dispatch(appActions.alert('tenants:invite canceled', 'success'));
		dispatch(getTenantManagerGroupById(groupId));
	} catch (error) {
		dispatch(appActions.handleError(error));
		dispatch(appActions.alert('user:error:remove user', 'warning'));
	}
};

export const verifyToken = (token: string): AppThunk => async (dispatch, getState) => {
	const tenantId = getSelectedLicenseGroupId(getState());

	try {
		const response = await axios.get(`/api/tenants/${tenantId}/tenant/${token}`);
		if (response.data === 'Token verified!') {
			dispatch({
				type: 'TOKEN_VERIFICATION_SUCCESS',
				payload: { licenseGroupId: tenantId, tokenStatus: 'TOKEN_VERIFIED' }
			});
			dispatch(appActions.alert('Token verified', 'success'));
			return true;
		}
		// dispatch({ type: 'TOKEN_VERIFICATION_SUCCESS', payload: false });
		dispatch({
			type: 'TOKEN_VERIFICATION_FAIL',
			payload: { licenseGroupId: tenantId, tokenStatus: 'TOKEN_ERROR' }
		});

		dispatch(appActions.alert('Token invalid', 'warning'));
		return false;
	} catch (error) {
		dispatch(appActions.handleError(error));
		return false;
	}
};

export const upgradeDemoToProductionLicense = ({
	purchaseCode,
	orderNumber,
	userId,
	// claimManager
	onSuccess = () => {},
	onError = () => {}
}: {
	purchaseCode: string;
	orderNumber: string;
	userId: string;
	// claimManager: boolean;
	onSuccess?: Function;
	onError?: Function;
}): AppThunk => async (dispatch, getState) => {
	const tenantId = getSelectedLicenseGroupId(getState());
	purchaseCode = purchaseCode.replace(/-/g, '');

	try {
		const { data } = await axios.patch(`api/tenants/${tenantId}/upgrade`, {
			purchaseCode,
			orderNumber,
			userId
			// claimManager
		});

		if (data.success && data.unassigned) {
			dispatch(appActions.alert('License upgraded, but licenses have been unassigned from devices', 'warning'));
		} else if (data.success && !data.unassigned) {
			dispatch(appActions.alert('License upgraded', 'success'));
		}
		onSuccess();
		dispatch(getSelectedLicenseGroupData());
	} catch (error) {
		if (error instanceof AxiosError) {
			if (error.response && error.response.status && error.response.status !== 500) {
				dispatch(appActions.alert('Purchase code invalid', 'warning'));
			} else {
				dispatch(appActions.handleError(error));
			}
		} else {
			dispatch(appActions.handleError(error));
		}
		onError();
	}
};

export const upgradeTermLicense = ({
	capacity,
	expirationDate,
	purchaseCode,
	userId,
	callbackFn
}: {
	capacity: number;
	expirationDate: number;
	purchaseCode: string;
	userId: string;
	callbackFn?: Function;
}): AppThunk => async (dispatch, getState) => {
	const tenantId = getSelectedLicenseGroupId(getState());
	purchaseCode = purchaseCode.replace(/-/g, '');
	let success = false;

	try {
		const response = await axios.patch(`api/tenants/${tenantId}/renew-license`, {
			capacity,
			expirationDate,
			purchaseCode,
			userId
		});
		console.log(response);
		if (response.data.success) {
			dispatch(appActions.alert('License upgraded', 'success'));
			dispatch(getSelectedLicenseGroupData());
			success = true;
		} else {
			dispatch(appActions.alert('Purchase code invalid', 'warning'));
		}
	} catch (error) {
		dispatch(appActions.alert('Purchase code invalid', 'warning'));
	} finally {
		if (callbackFn) callbackFn(success);
	}
};

export const modifyTermLicense = ({
	capacity,
	expirationDate,
	purchaseCode,
	userId,
	callbackFn
}: {
	capacity: number;
	expirationDate: number;
	purchaseCode: string;
	userId: string;
	callbackFn?: Function;
}): AppThunk => async (dispatch, getState) => {
	const tenantId = getSelectedLicenseGroupId(getState());
	purchaseCode = purchaseCode.replace(/-/g, '');

	try {
		const response = await axios.patch(`api/tenants/${tenantId}/modify-license`, {
			capacity,
			expirationDate,
			purchaseCode,
			userId
		});
		console.log(response);
		if (response.data.success) {
			dispatch(appActions.alert('License modified', 'success'));
			dispatch(getSelectedLicenseGroupData());
		} else {
			dispatch(appActions.alert('Failed to modify license', 'warning'));
		}
	} catch (error) {
		dispatch(appActions.alert('Failed to modify license', 'warning'));
	} finally {
		if (callbackFn) callbackFn();
	}
};

export const getTermLicense = (purchaseCode: string, successFn?: Function, failFn?: Function): AppThunk => async (
	dispatch,
	getState
) => {
	purchaseCode = purchaseCode.replace(/-/g, '');

	try {
		const response = await axios.get(`/api/mp/license/order/${purchaseCode}`);
		console.log(response);
		// @ts-ignore
		if (response.data.order) {
			// dispatch(appActions.alert('License upgraded', 'success'));
			dispatch(getSelectedLicenseGroupData());
			// @ts-ignore
			if (successFn) successFn(response.data.order);
		} else {
			dispatch(appActions.alert('Purchase code invalid', 'warning'));
			if (failFn) failFn();
		}
	} catch (error) {
		dispatch(appActions.alert('Purchase code invalid', 'warning'));
		if (failFn) failFn();
	}
};

export const getParagonData = (): AppThunk => async (dispatch, getState) => {
	const tenantId = getSelectedLicenseGroupId(getState());

	try {
		const response = await axios.get(`/api/sso/${tenantId}/paragon-data`);
		console.log(response);
		console.log(response.data);
		// @ts-ignore
		if (responseError(response)) {
			dispatch(appActions.alert('settings:get paragon data:fail', 'warning'));
		} else {
			dispatch({
				type: GET_PARAGON_DATA_SUCCESS,
				payload: response.data
			});
			dispatch(getSelectedLicenseGroupData());
		}
	} catch (error) {
		dispatch(appActions.alert('settings:get paragon data:fail', 'warning'));
	}
};

export const getTenantGroupInfo = (): AppThunk => async (dispatch, getState) => {
	const tenantId = getSelectedLicenseGroupId(getState());
	const response = await axios.get(`/api/tenants/${tenantId}/group`);
	// @ts-ignore
	if (responseError(response)) {
		dispatch(appActions.alert('tenant groups:details', 'warning'));
	} else {
		const { data: details } = response;
		dispatch({
			type: GET_SELECTED_TENANT_GROUP_INFO_SUCCESS,
			payload: {
				tenantId,
				data: details
			}
		});
	}
};

export const setNotifications = (groupId: string, arr?: Array<string>): AppThunk => async (dispatch, getState) => {
	try {
		const response = await axios.post(`/api/tenants/${groupId}/notifications`, {
			arr
		});
		// @ts-ignore
		if (responseError(response)) {
			dispatch(appActions.alert('tenants:notification:fail', 'warning'));
		} else {
			dispatch(getTenantManagerGroups());
			dispatch(getTenantManagerGroupById(groupId));
		}
	} catch (error) {
		dispatch(appActions.alert('tenants:notification:fail', 'warning'));
		console.log('error catch', error);
	}
};

export const setSuspension = (tenantId: string, obj?: Record<string, boolean>): AppThunk => async (
	dispatch,
	getState
) => {
	try {
		const response = await axios.post(`/api/tenants/${tenantId}/suspension`, {
			obj
		});
		// @ts-ignore
		if (responseError(response)) {
			dispatch(appActions.alert('tenants:suspension:fail', 'warning'));
		} else {
			dispatch(getTenantManagerGroups());
		}
	} catch (error) {
		dispatch(appActions.alert('tenants:suspension:fail', 'warning'));
		console.log('error catch', error);
	}
};

export const createTenantManagerGroup = (name: string, parentId?: string): AppThunk => async (dispatch, getState) => {
	const groupsById = getTenantManagerGroupsById(getState());
	let pId;
	if (parentId !== undefined) pId = groupsById[parentId];

	try {
		const response = await axios.post('/api/tenants/tenant-manager-groups', {
			name,
			parentId,
			parentName: pId?.name
		});
		// @ts-ignore
		if (responseError(response)) {
			dispatch(appActions.alert('', 'warning'));
		} else {
			dispatch(getTenantManagerGroups());
		}
	} catch (error) {
		dispatch(appActions.alert('tenant groups: update fail', 'warning'));
	}
};

export const editTenantManagerGroup = (name: string, id: string, parentId?: string): AppThunk => async (
	dispatch,
	getState
) => {
	try {
		const response = await axios.put('/api/tenants/tenant-manager-groups', {
			name,
			id,
			parentId
		});
		// @ts-ignore
		if (responseError(response)) {
			dispatch(appActions.alert('', 'warning'));
		} else {
			// const { data } = response;
			// const licenseGroup: TenantGroup = massageRawTenantManagerGroup(data);

			// dispatch({
			// 	type: GET_TENANT_GROUP_BY_ID_SUCCESS,
			// 	payload: {
			// 		id,
			// 		data: licenseGroup
			// 	}
			// });
			// const tenantGroups: { [id: string]: TenantGroup } = _.mapValues(
			// 	_.keyBy(response.data, 'id'),
			// 	massageRawTenantManagerGroup
			// );
			// dispatch({
			// 	type: GET_TENANT_MANAGER_GROUPS_SUCCESS,
			// 	payload: {
			// 		data: tenantGroups
			// 	}
			// });
			dispatch(getEditedTenantManagerGroups(id));
		}
	} catch (error) {
		console.log(error);
		dispatch(appActions.alert('tenant groups: update fail', 'warning'));
	}
};

export const editManagedTenantDetails = (
	name: string,
	id: string,
	region?: string,
	parentId?: string
): AppThunk => async (dispatch, getState) => {
	try {
		const response = await axios.put(`/api/tenants/edit-tenant-details/${id}`, {
			name,
			region,
			parentId
		});
		// @ts-ignore
		if (responseError(response)) {
			dispatch(appActions.alert('', 'warning'));
		} else {
			dispatch(getManagedLicenseGroups());
			// below was for mock data. probably dont need to do this when the API is done.
			// const licenseGroups: { [id: string]: LicenseGroupData } = _.mapValues(
			// 	response.data,
			// 	massageRawLicenseGroup
			// );
			// dispatch({
			// 	type: GET_MANAGED_LICENSE_GROUPS_SUCCESS,
			// 	payload: {
			// 		data: licenseGroups
			// 	}
			// });
		}
	} catch (error) {
		dispatch(appActions.alert('tenants:details fail', 'warning'));
	}
};

export const deleteTenantGroup = (id: string): AppThunk => async (dispatch, getState) => {
	try {
		const response = await axios.post(`/api/tenants/tenant-group/${id}/delete`);
		// @ts-ignore
		if (responseError(response)) {
			dispatch(appActions.alert('', 'warning'));
		} else {
			dispatch(getManagedLicenseGroups());
			dispatch(getTenantManagerGroups());
		}
	} catch (error) {
		dispatch(appActions.alert('tenant groups: delete fail', 'warning'));
	}
};

export const getTenantManagerGroupById = (groupId: string): AppThunk => async (dispatch, getState) => {
	try {
		const response = await axios.get(`/api/tenants/tenant-manager-group/${groupId}`);
		// @ts-ignore
		if (responseError(response)) {
			dispatch(appActions.alert('', 'warning'));
		} else {
			const { data: EmailInfo } = response;
			dispatch({
				type: GET_GROUP_MANAGER_INFO_BY_ID_SUCCESS,
				payload: {
					groupId,
					data: EmailInfo
				}
			});
		}
	} catch (error) {
		dispatch(appActions.alert('tenant groups:details', 'warning'));
	}
};

export const setLoadingTenantGroups = (): AppThunk => async (dispatch, getState) => {
	dispatch({
		type: SET_LOADING_MANAGER_GROUPS,
		payload: {
			loading: true
		}
	});
};
