import FuseAnimate from '@fuse/core/FuseAnimate';
import FuseCountdown from '@fuse/core/FuseCountdown';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Icon from '@material-ui/core/Icon';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { darken } from '@material-ui/core/styles/colorManipulator';
import { ExpiredIcon } from 'app/components/Icons';
import MessageView from 'app/components/MessageView';
import useHideNavbar from 'app/hooks/useHideNavbar';
import { useDispatch, useSelector } from 'app/modules/react-redux';
import * as Actions from 'app/store/actions';
import { getHideExpiredScreen, getIsPossibleDevelopStore, getSelectedLicenseGroupData } from 'app/store/reducers';
import { EXPIRED_GRACE_PERIOD } from 'app/utils/helpers';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

// const EXPIRED_GRACE_PERIOD: Record<LicenseGroupData['orderType'], number> = {
// 	PRODUCTION: 30 * (24 * 3600 * 1000), // 30 days
// 	DEMO: 15 * (24 * 3600 * 1000), // 15 days
// 	NFR: 30 * (24 * 3600 * 1000) // 30 days
// };

const useStyles = makeStyles(theme => ({
	root: {
		background: `radial-gradient(${darken(theme.palette.primary.dark, 0.5)} 0%, ${theme.palette.primary.dark} 80%)`,
		color: theme.palette.primary.contrastText
	}
}));

type Props = {
	children: React.ReactNode;
};

const ExpiredView = ({ children }: Props) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const dispatch = useDispatch();

	const licenseGroupData = useSelector(getSelectedLicenseGroupData);
	const isPossibleDevelopStore = useSelector(getIsPossibleDevelopStore); // TEMP::skip any buttons for now develop store (as we don't know which to direct to)
	const hideExpiredScreen = useSelector(getHideExpiredScreen);

	const showExpiredScreen = !!(
		licenseGroupData.expirationDate &&
		Date.now() > licenseGroupData.expirationDate &&
		!hideExpiredScreen
	);

	useHideNavbar(showExpiredScreen);

	if (!licenseGroupData.expirationDate || !showExpiredScreen) {
		return <>{children}</>;
	}

	const contactUsLink = `${window.location.protocol}//${process.env.REACT_APP_DOMAIN_NAME}/contact-us`;

	return licenseGroupData.expirationDate &&
		Date.now() > licenseGroupData.expirationDate &&
		!licenseGroupData.isCoolOffPeriod ? (
		<MessageView
			icon={<ExpiredIcon className="text-96 block" />}
			title={t('expired:title')}
			message={[t('expired:message'), t('expired:message subtext')]}
			footer={
				isPossibleDevelopStore ? (
					undefined
				) : (
					<Link color="inherit" className="font-medium" href={contactUsLink} rel="noreferrer noopener">
						{t('Contact us')}
					</Link>
				)
			}
		/>
	) : (
		<div className={clsx(classes.root, 'flex flex-col flex-auto flex-shrink-0 items-center justify-center p-32')}>
			<div className="flex flex-col items-center justify-center w-full">
				<FuseAnimate animation="transition.expandIn">
					<Card className="w-full max-w-384">
						<CardContent className="flex flex-col items-center justify-center p-32 text-center">
							<div className="m-32">
								<Icon className="text-96" color="action">
									<ExpiredIcon className="text-96 block" />
								</Icon>
							</div>

							<Typography variant="subtitle1" className="mb-16">
								{t('expired:message')}
							</Typography>

							<Typography color="textSecondary" className="mb-16">
								{t('expired:message subtext')}
							</Typography>

							<Typography color="textSecondary" className="max-w-288 mb-32">
								{licenseGroupData.orderType === 'DEMO'
									? t('expired:message subtext grace period:demo')
									: t('expired:message subtext grace period:production')}
							</Typography>

							<FuseCountdown
								endDate={
									licenseGroupData.expirationDate + EXPIRED_GRACE_PERIOD[licenseGroupData.orderType]
								}
							/>

							<Button
								variant="contained"
								color="secondary"
								className="whitespace-no-wrap normal-case font-medium mt-48 mb-24"
								onClick={() => dispatch(Actions.hideExpiredScreen())}
							>
								{t('expired:continue to portal')}
							</Button>
						</CardContent>
					</Card>
				</FuseAnimate>
			</div>
		</div>
	);
};

export default ExpiredView;
