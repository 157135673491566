/* eslint-disable import/no-cycle */
import axios from 'app/client';
import _ from '@lodash';
import { arrayify, getWfxUrl, responseErrors } from 'app/utils/helpers';
import { Translation } from 'react-i18next';
import { getUsers, getSelectedLicenseGroupId, getProfile, getAllUsersById, getWorkflows } from 'app/store/reducers';
import { AppThunk } from 'app/store';
import { User } from 'app/store/types';
import * as Actions from 'app/store/actions';
import React from 'react';
import * as appActions from './app.actions';
import * as licenseGroupsActions from './licenseGroups.actions';

const errors = ['unexpected-error'];

const findErrors = (res: any) => {
	const err: any = [];
	arrayify(res).forEach((response: any) => {
		if (Array.isArray(response.data)) {
			response.data.forEach((datum: any) => {
				if (datum.type) {
					err.push(datum.type);
				}
			});
		}
	});
	return err;
};

const findSuccessfullyRemovedUserIds = (userIds: User['id'][], res: any) => {
	const userIdsNotDeleted: User['id'][] = [];
	arrayify(res).forEach((response: any) => {
		if (Array.isArray(response.data)) {
			response.data.forEach((datum: any) => {
				if (datum.data && datum.data.user) {
					userIdsNotDeleted.push(datum.data.user);
				}
			});
		}
	});
	return userIds.filter(userId => !userIdsNotDeleted.includes(userId));
};

export const addUsersToUserGroup = (payload: {
	users: Array<{ id: string; add?: Array<string>; delete?: Array<string> }>;
}): AppThunk => async (dispatch, getState) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());

	try {
		const responses = await axios.patch(`/api/user/${licenseGroupId}/user-group`, payload);

		console.log(responses);
		const err: any = findErrors(responses);

		dispatch(
			licenseGroupsActions.getSelectedLicenseGroupData(() => {
				if (err.length === 0) {
					if (responseErrors(responses).length) {
						dispatch(appActions.alert('failed to add some users to user group', 'warning'));
					} else {
						dispatch(appActions.alert(payload.users.length > 1 ? 'users moved' : 'user moved', 'success'));
					}
				} else {
					if (err.includes('not-found')) {
						dispatch(appActions.alert('failed to add some users to user group', 'warning'));
					}
					if (err.includes('unauthorized')) {
						dispatch(appActions.alert('failed to add some users to user group', 'warning'));
					}
					if (err.includes('assign-user-group-failed')) {
						dispatch(appActions.alert('failed to add some users to user group', 'warning'));
					}
					if (err.includes('remove-user-group-failed')) {
						dispatch(appActions.alert('failed to add some users to user group', 'warning'));
					}
					if (err.includes('unexpected-error')) {
						dispatch(appActions.alert('failed to add some users to user group', 'warning'));
					}
					if (err.includes('already-assigned')) {
						dispatch(
							appActions.alert(payload.users.length > 1 ? 'users in group' : 'user in group', 'warning')
						);
					}
				}
			})
		);
	} catch (error) {
		dispatch(appActions.handleError(error, 1 ? 'user:error:move users' : 'user:error:move user'));
	}
};

export const blockMove = (): AppThunk => async (dispatch, getState) => {
	dispatch(appActions.alert('cannot move blocked users', 'warning'));
};

export const resendInvite = (userIds: string[]): AppThunk => async (dispatch, getState) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());

	try {
		const response = await Promise.all(
			userIds.map(userId =>
				axios.patch(`/api/user/${licenseGroupId}/invitation`, {
					send: [userId]
				})
			)
		);
		if (responseErrors(response).length) {
			dispatch(appActions.alert('failed to resend invite', 'warning'));
		} else {
			dispatch(appActions.alert('invite resent', 'success'));
		}
	} catch (error) {
		dispatch(appActions.handleError(error, 'user:error:resend invite'));
	}
};

export const addUsers = (emails: string[], roles: string[][]): AppThunk => async (dispatch, getState) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());

	try {
		// const response = await Promise.all(
		// 	emails.map(email =>
		// 		axios.patch(`/api/user/${licenseGroupId}`, {
		// 			add: [{ email, roles: [roles[emails.indexOf(email)]] }]
		// 		})
		// 	)
		// );
		// const response = await axios.patch(`/api/user/${licenseGroupId}`, {
		// 	add: emails.map(email => ({ email, roles: [roles[emails.indexOf(email)]] }))
		// });
		const response = await Promise.all(
			_.chunk(emails, 10).map(chunk =>
				axios.patch(`/api/user/${licenseGroupId}`, {
					add: chunk.map(email => ({ email, roles: roles[emails.indexOf(email)] }))
				})
			)
		);
		console.log(response);
		if (responseErrors(response).length) {
			// dispatch(appActions.alert('failed to add some users', 'warning'));
			if (emails.length > 1) {
				dispatch(
					Actions.alert(
						<div>
							<>
								<Translation>{t => t('add users:failed:header')}</Translation>
								<ul className="list-disc ml-24">
									{emails.map(email => {
										return <li key={email}>{email}</li>;
									})}
								</ul>
							</>
						</div>,
						'warning'
					)
				);
			} else {
				dispatch(appActions.alert('failed to add user'));
			}
		} else {
			dispatch(appActions.alert(emails.length > 1 ? 'users added' : 'user added', 'success'));
			// emails.forEach(async email => {
			// 	try {
			// 		const apiResponse = await axios.put(`${process.env.REACT_APP_METADATA_URL}/api/v1/user/${email}`, {
			// 			id: email,
			// 			data: {
			// 				last: '',
			// 				domain: 'stratus',
			// 				profile: 'n/a',
			// 				name: 'NA',
			// 				dept: 'unknown',
			// 				title: '',
			// 				email,
			// 				first: '',
			// 				full: '',
			// 				home: '???'
			// 			}
			// 		});
			// 		console.log(apiResponse);
			// 	} catch (err) {
			// 		console.log(err);
			// 	}
			// });
		}

		dispatch(licenseGroupsActions.getSelectedLicenseGroupData());
	} catch (error) {
		dispatch(appActions.handleError(error, 'user:error:invite user'));
		dispatch(licenseGroupsActions.getSelectedLicenseGroupData());
	}
};

// CHANGEME::once finalized this logic should likely be moved to the backend (it doesn't even get the latest workflows data)
const removeUsersFromWorkflows = (userIds: User['id'][], replaceOwnerWithCurrentUser = true): AppThunk => async (
	dispatch,
	getState
) => {
	const workflows = getWorkflows(getState());
	const newOwnerId = getProfile(getState()).id;
	const profile = getProfile(getState());
	const wfxApiUrl = getWfxUrl(profile?.awsRegion);

	const modifiedWorkflowAcls = workflows
		// get list of only relevant workflows
		.filter(workflow => workflow.acl.users.some(({ name }) => userIds.includes(name)))
		// get list of acls with...
		.map(({ id, acl }) => ({
			id,
			acl: {
				...acl,
				users: acl.users
					.map(aclUser =>
						// ...workflow owner users replaced (if applicable), and...
						userIds.includes(aclUser.name) && aclUser.role === 'owner' && replaceOwnerWithCurrentUser
							? { name: newOwnerId, role: 'owner' }
							: aclUser
					)
					// ...removed users deleted
					.filter(({ name }) => !userIds.includes(name))
			}
		}));

	const responses = await Promise.all(
		modifiedWorkflowAcls.map(({ id, acl }) => axios.post(`${wfxApiUrl}/api/wfx/${id}/acl`, acl))
	);

	if (responseErrors(responses).length) {
		throw new Error();
	}
};

export const removeUsers = (userIds: User['id'][]): AppThunk => async (dispatch, getState) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());
	const profile = getProfile(getState());
	const users = getUsers(getState());
	const allUsersById = getAllUsersById(getState());

	let selfDeletion = false;
	for (let i = userIds.length - 1; i >= 0; i -= 1) {
		if (userIds[i] === profile.id) {
			selfDeletion = true;
			userIds.splice(i, 1);
		}
	}

	try {
		// const responses = await axios.patch(`/api/user/${licenseGroupId}`, { delete: userIds });
		const responses = Promise.all(
			_.chunk(userIds, 10).map(chunk =>
				axios.patch(`/api/user/${licenseGroupId}`, {
					delete: chunk.map(userId => userId)
				})
			)
		);
		// if (responseErrors(responses).length) {
		// 	dispatch(appActions.alert('failed to remove some users', 'warning'));
		// } else {
		// 	const err: any = findErrors(responses);
		// 	if (selfDeletion) {
		// 		err.push('owner');
		// 	}
		// 	if (err.length === 0) {
		// 		dispatch(appActions.alert(userIds.length > 1 ? 'users removed' : 'user removed', 'success'));
		// 	} else {
		// 		if (err.includes('not-found')) {
		// 			dispatch(appActions.alert('user not found', 'warning'));
		// 		}
		// 		if (err.includes('owner')) {
		// 			if (
		// 				users.filter(user => user.permissions['tenant-admin'] && !user.blocked).length ===
		// 				userIds.filter(userId =>
		// 					users.find(user => user.id === userId && user.permissions['tenant-admin'] && !user.blocked)
		// 				).length
		// 			) {
		// 				dispatch(appActions.alert('must keep at least one admin', 'warning'));
		// 			} else {
		// 				dispatch(appActions.alert('cannot remove owner', 'warning'));
		// 			}
		// 		}
		// 		if (err.includes('keep-one-admin')) {
		// 			dispatch(appActions.alert('must keep at least one admin', 'warning'));
		// 		}
		// 	}
		// }
		const successfullyRemovedUserIds = findSuccessfullyRemovedUserIds(userIds, (await responses).flat());
		await dispatch(removeUsersFromWorkflows(successfullyRemovedUserIds));
		if (successfullyRemovedUserIds.includes(profile.id)) {
			window.location.reload();
		} else {
			dispatch(
				licenseGroupsActions.getSelectedLicenseGroupData(() => {
					if (responseErrors(responses).length) {
						dispatch(appActions.alert('failed to remove some users', 'warning'));
					} else {
						const err: any = findErrors(responses);
						if (selfDeletion) {
							err.push('owner');
						}
						if (err.length === 0) {
							dispatch(
								appActions.alert(userIds.length > 1 ? 'users removed' : 'user removed', 'success')
							);
						} else {
							if (err.includes('not-found')) {
								dispatch(appActions.alert('user not found', 'warning'));
							}
							if (err.includes('owner')) {
								if (
									users.filter(user => user.permissions['tenant-admin'] && !user.blocked).length ===
									userIds.filter(userId =>
										users.find(
											user =>
												user.id === userId && user.permissions['tenant-admin'] && !user.blocked
										)
									).length
								) {
									dispatch(appActions.alert('must keep at least one admin', 'warning'));
								} else {
									dispatch(appActions.alert('cannot remove owner', 'warning'));
								}
							}
							if (err.includes('keep-one-admin')) {
								dispatch(appActions.alert('must keep at least one admin', 'warning'));
							}
						}
					}
				})
			);
		}
	} catch (error) {
		dispatch(
			appActions.handleError(
				error,
				userIds.length > 1
					? 'user:error:remove users'
					: allUsersById[userIds[0]].pending
					? 'user:error:cancel invite'
					: 'user:error:remove user'
			)
		);
	}
};

export const changeUserRole = (userIds: User['id'][], roles: string[]): AppThunk => async (dispatch, getState) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());

	try {
		const response = await axios.patch(`/api/user/${licenseGroupId}/roles`, {
			users: userIds,
			roles
		});
		const err: any = findErrors(response);
		if (err.length === 0) {
			dispatch(appActions.alert(userIds.length > 1 ? 'user roles updated' : 'user role updated', 'success'));
		} else {
			if (err.includes('unauthorized')) {
				dispatch(appActions.alert('unauthorized', 'warning'));
			}
			if (err.includes('already-assigned-role')) {
				dispatch(appActions.alert('user role already assigned', 'warning'));
			}
			if (err.includes('keep-one-admin')) {
				dispatch(appActions.alert('must keep at least one admin', 'warning'));
			}
			if (err.includes('updated-user-roles-failed')) {
				dispatch(appActions.alert('user:error:change role', 'warning'));
			}
			if (err.includes('unexpected-error')) {
				dispatch(appActions.alert('user:error:change role', 'warning'));
			}
		}
		dispatch(licenseGroupsActions.getSelectedLicenseGroupData());
	} catch (error) {
		dispatch(appActions.handleError(error, 'user:error:change role'));
	}
};

export const blockUser = (userIds: User['id'][]): AppThunk => async (dispatch, getState) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());
	const profile = getProfile(getState());

	try {
		const response = await axios.patch(`/api/user/${licenseGroupId}/block-list`, {
			block: userIds
		});
		const err: any = findErrors(response);
		// if (err.length === 0) {
		// 	dispatch(appActions.alert(userIds.length > 1 ? 'users blocked' : 'user blocked', 'success'));
		// } else {
		// 	if (err.includes('unauthorized')) {
		// 		dispatch(appActions.alert('unauthorized', 'warning'));
		// 	}
		// 	if (err.includes('already-blocked')) {
		// 		dispatch(appActions.alert('user already blocked', 'warning'));
		// 	}
		// 	if (err.includes('pending-user')) {
		// 		dispatch(appActions.alert('cannot block pending user', 'warning'));
		// 	}
		// 	if (err.includes('keep-one-admin')) {
		// 		dispatch(appActions.alert('must keep at least one admin', 'warning'));
		// 	}
		// 	if (err.includes('block-user-failed')) {
		// 		dispatch(appActions.alert('user:error:block user', 'warning'));
		// 	}
		// 	if (err.includes('unexpected-error')) {
		// 		dispatch(appActions.alert('user:error:block user', 'warning'));
		// 	}
		// }
		if (userIds.includes(profile.id)) {
			window.location.reload();
		} else {
			dispatch(
				licenseGroupsActions.getSelectedLicenseGroupData(() => {
					if (err.length === 0) {
						dispatch(appActions.alert(userIds.length > 1 ? 'users blocked' : 'user blocked', 'success'));
					} else {
						if (err.includes('unauthorized')) {
							dispatch(appActions.alert('unauthorized', 'warning'));
						}
						if (err.includes('already-blocked')) {
							dispatch(appActions.alert('user already blocked', 'warning'));
						}
						if (err.includes('pending-user')) {
							dispatch(appActions.alert('cannot block pending user', 'warning'));
						}
						if (err.includes('keep-one-admin')) {
							dispatch(appActions.alert('must keep at least one admin', 'warning'));
						}
						if (err.includes('block-user-failed')) {
							dispatch(appActions.alert('user:error:block user', 'warning'));
						}
						if (err.includes('unexpected-error')) {
							dispatch(appActions.alert('user:error:block user', 'warning'));
						}
					}
				})
			);
		}
	} catch (error) {
		dispatch(appActions.handleError(error, 'user:error:block user'));
	}
};

export const unblockUser = (userIds: User['id'][]): AppThunk => async (dispatch, getState) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());

	try {
		const response = await axios.patch(`/api/user/${licenseGroupId}/block-list`, { unblock: userIds });
		const err: any = findErrors(response);
		if (err.length === 0) {
			// dispatch(appActions.alert(userIds.length > 1 ? 'users unblocked' : 'user unblocked', 'success'));
			dispatch(
				licenseGroupsActions.getSelectedLicenseGroupData(() => {
					dispatch(appActions.alert(userIds.length > 1 ? 'users unblocked' : 'user unblocked', 'success'));
				})
			);
		} else {
			if (err.includes('unauthorized')) {
				dispatch(appActions.alert('user:error:unblock user', 'warning'));
			}
			if (err.includes('already-unblocked')) {
				dispatch(appActions.alert('user already unblocked', 'warning'));
			}
			if (err.includes('unblock-user-failed')) {
				dispatch(appActions.alert('user:error:unblock user', 'warning'));
			}
			if (err.includes('unexpected-error')) {
				dispatch(appActions.alert('user:error:unblock user', 'warning'));
			}
		}
	} catch (error) {
		dispatch(appActions.handleError(error, 'user:error:unblock user'));
	}
};

export const editUserRoles = (
	id: string,
	roleIds: string[],
	keepGroupRole: boolean,
	groupId: string | undefined,
	option: string
): AppThunk => async (dispatch, getState) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());
	const data = {
		id,
		roleIds,
		...(!keepGroupRole && groupId && { groupId, option })
	};

	try {
		const response = await axios.patch(`/api/user/${licenseGroupId}/user-roles`, data);

		const err: any = findErrors(response);
		dispatch(
			licenseGroupsActions.getSelectedLicenseGroupData(() => {
				if (responseErrors(response).length || _.intersection(err, errors).length > 0) {
					dispatch(appActions.alert('user role:edit:fail', 'error'));
				} else if (err.length === 0) {
					dispatch(appActions.alert('user role:edit:success', 'success'));
				}
			})
		);
	} catch (error) {
		// TODO: update error handling
		dispatch(appActions.handleError(error));
	}
};

// export const editUsers = (
// 	userIds: User['id'][],
// 	{ permissions: modifiedPermissions }: Partial<User>
// ): AppThunk => async (dispatch, getState) => {
// 	const licenseGroupId = getSelectedLicenseGroupId(getState());
// 	const usersById = getUsersById(getState());

// 	const mergePermissions = (userId: User['id'], newPermissions: User['permissions'] | undefined) => {
// 		return {
// 			...usersById[userId].permissions,
// 			...newPermissions
// 		};
// 	};

// 	try {
// 		// CHANGEME::commented out to force to work
// 		// const responses = await Promise.all(
// 		// 	_.chunk(userIds, 25).map(chunk =>
// 		// 		axios.patch(
// 		// 			`/api/v1/user/${licenseGroupId}`,
// 		// 			chunk.map(userId => ({ userId, permissions: mergePermissions(userId, modifiedPermissions) }))
// 		// 		)
// 		// 	)
// 		// );
// 		// if (responseErrors(responses).length) {
// 		// 	dispatch(appActions.alert((userIds.length > 1 ? 'failed to update users' : 'failed to update user', 'warning'));
// 		// } else {
// 		// 	dispatch(appActions.alert(userIds.length > 1 ? 'users updated' : 'user updated', 'success'));
// 		// }
// 		dispatch(appActions.alert(userIds.length > 1 ? 'users updated' : 'user updated', 'success'));
// 		dispatch(licenseGroupsActions.getSelectedLicenseGroupData());
// 	} catch (error) {
// 		dispatch(appActions.handleError(error));
// 	}
// };
