import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import * as Actions from 'app/store/actions';
import React from 'react';
import { useDispatch } from 'app/modules/react-redux';

function NavbarMobileToggleButton(props) {
	const dispatch = useDispatch();

	return (
		<IconButton
			className={`${props.className} starter:text-white`}
			onClick={_ev => dispatch(Actions.navbarToggleMobile())}
			// disableRipple
			style={{ height: 32, width: 32, margin: 4 }}
		>
			{props.children}
		</IconButton>
	);
}

NavbarMobileToggleButton.defaultProps = {
	children: <Icon>menu</Icon>
};

export default NavbarMobileToggleButton;
