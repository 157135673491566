import FuseDialog from '@fuse/core/FuseDialog';
import FuseMessage from '@fuse/core/FuseMessage';
import FuseScrollbars from '@fuse/core/FuseScrollbars';
import FuseSuspense from '@fuse/core/FuseSuspense';
import { makeStyles } from '@material-ui/core/styles';
import AppContext from 'app/AppContext';
// import SettingsPanel from 'app/fuse-layouts/shared-components/SettingsPanel';
import clsx from 'clsx';
import React, { useContext } from 'react';
import { useSelector } from 'app/modules/react-redux';
import { getHideNavbar, getUserPreferences } from 'app/store/reducers';
import ErrorBoundary from 'app/components/ErrorBoundary';
import InitializeApp from 'app/components/InitializeApp';
import CookieConsentMessage from 'app/components/CookieConsentMessage';
import { renderRoutes, RouteConfig } from 'react-router-config';
import FooterLayout1 from './components/FooterLayout1';
import LeftSideLayout1 from './components/LeftSideLayout1';
import NavbarWrapperLayout1 from './components/NavbarWrapperLayout1';
import RightSideLayout1 from './components/RightSideLayout1';
import ToolbarLayout1 from './components/ToolbarLayout1';

const useStyles = makeStyles(theme => ({
	root: {
		position: 'relative',
		display: 'flex',
		flexDirection: 'row',
		width: '100%',
		height: '100%',
		overflow: 'hidden',
		color: theme.palette.text.primary,
		backgroundColor: '#FFFFFF',
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		'&.boxed': {
			maxWidth: 1280,
			margin: '0 auto',
			boxShadow: theme.shadows[3]
		},
		'&.scroll-body': {
			'& $wrapper': {
				height: 'auto',
				flex: '0 0 auto',
				overflow: 'auto'
			},
			'& $contentWrapper': {},
			'& $content': {}
		},
		'&.scroll-content': {
			'& $wrapper': {},
			'& $contentWrapper': {},
			'& $content': {}
		},
		'& .navigation': {
			'& .list-subheader-text, & .list-item-text, & .item-badge, & .arrow-icon': {
				transition: theme.transitions.create('opacity', {
					duration: theme.transitions.duration.short,
					easing: theme.transitions.easing.easeInOut
				})
			}
		}
	},
	wrapper: {
		display: 'flex',
		position: 'relative',
		width: '100%',
		height: '100%',
		flex: '1 1 auto'
	},
	contentWrapper: {
		display: 'flex',
		flexDirection: 'column',
		position: 'relative',
		zIndex: 3,
		overflow: 'hidden',
		flex: '1 1 auto'
	},
	content: {
		position: 'relative',
		display: 'flex',
		overflow: 'auto',
		flex: '1 1 auto',
		flexDirection: 'column',
		width: '100%',
		'-webkit-overflow-scrolling': 'touch',
		zIndex: 2,
		top: 64,
		marginBottom: 64
	}
}));

type Props = {
	children: React.ReactNode;
};
function Layout1(props: Props) {
	const config = useSelector(({ fuse }) => fuse.settings.current.layout.config);
	const hideNavbar = useSelector(getHideNavbar);
	const { siteBackground } = useSelector(getUserPreferences);

	const appContext = useContext(AppContext);
	const classes = useStyles(props);
	const { routes } = appContext as { routes: RouteConfig[] };

	// console.warn('FuseLayout:: rendered');

	switch (config.scroll) {
		case 'body': {
			return (
				<div
					id="fuse-layout"
					className={clsx(classes.root, config.mode, `scroll-${config.scroll}`)}
					style={{ backgroundImage: `url("${siteBackground}")` }}
				>
					{config.leftSidePanel.display && <LeftSideLayout1 />}

					<div className="flex flex-1 flex-col overflow-hidden relative">
						{config.toolbar.display &&
							config.toolbar.style === 'fixed' &&
							config.toolbar.position === 'above' && <ToolbarLayout1 adminPage={config.adminPage} />}

						<FuseScrollbars className="overflow-auto" scrollToTopOnRouteChange>
							{config.toolbar.display &&
								config.toolbar.style !== 'fixed' &&
								config.toolbar.position === 'above' && <ToolbarLayout1 adminPage={config.adminPage} />}

							<div className={classes.wrapper}>
								{config.navbar.display && config.navbar.position === 'left' && !hideNavbar && (
									<NavbarWrapperLayout1 adminPage={config.adminPage} />
								)}

								<div className={classes.contentWrapper}>
									{config.toolbar.display && config.toolbar.position === 'below' && (
										<ToolbarLayout1 adminPage={config.adminPage} />
									)}

									<div className={classes.content}>
										<FuseDialog />

										<FuseSuspense>
											<ErrorBoundary>{renderRoutes(routes)}</ErrorBoundary>
										</FuseSuspense>

										{props.children}
									</div>

									{config.footer.display && config.footer.position === 'below' && <FooterLayout1 />}

									{/* <SettingsPanel /> */}
								</div>

								{config.navbar.display && config.navbar.position === 'right' && !hideNavbar && (
									<NavbarWrapperLayout1 adminPage={config.adminPage} />
								)}
							</div>

							{config.footer.display &&
								config.footer.style !== 'fixed' &&
								config.footer.position === 'above' && <FooterLayout1 />}
						</FuseScrollbars>

						{config.footer.display &&
							config.footer.style === 'fixed' &&
							config.footer.position === 'above' && <FooterLayout1 />}
					</div>

					{config.rightSidePanel.display && <RightSideLayout1 />}

					<CookieConsentMessage />

					<FuseMessage />
				</div>
			);
		}
		case 'content':
		default: {
			return (
				<div
					id="fuse-layout"
					className={clsx(classes.root, config.mode, `scroll-${config.scroll}`)}
					style={{ backgroundImage: `url("${siteBackground}")` }}
				>
					{config.leftSidePanel.display && <LeftSideLayout1 />}

					<div className="flex flex-1 flex-col overflow-hidden relative">
						{config.toolbar.display && config.toolbar.position === 'above' && (
							<ToolbarLayout1 adminPage={config.adminPage} />
						)}

						<div className={classes.wrapper}>
							{config.navbar.display && config.navbar.position === 'left' && !hideNavbar && (
								<NavbarWrapperLayout1 adminPage={config.adminPage} />
							)}

							<div className={classes.contentWrapper}>
								{config.toolbar.display &&
									config.toolbar.position === 'below' &&
									config.toolbar.style === 'fixed' && <ToolbarLayout1 adminPage={config.adminPage} />}

								<FuseScrollbars className={classes.content} scrollToTopOnRouteChange>
									{config.toolbar.display &&
										config.toolbar.position === 'below' &&
										config.toolbar.style !== 'fixed' && (
											<ToolbarLayout1 adminPage={config.adminPage} />
										)}

									<FuseDialog />

									<FuseSuspense>
										<ErrorBoundary>{renderRoutes(routes)}</ErrorBoundary>
									</FuseSuspense>

									{props.children}

									{config.footer.display &&
										config.footer.position === 'below' &&
										config.footer.style !== 'fixed' && <FooterLayout1 />}
								</FuseScrollbars>

								{config.footer.display &&
									config.footer.position === 'below' &&
									config.footer.style === 'fixed' && <FooterLayout1 />}

								{/* <SettingsPanel /> */}
							</div>

							{config.navbar.display && config.navbar.position === 'right' && !hideNavbar && (
								<NavbarWrapperLayout1 adminPage={config.adminPage} />
							)}
						</div>

						{config.footer.display && config.footer.position === 'above' && <FooterLayout1 />}
					</div>

					{config.rightSidePanel.display && <RightSideLayout1 />}

					<CookieConsentMessage />

					<FuseMessage />
				</div>
			);
		}
	}
}

const Layout1Wrapper = (props: Props) => (
	<FuseSuspense fullscreen>
		<ErrorBoundary>
			<InitializeApp>
				<Layout1 {...props} />
			</InitializeApp>
		</ErrorBoundary>
	</FuseSuspense>
);

export default React.memo(Layout1Wrapper);
