import React from 'react';
// import Accordion from '@material-ui/core/Accordion';
// import AccordionSummary from '@material-ui/core/AccordionSummary';
// import AccordionDetails from '@material-ui/core/AccordionDetails';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import Logo from 'app/fuse-layouts/shared-components/Logo';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import * as Actions from 'app/store/actions';
import { getCookieNagbarAcknowledged, getCookieSettings } from 'app/store/reducers';
import { useDispatch, useSelector } from 'app/modules/react-redux';
import { useTranslation } from 'react-i18next';
import { Collapse } from '@material-ui/core';
import clsx from 'clsx';

//
// Styles
//

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		closeButton: {
			position: 'absolute',
			right: theme.spacing(1),
			top: theme.spacing(0.6),
			color: '#FFFFFF'
		},
		button: {
			marginRight: '14px',
			textTransform: 'capitalize',
			width: '276px'
		},
		dialogContent: {
			// paddingTop: '1.5em',
			overflow: 'hidden',
			boxShadow:
				'0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
			margin: 16,
			padding: '16px 0',
			borderRadius: 4,
			'& > .MuiPaper-root': {
				padding: '0 16px',
				minHeight: 64
			}
		},
		logoBackground: {
			backgroundColor: theme.palette.secondary.main
		},
		collapseBtn: {
			height: 48,
			borderRadius: 8,
			top: 32,
			left: -20
		}
	})
);

//
// Components
//
const CookieSettingsDescription = () => {
	const { t } = useTranslation();

	return (
		<DialogContent>
			<Grid item xs container direction="column" spacing={2}>
				<Grid item xs>
					<Typography gutterBottom variant="h6">
						{t('Cookie Settings')}
					</Typography>
					<Typography gutterBottom color="textSecondary">
						{t('cookie consent:description 1')}
					</Typography>
					<Typography gutterBottom color="textSecondary">
						{t('cookie consent:description 2')}
					</Typography>
					<Typography gutterBottom color="textSecondary">
						{t('cookie consent:description 3')}
					</Typography>
				</Grid>
			</Grid>
		</DialogContent>
	);
};

const CookieSettingsList = () => {
	const { t } = useTranslation();
	const classes = useStyles();
	const [showDetails, setShowDetails] = React.useState(true);

	const toggleDetails = () => {
		setShowDetails(!showDetails);
	};

	return (
		<DialogContent className={classes.dialogContent}>
			<Paper elevation={0} style={{ margin: '12px 0', marginTop: 0 }}>
				<Grid container spacing={3}>
					<Grid item xs={10} sm container>
						<Grid item xs container direction="column" spacing={2}>
							<Grid item xs>
								<Typography gutterBottom variant="subtitle1">
									<strong>{t('cookie consent:technically necessary subtitle')}</strong>
								</Typography>
								<Typography variant="body2" color="textSecondary">
									{t('cookie consent:technically necessary text')}
								</Typography>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={2} alignContent="center" container spacing={2}>
						<Grid item>
							<FormControlLabel
								disabled
								className="mt-4 mb-8 mr-8"
								label=""
								onClick={event => event.stopPropagation()}
								control={
									<Switch
										checked
										id="cookieSettingsSwitch"
										name="cookieSettingsSwitch"
										onChange={undefined}
									/>
								}
							/>
						</Grid>
					</Grid>
					<IconButton className={classes.collapseBtn}>
						<ExpandMoreIcon
							className={clsx('transition-transform transform', {
								'rotate-x-180': showDetails
							})}
							onClick={toggleDetails}
						/>
					</IconButton>
				</Grid>
			</Paper>
			<Divider style={{ display: showDetails ? 'block' : 'none' }} />
			<Collapse style={{ padding: '0 16px', marginTop: 12 }} in={showDetails}>
				<Grid item xs container direction="column" spacing={2}>
					<Grid item xs>
						<Typography gutterBottom variant="subtitle1">
							<strong>{t('cookie consent:session subtitle')}</strong>
						</Typography>
						<Typography variant="body2" color="textSecondary" gutterBottom>
							{t('cookie consent:session text 1')}
						</Typography>
						<Typography variant="body2" color="textSecondary" gutterBottom>
							{t('cookie consent:session text 2')}
						</Typography>
					</Grid>
				</Grid>
				<Grid item xs container direction="column" spacing={2}>
					<Grid item xs>
						<Typography gutterBottom variant="subtitle1">
							<strong>{t('cookie consent:license group selector subtitle')}</strong>
						</Typography>
						<Typography variant="body2" color="textSecondary" gutterBottom>
							{t('cookie consent:license group selector text 1')}
						</Typography>
					</Grid>
				</Grid>
			</Collapse>
		</DialogContent>
	);
};

const Footer = () => {
	const classes = useStyles();
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const handleClose = () => {
		dispatch(Actions.acknowledgeCookieNagbar());
	};

	const handleOnClick = (e: React.MouseEvent<HTMLElement>) => {
		e.preventDefault();
		dispatch(Actions.acknowledgeCookieNagbar());
		handleClose();
	};

	return (
		<DialogActions>
			<Button
				className={classes.button}
				onClick={handleOnClick}
				color="secondary"
				variant="contained"
				type="submit"
			>
				{t('Accept')}
			</Button>
		</DialogActions>
	);
};

const Header = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const showCookieSettings = useSelector(getCookieSettings);

	const handleClose = () => {
		dispatch(Actions.acknowledgeCookieNagbar());
		dispatch(Actions.setCookieSettings(false));
	};

	return (
		<DialogTitle id="alert-dialog-title" className={classes.logoBackground}>
			<Box color="secondary" style={{ marginLeft: -13 }}>
				<div className="flex flex-1 mx-8">
					<Logo classes={{ logo: 'starter:text-white' }} />
				</div>
			</Box>
			{showCookieSettings ? (
				<IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
					<CloseIcon />
				</IconButton>
			) : null}
		</DialogTitle>
	);
};

//
// Main Component
//

const CookieSettingsDialog = () => {
	const cookieAcknowledged = useSelector(getCookieNagbarAcknowledged);
	const showCookieSettings = useSelector(getCookieSettings);
	const showDialog = !cookieAcknowledged || showCookieSettings;

	// const handleClose = () => {
	// 	dispatch(Actions.acknowledgeCookieNagbar());
	// };

	return (
		<Dialog
			open={showDialog}
			// onClose={handleClose}
			maxWidth="sm"
			aria-labelledby="Accept"
			aria-describedby="Accept"
		>
			<Header />
			<Divider />
			<CookieSettingsDescription />
			<Divider />
			<CookieSettingsList />
			{cookieAcknowledged ? null : <Footer />}
		</Dialog>
	);
};

export default CookieSettingsDialog;
