import { Divider } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Hidden from '@material-ui/core/Hidden';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Notification from 'app/components/Notification';
import StandardButton from 'app/components/StandardButton';
import LicenseGroupsMenu from 'app/fuse-layouts/shared-components/LicenseGroupsMenu';
import Logo from 'app/fuse-layouts/shared-components/Logo';
import NavbarMobileToggleButton from 'app/fuse-layouts/shared-components/NavbarMobileToggleButton';
import UserMenu from 'app/fuse-layouts/shared-components/UserMenu';
import useGetPageSection from 'app/hooks/useGetPageSection';
import { useSelector } from 'app/modules/react-redux';
import { getHideNavbar, getProfile, getSelectedLicenseGroupData, getUserPreferences } from 'app/store/reducers';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
	appBar: {
		background: `linear-gradient(90deg, #262626 0%, #534C64 74%, #665984 91%, #7D61BC 100%), url('${process.env.PUBLIC_URL}/assets/images/banners/header.png')`,
		backgroundBlendMode: 'soft-light',
		backgroundSize: 'contain',
		backgroundPosition: '100% center',
		backgroundRepeat: 'no-repeat'
	},
	publicAppBar: {
		background: 'none',
		backgroundColor: '#231231'
	},
	separator: {
		width: 1,
		height: 64,
		backgroundColor: theme.palette.divider
	},
	btnLink: {
		'&:hover': {
			textDecoration: 'none !important'
		}
	}
}));

function ToolbarLayout1({ adminPage = false }: { adminPage: boolean }) {
	const { t } = useTranslation();
	const history = useHistory();
	const config = useSelector(({ fuse }) => fuse.settings.current.layout.config);
	const location = useLocation();
	const hideNavbar = useSelector(getHideNavbar);
	const pageSection = useGetPageSection();
	const licenseGroupData = useSelector(getSelectedLicenseGroupData) as
		| ReturnType<typeof getSelectedLicenseGroupData>
		| undefined; // HACK::use actual type here - assume never undefined within the wrapped app

	const profile = useSelector(getProfile) as ReturnType<typeof getProfile> | undefined; // HACK::use actual type here - assume never undefined within the wrapped app

	const { pageHeaderBackgroundColor } = useSelector(getUserPreferences);

	const classes = useStyles();

	return (
		<AppBar
			id="fuse-toolbar"
			style={
				localStorage.getItem('enterprise')
					? {
							background: 'none',
							backgroundColor: pageHeaderBackgroundColor
					  }
					: undefined
			}
			className={clsx('flex z-10 enterprise:shadow-0', classes.appBar, {
				[classes.publicAppBar]: pageSection.public
			})}
			color="secondary"
		>
			<Toolbar className="p-0">
				{adminPage && config.navbar.display && config.navbar.position === 'left' && !hideNavbar && (
					<Hidden lgUp>
						<NavbarMobileToggleButton className="w-64 h-64 p-0" />
						<div className={classes.separator} />
					</Hidden>
				)}

				{adminPage ? (
					<>
						<div>
							<Logo link classes={{ logo: 'starter:text-white' }} />
						</div>
						<Divider style={{ backgroundColor: 'white', height: 50 }} orientation="vertical" />
					</>
				) : (
					<div className="flex justify-start">
						<div className="flex flex-shrink-0 items-center pl-12">
							{pageSection.pricing ? (
								// <Link to="/welcome" role="button">
								// 	<Button color="secondary" variant="contained">
								// 		{t('Back to Overview')}
								// 	</Button>
								// </Link>
								<a className={classes.btnLink} href="/">
									<StandardButton
										className="MuiButton-containedSecondary"
										btnText={t('Back to Overview')}
										onClick={() => history.push('/welcome')}
									/>
								</a>
							) : pageSection.welcome ? (
								<span />
							) : (
								<Logo classes={{ logo: 'starter:text-white' }} />
							)}
						</div>
					</div>
				)}

				{pageSection.admin && licenseGroupData && (
					<div className="flex items-center">
						<LicenseGroupsMenu />
					</div>
				)}

				<div className="flex ml-auto justify-end">
					{profile ? (
						<>
							{adminPage && licenseGroupData && licenseGroupData.dataLoaded && <Notification />}

							{/* FIXME:::::figure out if tenant managers would see notifications ? */}
							{/* This checks to only add the icon if it isn't already on the tenant management page, to avoid more than one notfication icons */}
							{/* {licenseGroupData &&
									!licenseGroupData.dataLoaded &&
									location.pathname.includes('/manager/tenants') && <Notification />} */}

							<UserMenu adminPage={adminPage} />
						</>
					) : (
						// <Button
						// 	className="text-white mr-12"
						// 	href={
						// 		adminPage
						// 			? `/api/sso/login?redirect=${encodeURIComponent(location.pathname)}`
						// 			: `/api/sso/mp/login?redirect=${encodeURIComponent(location.pathname)}`
						// 	}
						// 	role="button"
						// 	variant="contained"
						// >
						// 	{t('Login')}
						// </Button>
						<a
							className="mr-12"
							style={{ textDecoration: 'none' }}
							href={
								adminPage
									? `/api/sso/login?redirect=${encodeURIComponent(location.pathname)}`
									: `/api/sso/mp/login?redirect=${encodeURIComponent(location.pathname)}`
							}
						>
							<StandardButton btnText={t('Login')} />
						</a>
					)}

					{/* <div className={classes.separator} />

						<LanguageSwitcher />

						<Hidden mdDown>
							<div className={classes.separator} />
						</Hidden> */}
				</div>

				{adminPage && config.navbar.display && config.navbar.position === 'right' && !hideNavbar && (
					<Hidden lgUp>
						<NavbarMobileToggleButton />
					</Hidden>
				)}
			</Toolbar>
		</AppBar>
	);
}

export default React.memo(ToolbarLayout1);
