import FuseSplashScreen from '@fuse/core/FuseSplashScreen';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'app/modules/react-redux';
import ErrorMessageView from 'app/components/ErrorMessageView';
import * as Actions from 'app/store/actions';
import { getIsSupportedRegion } from 'app/store/reducers';
import NotAvailablePage from 'app/main/not-available/NotAvailablePage';

type Status = 'loading' | 'success' | 'error';
type Props = {
	children: React.ReactNode;
};

// FIXME::WARNING::this component cannot contain `useTranslation`. For some reason React.Suspense is not stopping this from rendering.
const InitializeApp = ({ children }: Props) => {
	const dispatch = useDispatch();
	const isSupportedRegion = useSelector(getIsSupportedRegion);
	const [status, setStatus] = useState<Status>('loading');

	useEffect(() => {
		Promise.all([
			dispatch(Actions.getProfile()),
			dispatch(Actions.getManagedLicenseGroups()),
			dispatch(Actions.getTenantManagerGroups()),
			// dispatch(Actions.getAdminedLicenseGroups()),
			// FIXME::why is `.then` not working?
			(dispatch(Actions.getRegion()) as any).then(() => dispatch(Actions.getCatalog()))
		])
			.then(() => {
				setStatus('success');
			})
			.catch(() => {
				setStatus('error');
			});
	}, [dispatch]);

	if (status === 'loading') {
		return <FuseSplashScreen />;
	}
	if (status === 'error') {
		return <ErrorMessageView />;
	}

	if (!isSupportedRegion) {
		return <NotAvailablePage />;
	}

	return <>{children}</>;
};

export default InitializeApp;
