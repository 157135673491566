import { ListItem, TextField, withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Skeleton from '@material-ui/lab/Skeleton';
import axios from 'app/client';
import { AddIcon, CloseIcon as RemoveFromListIcon, LicenseGroupLineIcon } from 'app/components/Icons';
import { useDispatch, useSelector } from 'app/modules/react-redux';
import * as Actions from 'app/store/actions';
import { getSelectedLicenseGroupData } from 'app/store/reducers';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type AdminedLicenseGroup = {
	id: string;
	name: string;
	domain: string;
};

// type Status = 'loading' | 'success' | 'error';

const useStyles = makeStyles(theme => ({
	menuItem: {
		padding: 0,
		display: 'flex',
		flexWrap: 'wrap'
	},
	gutter: {
		width: 20,
		height: 20,
		marginRight: 8
	},
	unconfiguredListItem: {
		marginBottom: 0,
		color: '#FFAA00',
		fontStyle: 'italic',
		'& span': {
			fontWeight: 'bold'
		}
	},
	orderNumber: {
		color: '#FFAA00'
	}
}));

const getSlug = (value: string) => {
	const urlRegex = /^(https?:\/\/)?(([a-zA-Z0-9]+([a-zA-Z0-9-]+[a-zA-Z0-9]+)?)\.tenant\..+)$/;
	return urlRegex.exec(value)?.[3] ?? value;
};

const LightTextField = localStorage.getItem('enterprise')
	? TextField
	: withStyles(theme => ({
			root: {
				'& .MuiOutlinedInput-root': {
					'& fieldset': {
						borderColor: '#CCCCCC'
					},
					'&:hover fieldset': {
						borderColor: '#CCCCCC'
					},
					'&.Mui-focused fieldset': {
						borderColor: '#CCCCCC'
					}
				},
				'& .MuiOutlinedInput-input': {
					color: '#FFFFFF',
					'&::placeholder': {
						color: '#E5E5E5 !important'
					}
				},
				'& .MuiInputLabel-root': {
					color: '#FFFFFF'
				}
			}
	  }))(TextField);

const LicenseGroupsMenu = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	// const adminedLicenseGroups = useSelector(getAdminedLicenseGroups);
	const licenseGroupData = useSelector(getSelectedLicenseGroupData);
	const classes = useStyles();

	const [addAdminLicenseGroupValue, setAddAdminLicenseGroupValue] = useState('');

	// const [status, setStatus] = useState<Status>('loading');
	const [adminedLicenseGroups, setAdminedLicenseGroups] = useState<AdminedLicenseGroup[] | undefined>();
	const [refetchAdminedLicenseGroupsCounter, setRefetchAdminedLicenseGroupsCounter] = useState(0);

	const refetchAdminedLicenseGroups = () => {
		setRefetchAdminedLicenseGroupsCounter(state => state + 1);
	};

	useEffect(() => {
		const getAdminedLicenseGroups = async () => {
			try {
				const { data } = await axios.get<any, { data: AdminedLicenseGroup[] }>('/api/user/user-tenants');
				setAdminedLicenseGroups(data);
				// setStatus('success');
			} catch (error) {
				dispatch(Actions.handleError(error));
				// setStatus('error');
			}
		};

		getAdminedLicenseGroups();
	}, [dispatch, licenseGroupData.status, refetchAdminedLicenseGroupsCounter]);

	const [menu, setMenu] = useState<HTMLButtonElement | null>(null);

	const menuClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		setMenu(event.currentTarget);
	};

	const menuClose = () => {
		setMenu(null);
	};

	// const hasUnconfigured = adminedLicenseGroups.some(e => !e.name);

	if (!adminedLicenseGroups) {
		return (
			<Button disabled className={clsx('h-64 px-20 starter:text-white')}>
				<LicenseGroupLineIcon className="text-28 mr-6" />
				<div className="flex-col mx-12 items-start">
					<Typography component="span" className="normal-case font-600 flex whitespace-pre-wrap">
						<Skeleton variant="rect" width="6em" />
					</Typography>
				</div>

				<ExpandMoreIcon className={clsx('transition-transform transform', { 'rotate-x-180': !!menu })} />
			</Button>
		);

		// return <></>;
	}

	return (
		<>
			<Button className={clsx('h-64 px-20 starter:text-white')} onClick={menuClick}>
				<LicenseGroupLineIcon className="text-28 mr-6" />
				<div className="flex-col mx-12 items-start">
					<Typography component="span" className="normal-case font-600 flex whitespace-pre-wrap">
						{licenseGroupData.name ? licenseGroupData.name : t('licenseGroup.unconfigured')}
					</Typography>
				</div>

				<ExpandMoreIcon className={clsx('transition-transform transform', { 'rotate-x-180': !!menu })} />
			</Button>

			<Popover
				open={!!menu}
				anchorEl={menu}
				onClose={menuClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center'
				}}
				classes={{
					paper: 'pt-4 pb-10 px-4 starter:text-white starter:bg-theme-secondary-900 rounded-none'
				}}
			>
				{adminedLicenseGroups
					.sort((a, b) => (a.name > b.name ? 1 : -1))
					.sort((a, b) => (a.name === '' ? 1 : b.name === '' ? -1 : 0))
					.map(licenseGroup => {
						// TODO::add unconfigured tenants to list
						// const unconfigured = !licenseGroup.region;
						const unconfigured = false;
						return (
							<MenuItem
								key={licenseGroup.id}
								component="a"
								// href={`https://${licenseGroup.slug}.tenant.${process.env.REACT_APP_DOMAIN_NAME}`}
								href={licenseGroup.domain}
								role="button"
								selected={licenseGroup.id === licenseGroupData.id}
								className={classes.menuItem}
							>
								{/* <ListItemIcon>
							<Avatar className="text-theme-secondary-900 bg-white">
								{licenseGroup.name.replace(/[^\w]/gi, '')[0] ?? licenseGroup.name[0]}
							</Avatar>
						</ListItemIcon> */}
								<ListItemText
									className={clsx({ [classes.unconfiguredListItem]: unconfigured }, 'ml-24 mr-12')}
									primary={
										unconfigured ? (
											t('licenseGroup.unconfigured')
										) : (
											<span className="whitespace-pre-wrap">{licenseGroup.name}</span>
										)
									}
									// secondary={
									// 	unconfigured ? (
									// 		<span className={`font-bold italic ${classes.orderNumber}`}>
									// 			Order Number: {licenseGroup.orderNumber}
									// 		</span>
									// 	) : (
									// 		undefined
									// 	)
									// }
								/>
								<IconButton
									onClick={e => {
										e.preventDefault();
										// FIXME::why is `.then` not working?
										dispatch(Actions.forgetAdminedLicenseGroup(licenseGroup.id) as any).then(() => {
											refetchAdminedLicenseGroups();
										});
									}}
									size="small"
									className={clsx({ invisible: licenseGroup.id === licenseGroupData.id }, 'mr-12')}
								>
									<RemoveFromListIcon className="starter:text-white" />
								</IconButton>
							</MenuItem>
						);
					})}
				<MenuItem className={classes.menuItem} disableRipple>
					<form
						className="flex"
						onSubmit={e => {
							e.preventDefault();
							// let slug = addAdminLicenseGroupValue;
							// if (urlRegex.test(slug)) {
							// 	slug = urlRegex.exec(addAdminLicenseGroupValue)?.[2];
							// }
							const slug = getSlug(addAdminLicenseGroupValue);
							// FIXME::why is `.then` not working?
							dispatch(Actions.addAdminedLicenseGroup(slug) as any).then(() => {
								refetchAdminedLicenseGroups();
								// setAddAdminLicenseGroupValue('');
							});
							setAddAdminLicenseGroupValue('');
						}}
					>
						<ListItem className="ml-6 mr-12">
							<LightTextField
								value={addAdminLicenseGroupValue}
								onChange={e => setAddAdminLicenseGroupValue(e.target.value)}
								variant="outlined"
								placeholder={t('add tenant placeholder')}
							/>
						</ListItem>
						<IconButton
							size="small"
							className="mr-12"
							type="submit"
							disabled={addAdminLicenseGroupValue === ''}
						>
							<AddIcon className="starter:text-white" />
						</IconButton>
					</form>
				</MenuItem>
			</Popover>
		</>
	);
};

export default LicenseGroupsMenu;
