import { Badge, Box, Button, IconButton, Popover, Tooltip, Typography } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import axios from 'app/client';
import { NotificationsIcon } from 'app/components/Icons';
import { useSelector } from 'app/modules/react-redux';
import { getProfile, getSelectedLicenseGroupId } from 'app/store/reducers';
import { Device } from 'app/store/types';
import { TLog, getLogApiUrl } from 'app/utils/helpers';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
	notificationPopup: {
		color: theme.palette.common.white,
		backgroundColor: red[500],
		width: 'calc(100vw - 280px - 20px)',
		padding: '1.2rem 1.2rem 1.2rem 9.6rem',
		'& button.MuiIconButton-root': {
			height: 32,
			width: 32,
			marginRight: 16,
			marginTop: 16
		}
	},
	errorIcon: {
		fontSize: '4.8rem',
		position: 'absolute',
		top: '50%',
		left: '4.8rem',
		transform: 'translate(-50%, -50%)'
	},
	closeButtonWrapper: {
		position: 'absolute',
		top: 0,
		right: 0,
		margin: '0.2rem'
	}
}));

const Notification = () => {
	const { t } = useTranslation();
	const classes = useStyles();
	const profile = useSelector(getProfile);
	const logApiUrl = getLogApiUrl(profile?.awsRegion);

	// const devices = useSelector(getDevices);
	// let errorLogs: any = [];
	const [errorLogs, setErrorLogs] = useState([]);
	let errorLogBackup: any[] = [];

	const getLogs = async () => {
		const backLog = 30;
		const d = new Date(Date.now());
		d.setDate(d.getDate() - backLog);
		const logs = await axios.get(
			`${logApiUrl}/log/${licenseGroupId}/message?limit=500&action=error&type=workflow,instance&from=${d.toISOString()}&to=${new Date().toISOString()}`
		);
		errorLogBackup = logs.data.Items.sort((a: any, b: any) => {
			return a.DateAdded < b.DateAdded ? 1 : -1;
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
		// @ts-ignore
		setErrorLogs(errorLogBackup);
		// return true;
	};

	const updateLogs = async () => {
		if (errorLogBackup.length === 0) {
			getLogs();
		} else {
			// @ts-ignore
			const latestDate = new Date(Math.max(...errorLogBackup.map(x => x.DateAdded)));
			const logs = await axios.get(
				`${logApiUrl}/log/${licenseGroupId}/message?action=error&type=workflow,instance&from=${latestDate.toISOString()}&to=${new Date().toISOString()}`
			);
			errorLogBackup = [
				...logs.data.Items.filter((x: any) => !errorLogBackup.map(y => y.SK).includes(x.SK)),
				...errorLogBackup
			].sort((a: any, b: any) => {
				return a.DateAdded < b.DateAdded ? 1 : -1;
			});
			// eslint-disable-next-line react-hooks/exhaustive-deps
			// @ts-ignore
			setErrorLogs(errorLogBackup);
		}
	};

	const licenseGroupId = useSelector(getSelectedLicenseGroupId);
	useEffect(() => {
		getLogs();
		document.querySelectorAll('#fuse-navbar a').forEach(link => {
			link.addEventListener('click', updateLogs);
		});
		return () => {
			document.querySelectorAll('#fuse-navbar a').forEach(link => {
				link.removeEventListener('click', updateLogs);
			});
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// const errorLogs = devices.filter(device => device.STCApp);

	const previouslyAcknowledgedNotifications = JSON.parse(
		localStorage.getItem('acknowledgedNotifications') ?? '[]'
	) as string[];

	const savedAcknowledgedNotifications = previouslyAcknowledgedNotifications.filter(errId =>
		// errorLogs.some((SK: any) => SK === errId)
		// @ts-ignore
		errorLogs.map(x => x.SK?.toString()).includes(errId?.toString())
	);

	const [acknowledgedNotifications, setAcknowledgedNotifications] = useState<Device['serial'][]>(
		// all of the locally stores devices that are still in the errorLogs list
		savedAcknowledgedNotifications
	);
	const savedAcknowledgedNotificationsDependency = JSON.stringify(savedAcknowledgedNotifications);
	useEffect(() => {
		setAcknowledgedNotifications(savedAcknowledgedNotifications);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [savedAcknowledgedNotificationsDependency]); // get an infinite render if `savedAcknowledgedNotifications` is added

	const unacknowledgedErrorLogsNotifications = errorLogs.filter(
		(device: any) => !acknowledgedNotifications.includes(device.SK)
	);

	const [activeNotification, setActiveNotification] = React.useState(0);

	const handleBack = () => {
		setActiveNotification(prevActiveNotification => prevActiveNotification - 1);
	};

	const handleNext = () => {
		setActiveNotification(prevActiveNotification => prevActiveNotification + 1);
	};

	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const saveAcknowledgedNotifications = () => {
		const newAcknowledgedNotifications = [
			...acknowledgedNotifications,
			// @ts-ignore
			unacknowledgedErrorLogsNotifications[activeNotification].SK
		];
		localStorage.setItem('acknowledgedNotifications', JSON.stringify(newAcknowledgedNotifications));
		setAcknowledgedNotifications(newAcknowledgedNotifications);
	};

	const handleAcknowledge = () => {
		saveAcknowledgedNotifications();
		setActiveNotification(prevActiveNotification =>
			Math.min(prevActiveNotification, unacknowledgedErrorLogsNotifications.length - 1 - 1)
		);
	};

	if (unacknowledgedErrorLogsNotifications.length === 0) {
		return (
			<Tooltip title={t('notification:no new notifications')}>
				<span>
					<IconButton className="w-64 h-64 starter:text-white" disabled>
						<NotificationsIcon style={{ maxWidth: 24 }} />
					</IconButton>
				</span>
			</Tooltip>
		);
	}

	const open = !!anchorEl && unacknowledgedErrorLogsNotifications.length !== 0;

	return (
		<>
			<IconButton className="w-64 h-64 starter:text-white" onClick={handleClick}>
				<Badge color="error" badgeContent={unacknowledgedErrorLogsNotifications.length}>
					<NotificationsIcon style={{ maxWidth: 24 }} />
				</Badge>
			</IconButton>
			<Popover
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left'
				}}
			>
				<div className={classes.notificationPopup}>
					<ErrorIcon className={classes.errorIcon} />
					<div className="mb-8 flex items-center">
						<Typography className="pr-16" variant="h6">
							{/* {t('notification:device not secure')} */}
							{t('workflow:error')}
						</Typography>
						<div
							className={clsx(
								{
									'opacity-0 pointer-events-none': unacknowledgedErrorLogsNotifications.length <= 1
								},
								'flex items-center'
							)}
						>
							<Typography className="pr-16" variant="h6">
								<span className="font-normal">
									{activeNotification + 1}/{unacknowledgedErrorLogsNotifications.length}
								</span>
							</Typography>
							<div className="pr-16">
								<IconButton color="inherit" onClick={handleBack} disabled={activeNotification === 0}>
									<KeyboardArrowLeft />
								</IconButton>
								<IconButton
									color="inherit"
									onClick={handleNext}
									disabled={activeNotification === unacknowledgedErrorLogsNotifications.length - 1}
								>
									<KeyboardArrowRight />
								</IconButton>
							</div>
						</div>
					</div>
					<Box display="flex" justifyContent="space-between">
						{/* @ts-ignore */}
						<Typography style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
							<TLog
								log={{
									// @ts-ignore
									messageKey: unacknowledgedErrorLogsNotifications[activeNotification].MsgKey,
									// @ts-ignore
									info: unacknowledgedErrorLogsNotifications[activeNotification].Info
								}}
							/>
							{/* Workflow Verification was started and failed. */}
						</Typography>
						<Button className="ml-16" color="inherit" onClick={handleAcknowledge}>
							{t('notification:dismiss')}
						</Button>
					</Box>
					<div className={classes.closeButtonWrapper}>
						<IconButton onClick={handleClose} color="inherit">
							<CloseIcon />
						</IconButton>
					</div>
				</div>
			</Popover>
		</>
	);
};

export default Notification;
