import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import { makeStyles } from '@material-ui/core/styles';
import NavbarMobileToggleFab from 'app/fuse-layouts/shared-components/NavbarMobileToggleFab';
import * as Actions from 'app/store/actions';
import clsx from 'clsx';
import React from 'react';
import { useDispatch, useSelector } from 'app/modules/react-redux';
import { getHideNavbar, getUserPreferences } from 'app/store/reducers';
import NavbarLayout1 from './NavbarLayout1';

const navbarWidth = 224;

const useStyles = makeStyles(theme => ({
	wrapper: {
		display: 'flex',
		flexDirection: 'column',
		zIndex: 4,
		// height: 'calc(100vh - 48px)',
		[theme.breakpoints.up('lg')]: {
			width: navbarWidth,
			minWidth: navbarWidth
		}
	},
	wrapperFolded: {
		[theme.breakpoints.up('lg')]: {
			width: 64,
			minWidth: 64
		}
	},
	navbar: {
		display: 'flex',
		overflow: 'hidden',
		flexDirection: 'column',
		flex: '1 1 auto',
		width: navbarWidth,
		minWidth: navbarWidth,
		zIndex: 4,
		transition: theme.transitions.create(['width', 'min-width'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.shorter
		}),
		boxShadow: theme.shadows[3],
		background: `linear-gradient(90deg, #262626 64px, #262626BF 67%, transparent 100%), url('${process.env.PUBLIC_URL}/assets/images/banners/navbar.png'), linear-gradient(#7D61BC, #7D61BC)`,
		backgroundSize: '100%, 100% 100%, 100%',
		// backgroundPosition: 'center, left 64px top 64px, center',
		backgroundRepeat: 'no-repeat',
		position: 'relative',
		top: 64,
		// HACK-ish: prevent `top: 64` from causing page overflow on desktop (with `marginBottom`) and mobile (with `height: calc()`)...
		// ...there's probably a way to rewrite this using `marginTop` instead of `top` on a `position: relative` element
		height: 'calc(100% - 64px)',
		marginBottom: 64
	},
	navbarEnterprise: ({ navbarBackgroundColor }: { navbarBackgroundColor: string }) => ({
		background: 'none',
		backgroundColor: navbarBackgroundColor
	}),
	left: {
		left: 0
	},
	right: {
		right: 0
	},
	folded: {
		position: 'absolute',
		width: 64,
		minWidth: 64,
		top: 64,
		bottom: 0
	},
	foldedAndOpened: {
		width: navbarWidth,
		minWidth: navbarWidth
	},
	navbarContent: {
		flex: '1 1 auto',
		'&:hover button': {
			visibility: 'visible !important'
		}
	},
	foldedAndClosed: {
		'& $navbarContent': {
			'& .logo-icon': {
				width: 32,
				height: 32
			},
			'& .logo-text': {
				opacity: 0
			},
			'& .react-badge': {
				opacity: 0
			},
			'& .list-item-text, & .arrow-icon, & .item-badge': {
				opacity: 0
			},
			'& .list-subheader .list-subheader-text': {
				opacity: 0
			},
			'& .list-subheader:before': {
				content: '""',
				display: 'block',
				position: 'absolute',
				minWidth: 16,
				borderTop: '2px solid',
				opacity: 0.2
			},
			'& .collapse-children': {
				display: 'none'
			},
			'& .user': {
				'& .username, & .email': {
					opacity: 0
				},
				'& .avatar': {
					width: 40,
					height: 40,
					top: 32,
					padding: 0
				}
			},
			'& .list-item.active': {
				marginLeft: 12,
				width: 40,
				padding: 12,
				// borderRadius: 20,
				borderColor: 'transparent',
				backgroundColor: 'transparent',
				'&.square': {
					borderRadius: 0,
					marginLeft: 0,
					paddingLeft: 24,
					width: '100%'
				}
			}
		}
	}
}));

type Props = {
	adminPage: boolean;
};
function NavbarWrapperLayout1({ adminPage }: Props) {
	const dispatch = useDispatch();
	const config = useSelector(({ fuse }) => fuse.settings.current.layout.config);
	const navbar = useSelector(({ fuse }) => fuse.navbar);

	const hideNavbar = useSelector(getHideNavbar);

	const { navbarBackgroundColor } = useSelector(getUserPreferences);

	const classes = useStyles({ navbarBackgroundColor });

	const { folded } = config.navbar;
	const foldedAndClosed = folded && !navbar.foldedOpen;
	const foldedAndOpened = folded && navbar.foldedOpen;

	if (adminPage) {
		return (
			<>
				<div id="fuse-navbar" className={clsx(classes.wrapper, folded && classes.wrapperFolded)}>
					<Hidden mdDown>
						<div
							className={clsx(
								classes.navbar,
								{ [classes.navbarEnterprise]: localStorage.getItem('enterprise') },
								classes[config.navbar.position as 'left' | 'right'],
								folded && classes.folded,
								foldedAndOpened && classes.foldedAndOpened,
								foldedAndClosed && classes.foldedAndClosed,
								'enterprise:mt-24',
								'enterprise:shadow-0'
							)}
							onMouseEnter={() => foldedAndClosed && dispatch(Actions.navbarOpenFolded())}
							onMouseLeave={() => foldedAndOpened && dispatch(Actions.navbarCloseFolded())}
						>
							<NavbarLayout1 className={classes.navbarContent} />
						</div>
					</Hidden>

					<Hidden lgUp>
						<Drawer
							anchor={config.navbar.position}
							variant="temporary"
							open={navbar.mobileOpen}
							classes={{
								paper: clsx(classes.navbar, {
									[classes.navbarEnterprise]: localStorage.getItem('enterprise')
								})
							}}
							onClose={() => dispatch(Actions.navbarCloseMobile())}
							ModalProps={{
								keepMounted: true // Better open performance on mobile.
							}}
						>
							<NavbarLayout1 className={classes.navbarContent} />
						</Drawer>
					</Hidden>
				</div>

				{config.navbar.display && !hideNavbar && !config.toolbar.display && (
					<Hidden lgUp>
						<NavbarMobileToggleFab />
					</Hidden>
				)}
			</>
		);
	}

	// non-admin-side navbar can go here
	return null;
}

export default React.memo(NavbarWrapperLayout1);
