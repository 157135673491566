import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import common from './locales/en/common.json';
import alert from './locales/en/alert.json';
import pageTitle from './locales/en/pageTitle.json';
import log from './locales/en/log.json';

import commonEs from './locales/es/common.json';
import alertEs from './locales/es/alert.json';
import pageTitleEs from './locales/es/pageTitle.json';
import logEs from './locales/es/log.json';

import commonDe from './locales/de/common.json';
import alertDe from './locales/de/alert.json';
import pageTitleDe from './locales/de/pageTitle.json';
import logDe from './locales/de/log.json';

import commonFr from './locales/fr/common.json';
import alertFr from './locales/fr/alert.json';
import pageTitleFr from './locales/fr/pageTitle.json';
import logFr from './locales/fr/log.json';

import commonIt from './locales/it/common.json';
import alertIt from './locales/it/alert.json';
import pageTitleIt from './locales/it/pageTitle.json';
import logIt from './locales/it/log.json';

import commonJa from './locales/ja/common.json';
import alertJa from './locales/ja/alert.json';
import pageTitleJa from './locales/ja/pageTitle.json';
import logJa from './locales/ja/log.json';

const mergeAppName = (json: { [key: string]: string }) => ({
	...json,
	'app:name': localStorage.getItem('enterprise') ? 'Dispatcher Stratus' : 'Dispatcher ScanTrip Cloud'
});

i18next
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		fallbackLng: 'en',
		// referenceLng: 'en',
		// whitelist: ['en'],
		load: 'languageOnly',

		debug: false,
		ns: ['common', 'alert', 'pageTitle', 'log'],
		defaultNS: 'common',
		returnEmptyString: false,
		keySeparator: ';',
		nsSeparator: '::',
		interpolation: {
			escapeValue: false
		},
		resources: {
			en: {
				common: mergeAppName(common),
				alert,
				pageTitle,
				log
			},
			es: {
				common: mergeAppName(commonEs),
				alert: alertEs,
				pageTitle: pageTitleEs,
				log: logEs
			},
			de: {
				common: mergeAppName(commonDe),
				alert: alertDe,
				pageTitle: pageTitleDe,
				log: logDe
			},
			fr: {
				common: mergeAppName(commonFr),
				alert: alertFr,
				pageTitle: pageTitleFr,
				log: logFr
			},
			it: {
				common: mergeAppName(commonIt),
				alert: alertIt,
				pageTitle: pageTitleIt,
				log: logIt
			},
			ja: {
				common: mergeAppName(commonJa),
				alert: alertJa,
				pageTitle: pageTitleJa,
				log: logJa
			}
		}
	});

// @ts-ignore // is this supposed to be `getResource`? I think it's wrong but I don't wanna change it now...
i18next.getResources = (lang, namespace) =>
	!i18next.hasResourceBundle(lang, namespace) && i18next.reloadResources(lang, namespace);

export default i18next;
