import MockAdapter from 'axios-mock-adapter';
import { AxiosInstance } from 'axios';
// eslint-disable-next-line import/no-cycle
import { LicenseGroupData, PublicId } from 'app/store/types';
// eslint-disable-next-line import/no-cycle
import { getFormsUrl, getLogApiUrl, getMetadataUrl, getWfxUrl } from 'app/utils/helpers';
import _ from '@lodash';
import qs from 'qs';

// toggle on or off mock data
const urlParams = new URLSearchParams(window.location.search);

if (urlParams.has('noMock')) {
	localStorage.removeItem('mock');
	localStorage.removeItem('mockUser');
	localStorage.removeItem('mockExpired');
	localStorage.removeItem('mockPlan');
	localStorage.removeItem('mockOrderType');
	localStorage.removeItem('mockRegion');
	localStorage.removeItem('mockLogs');
	localStorage.removeItem('mockForms');
	localStorage.removeItem('mockWorkflows');
	localStorage.removeItem('noComingSoonWrapper');
	localStorage.removeItem('enableCheckout');
	localStorage.removeItem('mockNoRegion');
}

if (urlParams.has('mock')) {
	localStorage.setItem('mock', 'true');
}
if (urlParams.has('mockUser')) {
	localStorage.setItem('mockUser', 'true');
}
if (urlParams.has('mockExpired')) {
	localStorage.setItem('mockExpired', urlParams.get('mockExpired') || `${+new Date('2020-12-12T23:59:59.000Z')}`);
}
if (Object.values(PublicId).includes(urlParams.get('mockPlan') as PublicId)) {
	localStorage.setItem('mockPlan', urlParams.get('mockPlan') as PublicId);
}
if (typeof urlParams.get('mockOrderType') === 'string') {
	localStorage.setItem('mockOrderType', urlParams.get('mockOrderType') as LicenseGroupData['orderType']);
}
if (typeof urlParams.get('mockRegion') === 'string') {
	localStorage.setItem('mockRegion', urlParams.get('mockRegion') as string);
}
if (urlParams.has('mockLogs')) {
	localStorage.setItem('mockLogs', 'true');
}
if (urlParams.has('mockForms')) {
	localStorage.setItem('mockForms', 'true');
}
if (urlParams.has('mockWorkflows')) {
	localStorage.setItem('mockWorkflows', 'true');
}
if (urlParams.has('noComingSoonWrapper')) {
	localStorage.setItem('noComingSoonWrapper', 'true');
}
if (urlParams.has('enableCheckout')) {
	localStorage.setItem('enableCheckout', 'true');
}
if (urlParams.has('mockNoRegion')) {
	localStorage.setItem('mockNoRegion', 'true');
}

// HACK-ish::add option for Product to edit site content easily
if (urlParams.has('designMode')) {
	document.designMode = 'on';
	document.body.setAttribute('spellcheck', 'false');
}

// CHANGEME::TEMP::delete after QA testing
if (typeof urlParams.get('mockTier') === 'string') {
	localStorage.setItem('enterprise', urlParams.get('mockTier') === 'enterprise' ? 'true' : '');
}

const mocking = (instance: AxiosInstance) => {
	const mock = new MockAdapter(instance, { delayResponse: 600, onNoMatch: 'passthrough' });
	const mockExpired = localStorage.getItem('mockExpired');
	const selectedLicenseGroup = {
		id: 'changeMeId',
		// id: '11111111-1111-1111-1111-111111111111',
		slug: 'sec',
		name: 'ABC Company',
		timezone: 'America/New_York',
		catalogPublicId: localStorage.getItem('mockPlan') ?? PublicId.TermOneYear,
		orderType: localStorage.getItem('mockOrderType') ?? 'PRODUCTION',
		expirationDate: mockExpired ? +mockExpired : +new Date('2024-01-27T23:59:59.000Z'),
		isCoolOffPeriod: false,
		orderNumber: '1000596878880001',
		purchaseCode: '267791324',
		adminData: [
			{ email: 'sec4@kmbs.konicaminolta.us', status: 'ACCEPTED' },
			{ email: 'mmetnetsky@kmbs.konicaminolta.us', status: 'PENDING' }
		],
		region: localStorage.getItem('mockNoRegion') ? false : 'NA',
		quantity: 7,
		capacity: 11,
		managerEmails: [],
		isAdmin: true,
		isManager: false,
		authMethod: 'mp',
		dateAdded: 1614320869622
		// licenseUsage: {
		// 	dateUpdated: 1675058102193,
		// 	fileCount: 51,
		// 	fileSizeTotal: 10000
		// }
	};
	const user = {
		id: 'mock-user-id',
		firstName: 'Russell',
		lastName: 'Fellows',
		email: 'rfellows@kmbs.konicaminolta.us',
		mpId: 'mock-user-mpId-1',
		country: 'US',
		awsRegion: undefined,
		tenant: selectedLicenseGroup.id,
		tokens: {
			accessToken:
				'eyJraWQiOiJMc0ZzOG5Xbjd5dlh0RXhnWjVpMkEyS0I4YmtLY0V3b0poQXJmVTVYVWkwPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiI2ZjQzNmU0ZS01NjRhLTQyMjctYTVhMS1mZWRhY2NjOGYwNzUiLCJjb2duaXRvOmdyb3VwcyI6WyJ1cy1lYXN0LTFfTEE0b1Bkbm1FX0tvbmljYU1pbm9sdGFNYXJrZXRQbGFjZSJdLCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAudXMtZWFzdC0xLmFtYXpvbmF3cy5jb21cL3VzLWVhc3QtMV9MQTRvUGRubUUiLCJ2ZXJzaW9uIjoyLCJjbGllbnRfaWQiOiI2Y3Bic3Y3bGs3NGlvbHNkZzRiamU5NG82dSIsIm9yaWdpbl9qdGkiOiI0MzQwMGI3ZS01OGJkLTRhYTItOTNjYi0zNDFiNzIyNDgzM2EiLCJ0b2tlbl91c2UiOiJhY2Nlc3MiLCJzY29wZSI6ImF3cy5jb2duaXRvLnNpZ25pbi51c2VyLmFkbWluIG9wZW5pZCBwcm9maWxlIGVtYWlsIiwiYXV0aF90aW1lIjoxNjk4OTM5NjA3LCJleHAiOjE2OTkyODY5MDksImlhdCI6MTY5OTI4MzMwOSwianRpIjoiNWMzNDQyYWYtMTg4My00YmFhLTg1MWItMzUwOTg4OWQ5OGU1IiwidXNlcm5hbWUiOiJLb25pY2FNaW5vbHRhTWFya2V0UGxhY2VfZjRjOWIxYzNkYjhmMDkzZmQ3MmY0ZjY3ZmViZjFmOTUxYzI1ZDBjMiJ9.OGDZg9KsWW7plCdgBMA6Kh-ccI5iumI3jpMaqiWy1-doIiOgOp6B2PLogI12bDJ-ohRTn2YmMsXUAbCRNyq5DCoHMSB6OhWO_AJFw_NG-tJNlyf2nuhba6PjViMqRM9RWKbCB0uTAnr21pin1_SNuoF0z5xBLrcsnGXILMDUvSs3I3O42wWFhCfrIvWSIP5aRWggmKWbULyV5r1Q5TTGvhdTYTZtvWfByorI-viwpeJYRdnNXRc7oYv__XaLyTlhqeoSY13lTpcjZjP2BttxG82DjtXK_QrHj0wpbXpXeXPZ7PXPUiBufE3xCwl-C_ssMpXv-hHjbNUvo7WFF2wJMw',
			idToken: 'mock-id-token',
			expireTime: (Date.now() + 1814400000) / 1000 // set far in the future (but do not exceed `2147483647` - see https://nodejs.org/api/timers.html#settimeoutcallback-delay-args)
		}
	};
	const tenantLicenseGroups = [
		{
			id: '111',
			name: 'University',
			parentId: 'cccc',
			parentName: 'Next one',
			managerEmails: []
		},
		{
			id: 'aaaa',
			name: 'Government Tenants',
			parentId: 'main',
			parentName: 'Main',
			managerEmails: []
		},
		{
			id: 'avvv',
			name: 'Federal',
			parentId: 'aaaa',
			parentName: 'TEST GROUP',
			managerEmails: []
		},
		{
			id: 'cccc',
			name: 'Education Tenants',
			parentId: 'main',
			parentName: 'Main',
			managerEmails: []
		},
		{
			id: 'main',
			name: 'Main',
			managerEmails: [
				{
					firstName: 'z',
					lastName: 'A',
					email: 'a@kmbs.konicaminolta.us',
					status: 'ACCEPTED'
				},
				{
					firstName: 'm',
					lastName: 'G',
					email: 'jalbanese@kmbs.konicaminolta.us',
					status: 'PENDING'
				},
				{
					firstName: 'Russell',
					lastName: 'Fellows',
					email: 'rfellows@kmbs.konicaminolta.us',
					status: 'ACCEPTED'
				}
			]
		}
	];
	const licenseGroups = [
		// admin
		{
			id: 'five',
			slug: 'five-slug',
			name: 'Gravitas Group',
			catalogPublicId: PublicId.EnterpriseMonthly,
			orderType: 'PRODUCTION',
			expirationDate: +new Date('2050-12-12T23:59:59.000Z'),
			orderNumber: '1000596878880002',
			purchaseCode: '267791325',
			adminData: [{ email: 'sec2@kmbs.konicaminolta.us', status: 'PENDING' }],
			region: 'NA',
			quantity: 19,
			capacity: 50,
			isAdmin: true,
			isManager: false,
			authMethod: 'mp',
			dateAdded: 1614320869623
		},
		// admin
		{
			id: 'six',
			slug: 'six-slug',
			name: 'Wallhaven Associates',
			catalogPublicId: PublicId.EnterpriseMonthly,
			orderType: 'PRODUCTION',
			expirationDate: +new Date('2050-12-12T23:59:59.000Z'),
			orderNumber: '1000596878890002',
			purchaseCode: '267791425',
			adminData: [],
			region: 'NA',
			quantity: 19,
			capacity: 50,
			isAdmin: true,
			isManager: false,
			authMethod: 'mp',
			dateAdded: 1614320869633
		},
		{
			id: 'six-two',
			slug: 'six-two-slug',
			name: '',
			catalogPublicId: PublicId.EnterpriseMonthly,
			orderType: 'PRODUCTION',
			expirationDate: +new Date('2050-12-12T23:59:59.000Z'),
			orderNumber: '1000596878890065',
			purchaseCode: '267791425',
			adminData: [
				{ email: 'sec3@kmbs.konicaminolta.us', status: 'PENDING' },
				{ email: 'mmetnetsky@kmbs.konicaminolta.us', status: 'PENDING' }
			],
			region: '',
			quantity: 19,
			capacity: 50,
			isAdmin: true,
			isManager: false,
			authMethod: 'mp',
			dateAdded: 1614320869833
		},
		// manager
		// {
		// 	id: 'seven',
		// 	slug: 'seven-slug',
		// 	name: 'Milstone Financial',
		// 	catalogPublicId: PublicId.EnterpriseMonthly,
		// 	orderType: 'PRODUCTION',
		// 	expirationDate: undefined,
		// 	orderNumber: '1000596878880003',
		// 	purchaseCode: '267791326',
		// 	adminData: [
		// 		{ email: 'sec4@kmbs.konicaminolta.us', status: 'ACCEPTED' },
		// 		{ email: 'mmetnetsky@kmbs.konicaminolta.us', status: 'ACCEPTED' }
		// 	],
		// 	region: 'AWS-US',
		// 	status: 'CONFIGURED',
		// 	quantity: 14,
		// 	capacity: 25,
		// 	isAdmin: false,
		// 	isManager: true,
		// 	authMethod: 'local',
		// 	dateAdded: 1614320839624
		// },
		// {
		// 	id: 'eight',
		// 	slug: 'eight-slug',
		// 	name: 'Horizon Digital',
		// 	catalogPublicId: PublicId.EnterpriseMonthly,
		// 	orderType: 'PRODUCTION',
		// 	expirationDate: undefined,
		// 	orderNumber: '1000596878880007',
		// 	purchaseCode: '267791497',
		// 	adminData: [
		// 		{ email: 'sec5@kmbs.konicaminolta.us', status: 'ACCEPTED' },
		// 		{ email: 'mmetnetsky@kmbs.konicaminolta.us', status: 'ACCEPTED' }
		// 	],
		// 	region: 'AWS-US',
		// 	status: 'UNCONFIGURED',
		// 	quantity: 6,
		// 	capacity: 11,
		// 	isAdmin: false,
		// 	isManager: true,
		// 	authMethod: 'mp',
		// 	dateAdded: 1475320829626
		// },
		// {
		// 	id: 'twelve',
		// 	slug: 'twelve-slug',
		// 	name: 'Sabre Consulting Group',
		// 	catalogPublicId: PublicId.EnterpriseMonthly,
		// 	orderType: 'PRODUCTION',
		// 	expirationDate: undefined,
		// 	orderNumber: '1000596878880008',
		// 	purchaseCode: '267791497',
		// 	adminData: [
		// 		{ email: 'sec@kmbs.konicaminolta.us', status: 'PENDING' },
		// 		{ email: 'mmetnetsky@kmbs.konicaminolta.us', status: 'PENDING' },
		// 		{ email: 'msmith@kmbs.konicaminolta.us', status: 'PENDING' }
		// 	],
		// 	region: 'us-east-1',
		// 	status: 'CONFIGURED',
		// 	quantity: 6,
		// 	capacity: 11,
		// 	isAdmin: false,
		// 	isManager: true,
		// 	authMethod: 'mp',
		// 	dateAdded: 1614320869628
		// },
		{
			id: '11111111-2222-2222-1111-111111111111',
			quantity: 0,
			capacity: 10,
			catalogPublicId: '3348C5FD-7123-4332-ABD0-4D5C644CE3A7',
			orderType: 'PRODUCTION',
			orderNumber: 'MP00012904',
			status: 'UNCONFIGURED',
			purchaseCode: '3718-1b76-e138-bf1e',
			adminData: [
				{
					email: 'aabbott@kmbs.konicaminolta.us',
					status: 'PENDING'
				}
			],
			managerEmails: [],
			isAdmin: false, // at least one mock tenant needs this property, else crash
			dateAdded: 1671139415108,
			isManager: true
		},
		{
			id: '4505281a-2444-547d-a25a-31c618e570fd',
			quantity: 0,
			capacity: 10,
			catalogPublicId: '3348C5FD-7123-4332-ABD0-4D5C644CE3A7',
			orderType: 'PRODUCTION',
			orderNumber: 'MP00013026',
			status: 'UNCONFIGURED',
			purchaseCode: '34fe-4157-2c6b-be0d',
			managerEmails: [],
			adminData: [],
			dateAdded: 1675438138272,
			isManager: true,
			isAdmin: false
		},
		{
			id: '6745859e-8f21-525e-8c00-40b5473edc78',
			quantity: 0,
			capacity: 10,
			catalogPublicId: '3348C5FD-7123-4332-ABD0-4D5C644CE3A7',
			orderType: 'PRODUCTION',
			orderNumber: 'MP00013061',
			status: 'UNCONFIGURED',
			purchaseCode: '6528-3c49-aba6-bae9',
			managerEmails: [],
			adminData: [],
			dateAdded: 1675691857902,
			isManager: true,
			isAdmin: false
		},
		{
			id: '7aea885e-5d71-5a55-b2b1-4ea0712640c3',
			quantity: 0,
			capacity: 10,
			catalogPublicId: '3348C5FD-7123-4332-ABD0-4D5C644CE3A7',
			orderType: 'PRODUCTION',
			orderNumber: 'MP00013062',
			status: 'PENDING',
			purchaseCode: 'e0af-53d6-b194-8099',
			managerEmails: [],
			adminData: [],
			dateAdded: 1675691848640,
			isManager: true,
			isAdmin: false
		},
		{
			id: '813e7464-6d39-56b9-852e-0d7bc7c70f3c',
			quantity: 0,
			capacity: 10,
			catalogPublicId: '3348C5FD-7123-4332-ABD0-4D5C644CE3A7',
			orderType: 'PRODUCTION',
			orderNumber: 'MP00013067',
			status: 'UNCONFIGURED',
			purchaseCode: '125d-81bf-ee43-d80b',
			managerEmails: [],
			adminData: [],
			dateAdded: 1675713684969,
			isManager: true,
			isAdmin: false
		},
		{
			id: '88d695c6-8896-599c-bfbd-f06764c61777',
			slug: 'test',
			quantity: 0,
			capacity: 10,
			catalogPublicId: '3348C5FD-7123-4332-ABD0-4D5C644CE3A7',
			orderType: 'PRODUCTION',
			orderNumber: 'MP00013065',
			status: 'UNCONFIGURED',
			purchaseCode: 'e9ad-09b9-b471-3f3b',
			region: 'us-east-1',
			managerEmails: [],
			adminData: [
				{
					email: 'vdogames@gmail.com',
					status: 'PENDING'
				}
			],
			dateAdded: 1675691821770,
			isManager: true,
			isAdmin: false
		},
		{
			id: '8ddc5e14-644e-5903-bd79-be31ac1b6bc5',
			quantity: 0,
			capacity: 10,
			catalogPublicId: '3348C5FD-7123-4332-ABD0-4D5C644CE3A7',
			orderType: 'PRODUCTION',
			orderNumber: 'MP00013012',
			status: 'UNCONFIGURED',
			purchaseCode: '287e-1c5b-c9c2-94d7',
			managerEmails: [],
			adminData: [
				{
					email: 'pat.white@kmbs.konicaminolta.com',
					status: 'PENDING'
				}
			],
			dateAdded: 1675265652769,
			isManager: true,
			isAdmin: false
		},

		{
			id: 'e8680b51-fd2b-5ab0-ba86-f5fcaf8b3ea7',
			quantity: 0,
			capacity: 10,
			catalogPublicId: '3348C5FD-7123-4332-ABD0-4D5C644CE3A7',
			orderType: 'PRODUCTION',
			orderNumber: 'MP00013066',
			status: 'UNCONFIGURED',
			purchaseCode: '8356-5e16-e9b4-217c',
			managerEmails: [],
			adminData: [
				{
					email: 'pat.white@kmbs.konicaminolta.us',
					status: 'PENDING'
				}
			],
			dateAdded: 1675713692022,
			isManager: true,
			isAdmin: false
		},
		{
			id: 'f5922df9-cbc1-5383-a86a-90ae4a0fc92f',
			quantity: 0,
			capacity: 10,
			catalogPublicId: '3348C5FD-7123-4332-ABD0-4D5C644CE3A7',
			orderType: 'PRODUCTION',
			orderNumber: 'MP00013064',
			status: 'UNCONFIGURED',
			purchaseCode: '13a1-efc6-a28e-f90f',
			managerEmails: [],
			adminData: [],
			dateAdded: 1675691829486,
			isManager: true,
			isAdmin: false
		},
		{
			id: '11111111-1111-1111-1111-111111111111',
			slug: 'jack',
			name: 'Bergen Community College',
			quantity: 5,
			capacity: 6,
			catalogPublicId: '3348C5FD-7123-4332-ABD0-4D5C644CE3A7',
			parent: 'cccc',
			orderType: 'PRODUCTION',
			orderNumber: 'SomeOrder200',
			status: 'CONFIGURED',
			purchaseCode: 'SomePurchase200',
			expirationDate: 1780876799000,
			region: 'us-east-1',
			managerEmails: [{ email: 'qa@kmbs.konicaminolta.com', status: 'ACCEPTED' }],
			adminData: [
				{
					email: 'qa@kmbs.konicaminolta.com',
					status: 'ACCEPTED'
				},
				{
					email: 'frank@ape-escape.net',
					status: 'PENDING'
				}
			],
			dateAdded: 1647107307031,
			isManager: true,
			isAdmin: false
		},
		{
			id: '11111111-1111-1111-1111-1212121212',
			slug: 'jack',
			name: 'Rutgers',
			quantity: 5,
			capacity: 6,
			catalogPublicId: '3348C5FD-7123-4332-ABD0-4D5C644CE3A7',
			parent: '111',
			orderType: 'PRODUCTION',
			orderNumber: 'SomeOrder200',
			status: 'CONFIGURED',
			purchaseCode: 'SomePurchase200',
			expirationDate: 1780876799000,
			region: 'us-east-1',
			managerEmails: [{ email: 'qa@kmbs.konicaminolta.com', status: 'ACCEPTED' }],
			adminData: [
				{
					email: 'qa@kmbs.konicaminolta.com',
					status: 'ACCEPTED'
				},
				{
					email: 'frank@ape-escape.net',
					status: 'PENDING'
				}
			],
			dateAdded: 1647107307031,
			isManager: true,
			isAdmin: false
		},
		{
			id: 'd254bae9-d5eb-5719-b86a-c8e72a807402',
			slug: 'vivivivi',
			name: 'Ridgewood Public Schools',
			parent: 'cccc',
			quantity: 0,
			capacity: 10,
			catalogPublicId: '3348C5FD-7123-4332-ABD0-4D5C644CE3A7',
			orderType: 'PRODUCTION',
			orderNumber: 'MP00013063',
			status: 'CONFIGURED',
			expirationDate: 1780876799000,
			purchaseCode: 'f22e-b506-0f0f-db72',
			region: 'us-east-1',
			managerEmails: [{ email: 'qa@kmbs.konicaminolta.com', status: 'ACCEPTED' }],
			managerEvents: {
				'30days': true
			},
			adminData: [
				{
					email: 'vdogames@gmail.com',
					status: 'ACCEPTED'
				}
			],
			dateAdded: 1686172692397,
			isManager: true,
			isAdmin: false
		},
		{
			id: '1ea16018-ee08-5eb3-ba96-a2baa04eeb8d',
			owner: 'f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
			name: 'William Paterson',
			region: 'us-east-1',
			status: 'CONFIGURED',
			dateAdded: 1680285802159,
			slug: 'sktenant11',
			parent: '111',
			purchaseCode: 'f5e5-c055-0878-f86f',
			orderNumber: 'MP00013303',
			materialNumber: 'DPSAAA01',
			catalogUid: 'dps-stc-subm',
			catalogPublicId: '3348c5fd-7123-4332-abd0-4d5c644ce3a7',
			licenseName: 'Dispatcher ScanTrip Cloud',
			licenseUsage: {},
			quantity: 0,
			capacity: 10,
			orderType: 'PRODUCTION',
			isExpired: false,
			managerEmails: [
				{
					email: 'qa@kmbs.konicaminolta.us',
					status: 'ACCEPTED'
				}
			],
			isManager: true,
			adminData: [
				{
					email: 'qa@kmbs.konicaminolta.us',
					status: 'ACCEPTED'
				}
			]
		},
		{
			id: '7def2712-dcc1-5ad8-be99-28e56af8eae3',
			owner: 'f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
			name: 'Montclair State',
			parent: '111',
			region: 'us-east-1',
			status: 'CONFIGURED',
			dateAdded: 1680790426016,
			slug: 'domainalreadytakenlg',
			purchaseCode: '7bba-96f5-7085-5880',
			orderNumber: 'MP00013320',
			materialNumber: 'DPSAAA01',
			catalogUid: 'dps-stc-subm',
			catalogPublicId: '3348c5fd-7123-4332-abd0-4d5c644ce3a7',
			licenseName: 'Dispatcher ScanTrip Cloud',
			licenseUsage: {},
			quantity: 0,
			capacity: 10,
			orderType: 'PRODUCTION',
			isExpired: false,
			isManager: true,
			managerEmails: [
				{
					email: 'qa@kmbs.konicaminolta.us',
					status: 'ACCEPTED'
				}
			],
			adminData: [
				{
					email: 'qa@kmbs.konicaminolta.us',
					status: 'ACCEPTED'
				}
			]
		},
		{
			id: '9f0303a4-ede5-5060-914e-6f5523da60cd',
			owner: 'f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
			name: 'Princeton',
			region: 'us-east-1',
			parent: '111',
			status: 'CONFIGURED',
			dateAdded: 1681849276370,
			slug: 'sgalvisadmin',
			purchaseCode: 'e8bb-72c0-97e8-0174',
			orderNumber: 'MP00013372',
			materialNumber: 'DPSAAA01',
			catalogUid: 'dps-stc-subm',
			catalogPublicId: '3348C5FD-7123-4332-ABD0-4D5C644CE3A7',
			licenseName: 'Enterprise',
			licenseUsage: {},
			quantity: 0,
			capacity: 11,
			orderType: 'PRODUCTION',
			isExpired: false,
			isManager: true,
			managerEmails: [
				{
					email: 'qa@kmbs.konicaminolta.us',
					status: 'ACCEPTED'
				}
			],
			adminData: [
				{
					email: 'sgalvis@kmbs.konicaminolta.us',
					status: 'ACCEPTED'
				}
			]
		},
		// ===================================================================

		// {
		// 	id: 'forty-one',
		// 	slug: 'forty-one-slug',
		// 	name: 'Cosgrove Medical',
		// 	catalogPublicId: PublicId.EnterpriseMonthly,
		// 	orderType: 'PRODUCTION',
		// 	expirationDate: undefined,
		// 	orderNumber: '1000596878880008',
		// 	purchaseCode: '267791497',
		// 	adminData: [],
		// 	region: 'AWS-EUR',
		// 	quantity: 6,
		// 	capacity: 11,
		// 	isAdmin: false,
		// 	isManager: true,
		// authMethod: 'mp',
		// 	dateAdded: 1614110769628
		// },

		// unconfigured tenant (i.e. no `region` [also no `name` - though that's not a rule in the logic])
		// {
		// 	id: 'nine',
		// 	slug: undefined,
		// 	name: undefined,
		// 	catalogPublicId: PublicId.EnterpriseMonthly,
		// 	orderType: 'PRODUCTION',
		// 	expirationDate: +new Date('2050-12-12T23:59:59.000Z'),
		// 	orderNumber: '1000596878880004',
		// 	purchaseCode: '267791327',
		// 	adminData: [],
		// 	region: undefined,
		// 	quantity: 119,
		// 	capacity: 25,
		// 	isAdmin: false,
		// 	isManager: true,
		// authMethod: 'mp',
		// 	dateAdded: 1614020869623
		// },
		// {
		// 	id: 'ten',
		// 	slug: undefined,
		// 	name: undefined,
		// 	catalogPublicId: PublicId.EnterpriseMonthly,
		// 	orderType: 'PRODUCTION',
		// 	expirationDate: +new Date('2050-12-12T23:59:59.000Z'),
		// 	orderNumber: '1000596878880005',
		// 	purchaseCode: '267791329',
		// 	adminData: [],
		// 	region: undefined,
		// 	quantity: 11,
		// 	capacity: 24,
		// 	isAdmin: false,
		// 	isManager: true,
		// authMethod: 'mp',
		// 	dateAdded: 1614320859627
		// },
		// {
		// 	id: 'thirteen',
		// 	slug: undefined,
		// 	name: undefined,
		// 	catalogPublicId: PublicId.EnterpriseMonthly,
		// 	orderType: 'PRODUCTION',
		// 	expirationDate: +new Date('2050-12-12T23:59:59.000Z'),
		// 	orderNumber: '1000596878880013',
		// 	purchaseCode: '267791329',
		// 	adminData: [],
		// 	region: undefined,
		// 	quantity: 11,
		// 	capacity: 24,
		// 	isAdmin: false,
		// 	isManager: true,
		// authMethod: 'mp',
		// 	dateAdded: 1398320849627
		// },
		// {
		// 	id: 'fourteen',
		// 	slug: undefined,
		// 	name: undefined,
		// 	catalogPublicId: PublicId.EnterpriseMonthly,
		// 	orderType: 'PRODUCTION',
		// 	expirationDate: +new Date('2050-12-12T23:59:59.000Z'),
		// 	orderNumber: '1000596878880014',
		// 	purchaseCode: '267791329',
		// 	adminData: [],
		// 	region: undefined,
		// 	quantity: 11,
		// 	capacity: 24,
		// 	isAdmin: false,
		// 	isManager: true,
		// authMethod: 'mp',
		// 	dateAdded: 1586320869627
		// },

		// {
		// 	id: 'fifteen',
		// 	slug: undefined,
		// 	name: undefined,
		// 	catalogPublicId: PublicId.EnterpriseMonthly,
		// 	orderType: 'PRODUCTION',
		// 	expirationDate: +new Date('2050-12-12T23:59:59.000Z'),
		// 	orderNumber: '1000596878880015',
		// 	purchaseCode: '267791329',
		// 	adminData: [],
		// 	region: undefined,
		// 	quantity: 11,
		// 	capacity: 24,
		// 	isAdmin: false,
		// 	isManager: true,
		// authMethod: 'mp',
		// 	dateAdded: 1614020839627
		// },
		// {
		// 	id: 'sixteen',
		// 	slug: undefined,
		// 	name: undefined,
		// 	catalogPublicId: PublicId.EnterpriseMonthly,
		// 	orderType: 'PRODUCTION',
		// 	expirationDate: +new Date('2050-12-12T23:59:59.000Z'),
		// 	orderNumber: '1000596878880016',
		// 	purchaseCode: '267791329',
		// 	adminData: [],
		// 	region: undefined,
		// 	quantity: 11,
		// 	capacity: 24,
		// 	isAdmin: false,
		// 	isManager: true,
		// authMethod: 'mp',
		// 	dateAdded: 1514200869627
		// },
		// {
		// 	id: 'seventeen',
		// 	slug: undefined,
		// 	name: undefined,
		// 	catalogPublicId: PublicId.EnterpriseMonthly,
		// 	orderType: 'PRODUCTION',
		// 	expirationDate: +new Date('2050-12-12T23:59:59.000Z'),
		// 	orderNumber: '1000596878880017',
		// 	purchaseCode: '267791329',
		// 	adminData: [],
		// 	region: undefined,
		// 	quantity: 11,
		// 	capacity: 24,
		// 	isAdmin: false,
		// 	isManager: true,
		// authMethod: 'mp',
		// 	dateAdded: 1601320869627
		// },
		// {
		// 	id: 'eightteen',
		// 	slug: undefined,
		// 	name: undefined,
		// 	catalogPublicId: PublicId.EnterpriseMonthly,
		// 	orderType: 'PRODUCTION',
		// 	expirationDate: +new Date('2050-12-12T23:59:59.000Z'),
		// 	orderNumber: '1000596878880018',
		// 	purchaseCode: '267791329',
		// 	adminData: [],
		// 	region: undefined,
		// 	quantity: 11,
		// 	capacity: 24,
		// 	isAdmin: false,
		// 	isManager: true,
		// authMethod: 'mp',
		// 	dateAdded: 1614320869627
		// },
		// {
		// 	id: 'nineteen',
		// 	slug: undefined,
		// 	name: undefined,
		// 	catalogPublicId: PublicId.EnterpriseMonthly,
		// 	orderType: 'PRODUCTION',
		// 	expirationDate: +new Date('2050-12-12T23:59:59.000Z'),
		// 	orderNumber: '1000596878880019',
		// 	purchaseCode: '267791329',
		// 	adminData: [],
		// 	region: undefined,
		// 	quantity: 11,
		// 	capacity: 24,
		// 	isAdmin: false,
		// 	isManager: true,
		// authMethod: 'mp',
		// 	dateAdded: 1614320869627
		// },
		// {
		// 	id: 'twenty',
		// 	slug: undefined,
		// 	name: undefined,
		// 	catalogPublicId: PublicId.EnterpriseMonthly,
		// 	orderType: 'PRODUCTION',
		// 	expirationDate: +new Date('2050-12-12T23:59:59.000Z'),
		// 	orderNumber: '1000596878880020',
		// 	purchaseCode: '267791329',
		// 	adminData: [],
		// 	region: undefined,
		// 	quantity: 11,
		// 	capacity: 24,
		// 	isAdmin: false,
		// 	isManager: true,
		// authMethod: 'mp',
		// 	dateAdded: 1614320869627
		// },
		// {
		// 	id: 'twenty-one',
		// 	slug: undefined,
		// 	name: undefined,
		// 	catalogPublicId: PublicId.EnterpriseMonthly,
		// 	orderType: 'PRODUCTION',
		// 	expirationDate: +new Date('2050-12-12T23:59:59.000Z'),
		// 	orderNumber: '1000596878880021',
		// 	purchaseCode: '267791329',
		// 	adminData: [],
		// 	region: undefined,
		// 	quantity: 11,
		// 	capacity: 24,
		// 	isAdmin: false,
		// 	isManager: true,
		// authMethod: 'mp',
		// 	dateAdded: 1614320869627
		// },
		// {
		// 	id: 'twenty-two',
		// 	slug: undefined,
		// 	name: undefined,
		// 	catalogPublicId: PublicId.EnterpriseMonthly,
		// 	orderType: 'PRODUCTION',
		// 	expirationDate: +new Date('2050-12-12T23:59:59.000Z'),
		// 	orderNumber: '1000596878880022',
		// 	purchaseCode: '267791329',
		// 	adminData: [],
		// 	region: undefined,
		// 	quantity: 11,
		// 	capacity: 24,
		// 	isAdmin: false,
		// 	isManager: true,
		// authMethod: 'mp',
		// 	dateAdded: 1614320869627
		// },
		// ========================================================
		// ========================================================
		selectedLicenseGroup
	];
	// DEV NOTE::admined and managed licenses are returned from two different lists - and the `isAdmin` and `isManager` property don't actually exist
	const managedLicenseGroups = licenseGroups
		.filter(({ isManager }) => isManager)
		.map(licenseGroup => _.omit(licenseGroup, ['isAdmin', 'isManager']));
	// const adminedLicenseGroups = licenseGroups
	// 	.filter(({ isAdmin }) => isAdmin)
	// 	.map(licenseGroup => _.omit(licenseGroup, ['isAdmin', 'isManager']));

	const adminedLicenseGroups = [
		{
			id: selectedLicenseGroup.id,
			name: selectedLicenseGroup.name,
			domain: `https://${selectedLicenseGroup.slug}.tenant.${process.env.REACT_APP_DOMAIN_NAME}`
		},
		{
			id: licenseGroups[1].id,
			name: licenseGroups[1].name,
			domain: `https://${licenseGroups[1].slug}.tenant.${process.env.REACT_APP_DOMAIN_NAME}`
		}
	];

	const users = [
		{
			id: user.id,
			userName: user.id,
			firstName: user.firstName,
			lastName: user.lastName,
			email: user.email,
			mpId: user.mpId,
			dateAdded: 1614202447928,
			// permissions: {
			// 	device_management: true,
			// 	user_management: true,
			// 	license_management: true
			// }
			roles: ['tenant-admin', 'role-1', 'role-2'],
			status: 'ACCEPTED',
			groupList: ['userGroup-1', '00000000-2222-2222-2222-000000000000']
		},
		{
			id: 'mock-user-id-3',
			userName: 'mock-user-id-3',
			firstName: 'John',
			lastName: 'Smith',
			email: 'jsmith@example.com',
			mpId: 'mock-user-mpId-2',
			dateAdded: 1616202447928,
			// permissions: {
			// 	device_management: true,
			// 	user_management: true,
			// 	license_management: true
			// }
			roles: ['tenant-admin'],
			status: 'ACCEPTED',
			groupList: ['userGroup-1', '00000000-2222-2222-2222-000000000000']
		},
		{
			id: 'mock-user-id-5',
			userName: 'mock-user-id-5',
			firstName: 'Katherine',
			lastName: 'Pryde',
			email: 'kpryde@example.com',
			mpId: 'mock-user-mpId-3',
			dateAdded: 1616202447928,
			// permissions: {
			// 	device_management: true
			// }
			roles: ['tenant-user'],
			status: 'ACCEPTED',
			groupList: ['userGroup-1', '00000000-1111-1111-1111-000000000000']
		},
		{
			id: 'mock-user-id-11',
			userName: 'mock-user-id-11',
			firstName: 'Hank',
			lastName: 'McCoy',
			email: 'hmccoy@example.com',
			mpId: 'mock-user-mpId-4',
			dateAdded: 1616202447928,
			// permissions: {
			// 	user_management: true
			// }
			roles: ['tenant-admin'],
			status: 'ACCEPTED',
			groupList: ['userGroup-1', '00000000-2222-2222-2222-000000000000']
		},
		{
			id: 'mock-user-id-13',
			userName: 'mock-user-id-13',
			firstName: undefined,
			lastName: undefined,
			username: 'ebrooks',
			email: 'ebrooks@example.com',
			mpId: 'mock-user-mpId-5',
			dateAdded: 1616202447928,
			// permissions: {
			// 	license_management: true
			// }
			roles: ['tenant-admin'],
			status: 'ACCEPTED',
			groupList: ['userGroup-2', '00000000-2222-2222-2222-000000000000']
		},
		{
			id: 'mock-user-id-19',
			userName: 'mock-user-id-19',
			firstName: 'Reed',
			lastName: 'Richards',
			email: 'rrichards@example.com',
			mpId: 'mock-user-mpId-6',
			dateAdded: 1616202447928,
			roles: ['tenant-user'],
			blocked: true,
			status: 'ACCEPTED',
			groupList: ['userGroup-2', '00000000-1111-1111-1111-000000000000']
		},
		{
			id: 'mock-user-id-21',
			userName: 'mock-user-id-21',
			firstName: 'Sean',
			lastName: 'Sullivan',
			email: 'ssullivan@example.com',
			mpId: 'mock-user-mpId-7',
			dateAdded: 1616202447928,
			roles: ['tenant-user'],
			status: 'ACCEPTED',
			groupList: ['userGroup-2', '00000000-1111-1111-1111-000000000000']
		},
		{
			id: 'mock-user-id-23',
			userName: 'mock-user-id-23',
			firstName: 'Tabrej',
			lastName: 'Chowdhury',
			email: 'tchowdhury@example.com',
			mpId: 'mock-user-mpId-8',
			dateAdded: 1616202447928,
			roles: ['tenant-user'],
			status: 'ACCEPTED',
			groupList: ['00000000-1111-1111-1111-000000000000', '00000000-3333-3333-3333-000000000000']
		},
		{
			id: 'mock-user-id-25',
			userName: 'mock-user-id-25',
			firstName: 'Pavani',
			lastName: 'Ennamaneni',
			email: 'pennamaneni@example.com',
			mpId: 'mock-user-mpId-9',
			dateAdded: 1615202447928,
			roles: ['tenant-admin'],
			status: 'BLOCKED',
			groupList: ['00000000-1111-1111-1111-000000000000', '00000000-3333-3333-3333-000000000000']
		},
		{
			id: 'mock-user-id-27',
			userName: 'mock-user-id-27',
			firstName: 'William',
			lastName: 'Smith',
			email: 'wsmith@example.com',
			mpId: 'mock-user-mpId-10',
			dateAdded: 1615202447928,
			roles: ['tenant-user'],
			status: 'ACCEPTED',
			groupList: ['00000000-1111-1111-1111-000000000000', '00000000-3333-3333-3333-000000000000']
		},
		{
			id: 'mock-user-id-29',
			userName: 'mock-user-id-29',
			firstName: 'Sue',
			lastName: 'Johnson',
			email: 'sjohnson@example.com',
			mpId: 'mock-user-mpId-11',
			dateAdded: 1615002447928,
			roles: ['tenant-user'],
			status: 'ACCEPTED',
			groupList: ['00000000-1111-1111-1111-000000000000', '00000000-3333-3333-3333-000000000000']
		}
	];
	const pendingUsers = [
		{
			id: 'mock-pending-user-1',
			email: 'jfrost@example.com',
			dateAdded: 1611202447928,
			token: 'f024244021',
			roles: ['tenant-admin'],
			status: 'PENDING',
			userGroupId: ['userGroup-1', '00000000-1111-1111-1111-000000000000']
		},
		{
			id: 'mock-pending-user-3',
			email: 'zjohnson@example.com',
			dateAdded: 1616202447528,
			token: 'f024244027',
			roles: ['tenant-user'],
			status: 'PENDING',
			userGroupId: ['00000000-1111-1111-1111-000000000000', '00000000-3333-3333-3333-000000000000']
		}
	];
	const userGroups = [
		// uncomment user group 0 for a lengthy group title that falls to the next line
		// {
		// 	id: 'userGroup-0',
		// 	name: 'My name is Ozymandias, king of kings!  Look upon my works, ye mighty, and despair!',
		// 	updatedAt: 1619483850584
		// },
		// default ungrouped, user, and admin groups
		{
			id: '00000000-3333-3333-3333-000000000000',
			name: '00000000-3333-3333-3333-000000000000',
			updatedAt: 1619483850584
		},
		{
			id: '00000000-1111-1111-1111-000000000000',
			name: '00000000-1111-1111-1111-000000000000',
			updatedAt: 1619483850584
		},
		{
			id: '00000000-2222-2222-2222-000000000000',
			name: '00000000-2222-2222-2222-000000000000',
			updatedAt: 1619483850584
		},
		{
			id: 'userGroup-1',
			name: 'Exec Group',
			updatedAt: 1619483870584
		},
		{
			id: 'userGroup-2',
			name: 'Marketing Group',
			updatedAt: 1619483850584
		},
		{
			id: 'userGroup-3',
			name: 'Admin Group',
			updatedAt: 1619483850584
		}
	];
	// uncomment the call below to mimic hundreds of users
	// addHundredsOfUsers();
	const devices = [
		{
			id: 'DA61F011000003',
			name: 'First Floor - Lobby',
			model: '287',
			location: 'First Floor - Lobby',
			stcApp: true,
			ip: '888.888.888.888',
			serial: 'A61F011000003',
			description: 'First Floor - Lobby: Description',
			licensed: true,
			groupList: ['00000000-4444-4444-4444-000000000000']
		},
		{
			id: 'A61F011000005',
			name: 'Second Floor - Lobby',
			model: '364e',
			location: 'Second Floor - Lobby',
			stcApp: true,
			ip: '12.34.56.80',
			serial: 'A61F011000005',
			description: 'Second Floor - Lobby Description',
			licensed: true,
			groupList: ['00000000-4444-4444-4444-000000000000']
		},
		{
			id: 'A61F011000007',
			name: 'First Floor - Lab',
			model: '364e',
			location: 'First Floor - Lab',
			stcApp: true,
			ip: '12.34.56.81',
			serial: 'A61F011000007',
			description: 'First Floor - Lab Description',
			licensed: true,
			groupList: ['deviceGroup-1']
		},
		{
			id: 'A61F011000009',
			name: 'Second Floor - Lab',
			model: '4052',
			location: 'Second Floor - Lab',
			stcApp: true,
			ip: '12.34.56.82',
			serialNumber: 'A61F011000009',
			description: 'Second Floor - Lab Description Test Extra Long Description',
			licensed: false,
			groupList: ['00000000-4444-4444-4444-000000000000']
		},
		{
			id: 'A61F011000011',
			name: 'Third Floor - Lab',
			model: '4750',
			location: 'Third Floor - Lab',
			stcApp: true,
			ip: '12.34.56.83',
			serial: 'A61F011000011',
			description: 'Third Floor - Lab Description',
			licensed: true,
			groupList: ['00000000-4444-4444-4444-000000000000']
		},
		{
			id: 'A61F011000013',
			name: 'Warehouse',
			model: 'C287',
			location: 'Warehouse',
			stcApp: true,
			ip: '12.34.56.84',
			serial: 'A61F011000013',
			description: 'Warehouse Description',
			licensed: true,
			groupList: ['00000000-4444-4444-4444-000000000000']
		},
		{
			id: 'A61F011000015',
			name: 'Third Floor - Lobby',
			model: 'C287',
			location: 'Third Floor - Lobby',
			stcApp: true,
			ip: '12.34.56.85',
			serial: 'A61F011000015',
			description: 'Third Floor - Lobby Description',
			licensed: false,
			groupList: ['deviceGroup-1']
		},
		{
			id: 'A61F011000017',
			name: 'Fourth Floor - Lobby',
			model: 'C300i',
			location: 'Fourth Floor - Lobby',
			stcApp: true,
			ip: '12.34.56.86',
			serial: 'A61F011000017',
			description: 'Fourth Floor - Lobby Description',
			licensed: true,
			groupList: ['deviceGroup-1', 'deviceGroup-2']
		},
		{
			id: 'A61F011000619',
			name: 'Fourth Floor - Lab',
			model: 'C666',
			location: 'Fourth Floor - Lab',
			stcApp: true,
			ip: '12.34.56.87',
			serial: 'A61F011000619',
			description:
				'0987h1234f0987h1234f0987h1234f987h1234f09871h234f0987h1234f0987h1234f0987h1234f0987h1234f-098j1234fj',
			licensed: true,
			groupList: ['deviceGroup-2', 'deviceGroup-3']
		},
		{
			id: 'A61F011000621',
			name: 'Fifth Floor - Lab',
			model: 'C666',
			location: 'Fifth Floor - Lab',
			stcApp: false,
			ip: '12.34.56.88',
			serial: 'A61F011000621',
			description: 'Fifth Floor - Lab Description',
			licensed: true,
			groupList: [
				'deviceGroup-1',
				'deviceGroup-2',
				'deviceGroup-3',
				'deviceGroup-4',
				'deviceGroup-5',
				'deviceGroup-6',
				'deviceGroup-7',
				'deviceGroup-8',
				'deviceGroup-9'
			]
		}
	];
	const deviceGroups = [
		{
			id: '00000000-4444-4444-4444-000000000000',
			name: '00000000-4444-4444-4444-000000000000',
			dateUpdated: 1619483870584
		},
		{
			id: 'deviceGroup-1',
			name: 'HQ Building 101'
		},
		{
			id: 'deviceGroup-2',
			name: 'HQ Building     201',
			dateUpdated: 1619483850584
		},
		{
			id: 'deviceGroup-3',
			name: 'HQ Building 301',
			dateUpdated: 1619483860584
		},
		{
			id: 'deviceGroup-4',
			name: 'HQ Building 401',
			dateUpdated: 1619483860584
		},
		{
			id: 'deviceGroup-5',
			name: 'HQ Building 501',
			dateUpdated: 1619483860584
		},
		{
			id: 'deviceGroup-6',
			name: 'HQ Building 601',
			dateUpdated: 1619483860584
		},
		{
			id: 'deviceGroup-7',
			name: 'HQ Building 701',
			dateUpdated: 1619483860584
		},
		{
			id: 'deviceGroup-8',
			name: 'HQ Building 801',
			dateUpdated: 1619483860587
		},
		{
			id: 'deviceGroup-9',
			name: 'HQ Building 901',
			dateUpdated: 1619483860584
		},
		{
			id: 'deviceGroup-10',
			name: 'HQ Building 1001',
			dateUpdated: 1619483860584
		},
		{
			id: 'deviceGroup-11',
			name:
				'Super Long Name that should wrap. aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa',
			dateUpdated: 1619483860584
		}
	];
	const forms = [
		{
			id: 'form-1',
			title: 'Conversions and File Upload',
			creator: 'mock-user-id-27',
			formStatus: 'draft',
			active: false,
			formGroupId: undefined,
			created: +new Date('2022-04-18 15:55'),
			updated: +new Date('2022-04-18 15:55')
		},
		{
			id: 'form-2',
			title: 'Email Sorting Automation',
			creator: 'mock-user-id-27',
			formStatus: 'draft',
			active: false,
			formGroupId: undefined,
			created: +new Date('2022-04-18 15:55'),
			updated: +new Date('2022-04-18 15:55')
		},
		{
			id: 'form-3',
			title: 'Documentation Forms',
			creator: 'mock-user-id-27',
			formStatus: 'published',
			active: true,
			formGroupId: undefined,
			created: +new Date('2022-04-16 15:55'),
			updated: +new Date('2022-04-17 15:55')
		},
		{
			id: 'form-5',
			title: 'File Storage',
			creator: 'mock-user-id-29',
			formStatus: 'published',
			active: true,
			formGroupId: 'formGroup-1',
			created: +new Date('2022-04-14 15:55'),
			updated: +new Date('2022-04-15 15:55')
		},
		{
			id: 'form-7',
			title: 'Public Conversions with Upload',
			creator: 'mock-user-id-27',
			formStatus: 'draft',
			active: false,
			formGroupId: 'formGroup-1',
			created: +new Date('2022-04-12 15:55'),
			updated: +new Date('2022-04-13 15:55')
		},
		{
			id: 'form-9',
			title: 'Upload Documents',
			creator: 'mock-user-id-27',
			formStatus: 'published',
			active: true,
			formGroupId: 'formGroup-1',
			created: +new Date('2022-04-12 15:55'),
			updated: +new Date('2022-04-13 15:55')
		},
		{
			id: 'form-11',
			title: 'File Conversion',
			creator: 'mock-user-id-27',
			formStatus: 'draft',
			active: false,
			formGroupId: 'formGroup-1',
			created: +new Date('2022-04-10 15:55'),
			updated: +new Date('2022-04-11 15:55')
		},
		{
			id: 'form-13',
			title: 'Cloud Storage',
			creator: 'mock-user-id-27',
			formStatus: 'published',
			active: true,
			formGroupId: 'formGroup-2',
			created: +new Date('2022-04-10 15:55'),
			updated: +new Date('2022-04-11 15:55')
		}
	];
	const formGroups = [
		{
			id: 'formGroup-1',
			title: 'Dev Forms',
			updatedAt: 1619483870584
		},
		{
			id: 'formGroup-2',
			title: 'Team Forms',
			updatedAt: 1619483850584
		},
		{
			id: 'formGroup-3',
			title: 'Test Forms',
			updatedAt: 1619483850585
		}
	];

	const roles = [
		{
			id: 'role-1',
			name: 'Test Role 1',
			permissions: {
				devicesTab: {
					devicesSection: {
						view: true,
						add: false,
						edit: true,
						remove: false,
						activate: true,
						createGroup: true,
						editGroup: false,
						deleteGroup: true
					}
				},
				formsTab: {
					formsSection: {
						view: true,
						add: false,
						edit: false,
						remove: true,
						import: false,
						activate: true,
						deactivate: false,
						createGroup: true,
						editGroup: false,
						deleteGroup: true,
						clone: false
					}
				},
				usersTab: {
					usersSection: {
						view: false,
						invite: true,
						edit: false,
						remove: true,
						block: false,
						createGroup: true,
						editGroup: true,
						deleteGroup: false
					}
				},
				workflowsTab: {
					workflowsSection: {
						view: false,
						create: true,
						edit: false,
						remove: true,
						share: false,
						import: true,
						run: false,
						schedule: true,
						changeOwner: false,
						simulation: true,
						createGroup: false,
						shareGroup: true,
						editGroup: false,
						deleteGroup: true,
						clone: false
					}
				},
				dataTab: {
					reportsSection: {
						view: true
					},
					logsSection: {
						view: true
					}
				},
				jobsTab: {
					jobQueueSection: {
						view: true,
						create: true,
						edit: true
					},
					trackerSection: {
						view: true,
						track: true
					},
					tasksSection: {
						view: true
					}
				},
				settingsTab: {
					quickLinksSection: {
						view: true,
						add: true,
						edit: true,
						remove: true
					},
					rolesSection: {
						view: true,
						create: true,
						edit: true,
						remove: true,
						assignUser: true,
						assignGroup: true,
						clone: true
					},
					settingsSection: {
						view: true,
						edit: true
					}
				}
			},
			systemRole: 'tenant-admin'
		},
		{
			id: 'role-2',
			name: 'Test Role 2',
			permissions: {
				devicesTab: {
					devicesSection: {
						view: true,
						add: false,
						edit: true,
						remove: false,
						activate: true,
						createGroup: true,
						editGroup: false,
						deleteGroup: true
					}
				},
				formsTab: {
					formsSection: {
						view: true,
						add: false,
						edit: false,
						remove: true,
						import: false,
						activate: true,
						deactivate: false,
						createGroup: true,
						editGroup: false,
						deleteGroup: true,
						clone: false
					}
				},
				usersTab: {
					usersSection: {
						view: false,
						invite: true,
						edit: false,
						remove: true,
						block: false,
						createGroup: true,
						editGroup: true,
						deleteGroup: false
					}
				},
				workflowsTab: {
					workflowsSection: {
						view: false,
						create: true,
						edit: false,
						remove: true,
						share: false,
						import: true,
						run: false,
						schedule: true,
						changeOwner: false,
						simulation: true,
						createGroup: false,
						shareGroup: true,
						editGroup: false,
						deleteGroup: true,
						clone: false
					}
				},
				dataTab: {
					reportsSection: {
						view: true
					},
					logsSection: {
						view: true
					}
				},
				jobsTab: {
					jobQueueSection: {
						view: true,
						create: true,
						edit: true
					},
					trackerSection: {
						view: true,
						track: true
					},
					tasksSection: {
						view: true
					}
				},
				settingsTab: {
					quickLinksSection: {
						view: true,
						add: true,
						edit: true,
						remove: true
					},
					rolesSection: {
						view: false,
						create: true,
						edit: false,
						remove: true,
						assignUser: true,
						assignGroup: true,
						clone: true
					},
					settingsSection: {
						view: true,
						edit: true
					}
				}
			},
			systemRole: 'tenant-user'
		},
		{
			id: 'role-3',
			name: 'Test Role 3',
			permissions: {
				devicesTab: {
					devicesSection: {
						view: true,
						add: false,
						edit: true,
						remove: false,
						activate: true,
						createGroup: true,
						editGroup: false,
						deleteGroup: true
					}
				},
				formsTab: {
					formsSection: {
						view: true,
						add: false,
						edit: false,
						remove: true,
						import: false,
						activate: true,
						deactivate: false,
						createGroup: true,
						editGroup: false,
						deleteGroup: true,
						clone: false
					}
				},
				usersTab: {
					usersSection: {
						view: false,
						invite: true,
						edit: false,
						remove: true,
						block: false,
						createGroup: true,
						editGroup: true,
						deleteGroup: false
					}
				},
				workflowsTab: {
					workflowsSection: {
						view: false,
						create: true,
						edit: false,
						remove: true,
						share: false,
						import: true,
						run: false,
						schedule: true,
						changeOwner: false,
						simulation: true,
						createGroup: false,
						shareGroup: true,
						editGroup: false,
						deleteGroup: true,
						clone: false
					}
				},
				dataTab: {
					reportsSection: {
						view: true
					},
					logsSection: {
						view: true
					}
				},
				jobsTab: {
					jobQueueSection: {
						view: true,
						create: true,
						edit: true
					},
					trackerSection: {
						view: true,
						track: true
					},
					tasksSection: {
						view: true
					}
				},
				settingsTab: {
					quickLinksSection: {
						view: true,
						add: true,
						edit: true,
						remove: true
					},
					rolesSection: {
						view: false,
						create: true,
						edit: false,
						remove: true,
						assignUser: true,
						assignGroup: true,
						clone: true
					},
					settingsSection: {
						view: true,
						edit: true
					}
				}
			}
		},
		{
			id: 'role-4',
			name: 'Test Role 4',
			permissions: {
				devicesTab: {
					devicesSection: {
						view: false,
						add: false,
						edit: false,
						remove: false,
						activate: false,
						createGroup: false,
						editGroup: false,
						deleteGroup: false
					}
				},
				formsTab: {
					formsSection: {
						view: true,
						add: false,
						edit: false,
						remove: true,
						import: false,
						activate: true,
						deactivate: false,
						createGroup: true,
						editGroup: false,
						deleteGroup: true,
						clone: false
					}
				},
				usersTab: {
					usersSection: {
						view: false,
						invite: true,
						edit: false,
						remove: true,
						block: false,
						createGroup: true,
						editGroup: true,
						deleteGroup: false
					}
				},
				workflowsTab: {
					workflowsSection: {
						view: false,
						create: true,
						edit: false,
						remove: true,
						share: false,
						import: true,
						run: false,
						schedule: true,
						changeOwner: false,
						simulation: true,
						createGroup: false,
						shareGroup: true,
						editGroup: false,
						deleteGroup: true,
						clone: false
					}
				},
				dataTab: {
					reportsSection: {
						view: true
					},
					logsSection: {
						view: true
					}
				},
				jobsTab: {
					jobQueueSection: {
						view: true,
						create: true,
						edit: true
					},
					trackerSection: {
						view: true,
						track: true
					},
					tasksSection: {
						view: true
					}
				},
				settingsTab: {
					quickLinksSection: {
						view: true,
						add: true,
						edit: true,
						remove: true
					},
					rolesSection: {
						view: true,
						create: true,
						edit: true,
						remove: true,
						assignUser: true,
						assignGroup: true,
						clone: true
					},
					settingsSection: {
						view: true,
						edit: true
					}
				}
			}
		}
	];

	const entitlements = {
		permissions: {
			devicesTab: {
				devicesSection: {
					view: true,
					add: true,
					edit: true,
					remove: true,
					activate: true,
					createGroup: true,
					editGroup: true,
					deleteGroup: true
				}
			},
			formsTab: {
				formsSection: {
					view: true,
					add: true,
					edit: true,
					remove: true,
					import: true,
					activate: true,
					deactivate: true,
					createGroup: true,
					editGroup: true,
					deleteGroup: true,
					clone: true
				}
			},
			usersTab: {
				usersSection: {
					view: true,
					invite: true,
					edit: true,
					remove: true,
					block: true,
					createGroup: true,
					editGroup: true,
					deleteGroup: true
				}
			},
			workflowsTab: {
				workflowsSection: {
					view: true,
					create: true,
					edit: true,
					remove: true,
					share: true,
					import: true,
					run: true,
					schedule: true,
					changeOwner: true,
					simulation: true,
					createGroup: true,
					shareGroup: true,
					editGroup: true,
					deleteGroup: true,
					clone: true
				}
			},
			dataTab: {
				reportsSection: {
					view: true
				},
				logsSection: {
					view: true
				}
			},
			jobsTab: {
				jobQueueSection: {
					view: true,
					create: true,
					edit: true
				},
				trackerSection: {
					view: true,
					track: true
				},
				tasksSection: {
					view: true
				}
			},
			settingsTab: {
				quickLinksSection: {
					view: true,
					add: true,
					edit: true,
					remove: true
				},
				rolesSection: {
					view: true,
					create: true,
					edit: true,
					remove: true,
					assignUser: true,
					assignGroup: true,
					clone: true
				},
				settingsSection: {
					view: true,
					edit: true
				}
			}
		},
		features: {
			assign_tenant_manager: true,
			azure_ad_integration: true,
			device_sharing: true,
			forms_management: true,
			forms_selector: true,
			import_device: true,
			license_calculator: true,
			licensing_platform: true,
			linear_forms: true,
			nfr_portal: true,
			processing_node_logging: true,
			public_user_access: true,
			rendering_custom_forms_on_mfps: true,
			samples_shared_with_all_admins: true,
			support_for_paragon_integration: true,
			tenant_settings_for_timezone: true
		}
	};

	const workflows = [
		{
			id: '1b501a9b9d2e493baaeee3c440c355ad',
			name: 'Scan to Google Drive',
			status: 'running',
			modified: '2022-08-15T14:30:58.4033151+00:00',
			acl: undefined,
			options: {
				PreviewPageCount: 3,
				group: 'workflowGroup-1'
			},
			workflowGroupId: 'workflowGroup-1'
		},
		{
			id: '2b501a9b9d2e493baaeee3c440c355ad',
			name: 'Scan to Box',
			status: 'running',
			modified: '2022-08-15T14:31:58.4033151+00:00',
			acl: undefined,
			options: {
				PreviewPageCount: 3,
				Group: 'workflowGroup-1'
			},
			workflowGroupId: 'workflowGroup-1'
		},
		{
			id: '4b501a9b9d2e493baaeee3c440c355ad',
			name: 'Scan to OneDrive for Business',
			status: 'stopped',
			modified: '2022-08-15T14:33:58.4033151+00:00',
			acl: {
				users: [{ name: user.id, role: 'owner' }],
				groups: [
					{ name: 'userGroup-1', role: 'edit' },
					{ name: 'userGroup-2', role: 'view' }
				]
			},
			options: {
				PreviewPageCount: 3,
				Group: 'workflowGroup-1'
			},
			workflowGroupId: 'workflowGroup-1'
		},
		{
			id: 'workflow-9',
			name: 'Route to SharePoint Online',
			status: 'running',
			modified: '2022-08-15T14:34:58.4033151+00:00',
			acl: {
				users: [
					{ name: user.id, role: 'owner' },
					{ name: 'mock-user-id-3', role: 'edit' },
					{ name: 'mock-user-id-5', role: 'edit' },
					{ name: 'mock-user-id-11', role: 'view' }
				],
				groups: []
			},
			options: {
				PreviewPageCount: 3,
				Group: 'workflowGroup-2'
			},
			workflowGroupId: 'workflowGroup-2'
		},
		{
			id: 'workflow-11',
			name: 'Route to Marketing',
			status: 'stopped',
			modified: '2022-08-15T14:35:58.4033151+00:00',
			acl: {
				users: [
					{ name: user.id, role: 'owner' },
					{ name: 'mock-user-id-3', role: 'edit' },
					{ name: 'mock-user-id-5', role: 'edit' },
					{ name: 'mock-user-id-11', role: 'view' }
				],
				groups: []
			},
			options: {
				PreviewPageCount: 3,
				Group: 'workflowGroup-2'
			},
			workflowGroupId: 'workflowGroup-2'
		},
		{
			id: 'workflow-13',
			name: 'Route to R&D',
			status: 'running',
			modified: '2022-08-15T14:36:58.4033151+00:00',
			acl: {
				users: [{ name: 'mock-user-id-13', role: 'owner' }],
				groups: []
			},
			options: {
				PreviewPageCount: 3,
				Group: 'workflowGroup-2'
			},
			workflowGroupId: 'workflowGroup-2'
		},
		{
			id: 'workflow-15',
			name: 'Convert and Route to Box',
			status: 'paused',
			modified: '2022-08-15T14:37:58.4033151+00:00',
			acl: undefined,
			options: {
				PreviewPageCount: 3,
				Group: 'workflowGroup-2'
			},
			workflowGroupId: 'workflowGroup-2'
		},
		{
			id: 'workflow-17',
			name: 'Convert and Route to SharePoint',
			status: 'draft',
			modified: '2022-08-15T14:38:58.4033151+00:00',
			acl: {
				users: [
					{ name: 'mock-user-id-19', role: 'owner' },
					{ name: 'mock-user-id-21', role: 'edit' },
					{ name: 'mock-user-id-23', role: 'view' }
				],
				groups: [{ name: 'userGroup-2', role: 'view' }]
			},
			options: {
				PreviewPageCount: 3,
				Group: 'workflowGroup-2'
			},
			workflowGroupId: 'workflowGroup-2'
		},
		{
			id: 'workflow-19',
			name: 'Convert and Route to OneDrive',
			status: 'draft',
			modified: '2022-08-15T14:39:58.4033151+00:00',
			acl: undefined,
			options: {
				PreviewPageCount: 1,
				Group: 'workflowGroup-2'
			},
			workflowGroupId: 'workflowGroup-2'
		},
		{
			id: 'workflow-21',
			name: 'Route to R&D',
			status: 'stopped',
			modified: '2022-08-15T14:40:58.4033151+00:00',
			acl: undefined,
			options: {
				PreviewPageCount: 6,
				Group: 'workflowGroup-3'
			},
			workflowGroupId: 'workflowGroup-3'
		}
	];
	// uncomment below to mimic a workflow table that needs a scrollbar
	const tasks = [
		'Go to warp',
		'Open pod bay doors',
		'Use the Force',
		'Release the Kraken',
		'Test your might',
		'Do the time warp again',
		'Fastball Special',
		'Let the spice flow',
		'Kneel before Zod',
		'Save the cheerleader, save the world',
		'Hawkmen, dive',
		'Build it, they will come',
		'Make it so',
		'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa'
	];
	for (let i = 0; i < tasks.length; i += 1) {
		workflows.push({
			id: `workflow-${i + 22}`,
			name: tasks[i],
			status: 'running',
			modified: '2022-08-15T14:30:58.4033151+00:00',
			acl: undefined,
			options: {
				PreviewPageCount: 0,
				Group: 'workflowGroup-2'
			},
			workflowGroupId: 'workflowGroup-2'
		});
	}
	const workflowGroups = [
		{
			id: 'workflowGroup-1',
			name: 'My Workflows',
			modified: 1619483870584
		},
		{
			id: 'workflowGroup-2',
			name: 'Other Workflows',
			modified: 1617483870584
		},
		{
			id: 'workflowGroup-3',
			name: 'Some Workflows',
			modified: 1615483870584
		}
		// {
		// 	id: 'workflowGroup-4',
		// 	name: 'More Workflows',
		// 	updatedAt: 1615483870584
		// },
		// {
		// 	id: 'workflowGroup-5',
		// 	name: 'Further Workflows',
		// 	updatedAt: 1615483870584
		// },
		// {
		// 	id: 'workflowGroup-6',
		// 	name: 'Extra Workflows',
		// 	updatedAt: 1615483870584
		// },
		// {
		// 	id: 'workflowGroup-7',
		// 	name: 'Bonus Workflows',
		// 	updatedAt: 1615483870584
		// },
		// {
		// 	id: 'workflowGroup-8',
		// 	name: 'Added Workflows',
		// 	updatedAt: 1615483870584
		// },
		// {
		// 	id: 'workflowGroup-9',
		// 	name: 'New Workflows',
		// 	updatedAt: 1615483870584
		// }
	];
	const logs = [
		{
			SK: 'log-1',
			Type: 'workflow',
			severity: 'low',
			DateAdded: 1646110800000,
			MsgKey: 'Workflow Secure Files was checked in.',
			Action: 'Check in',
			Info: {
				deviceId: 'A112233440001',
				deviceName: '558'
			}
		},
		{
			SK: 'log-2',
			Type: 'workflow',
			severity: 'low',
			DateAdded: 1646197200000,
			MsgKey: 'Workflow Scan Financial Records was checked out.',
			Action: 'Check out',
			Info: {
				deviceId: 'A112233440002',
				deviceName: '364e'
			}
		},
		{
			SK: 'log-3',
			Type: 'workflow',
			severity: 'low',
			DateAdded: 1646283600000,
			MsgKey: 'Workflow Verification was started and failed.',
			Action: 'Error',
			Info: {
				deviceId: 'A112233440003',
				deviceName: '364e',
				policyFails: {
					msg: "I can't do that, Dave",
					deviceSetting: 120,
					msgKey: 'encryptpdfFail',
					requiredSetting: '15',
					policyItemKey: 'encryptpdf'
				}
			}
		},
		{
			SK: 'log-4',
			Type: 'workflow',
			severity: 'high',
			DateAdded: 1646370000000,
			MsgKey: 'Workflow Secure Files was paused.',
			Action: 'Pause',
			Info: {
				deviceId: 'A112233440004',
				deviceName: '364e',
				policyFails: [
					{
						msg: "hhdencrypt is 'false' but should be 'true'",
						deviceSetting: false,
						msgKey: 'hhdencryptFail',
						requiredSetting: 'true',
						policyItemKey: 'hhdencrypt'
					},
					{
						msg: "encryptpdf is '120' but should be '5'",
						deviceSetting: 120,
						msgKey: 'encryptpdfFail',
						requiredSetting: '5',
						policyItemKey: 'encryptpdf'
					}
				]
			}
		},
		{
			SK: 'log-5',
			Type: 'workflow',
			severity: 'high',
			DateAdded: 1646456400000,
			MsgKey: 'Workflow Secure Files was scheduled.',
			Action: 'Scheduled',
			Info: {
				deviceId: 'A112233440005',
				deviceName: 'C300',
				policyFails: [
					{
						msg: "encryptpdf is '120' but should be '15'",
						deviceSetting: 120,
						msgKey: 'encryptpdfFail',
						requiredSetting: '15',
						policyItemKey: 'encryptpdf'
					}
				]
			}
		},
		{
			SK: 'log-6',
			Type: 'workflow',
			severity: 'high',
			DateAdded: 1646542800000,
			MsgKey: 'Workflow Scan Financial Records was started.',
			Action: 'Start',
			Info: {
				deviceId: 'A112233440006',
				deviceName: 'c558',
				policyFails: [
					{
						msg: "idandprint is '60`' but should be '30'",
						deviceSetting: 60,
						msgKey: 'idandprintFail',
						requiredSetting: '30',
						policyItemKey: 'idandprint'
					}
				]
			}
		},
		{
			SK: 'log-7',
			Type: 'workflow',
			severity: 'high',
			DateAdded: 1646629200000,
			MsgKey: 'Workflow Scan Financial Records was stopped.',
			Action: 'Stop',
			Info: {
				deviceId: 'A112233440007',
				deviceName: 'C300i',
				policyFails: [
					{
						msg: "publicauth is '2' but should be '1'",
						deviceSetting: 2,
						msgKey: 'publicauthFail',
						requiredSetting: '1',
						policyItemKey: 'publicauth'
					}
				]
			}
		},
		{
			SK: 'log-8',
			Type: 'device',
			severity: 'low',
			DateAdded: 1646715600000,
			MsgKey: 'Device bizhub 364e was added.',
			Action: 'Added',
			Info: {
				deviceId: 'A112233440008',
				deviceName: '364e'
			}
		},
		{
			SK: 'log-10',
			Type: 'device',
			severity: 'low',
			DateAdded: 1646888400000,
			MsgKey: 'Device bizhub C300i was removed.',
			Action: 'Removed',
			Info: {
				deviceId: 'A1122334400010',
				deviceName: 'C300i'
			}
		},
		{
			SK: 'log-11',
			Type: 'device',
			severity: 'low',
			DateAdded: 1646974800000,
			MsgKey: 'Device bizhub 558 was registered.',
			Action: 'Registered',
			Info: {
				deviceId: 'A1122334400011',
				deviceName: '558'
			}
		},
		{
			SK: 'log-12',
			Type: 'device',
			severity: 'low',
			DateAdded: 1647061200000,
			MsgKey: 'Device bizhub C300i was unregistered.',
			Action: 'Unregistered',
			Info: {
				deviceId: 'A1122334400012',
				deviceName: 'C300i'
			}
		},
		{
			SK: 'log-13',
			Type: 'user',
			severity: 'low',
			DateAdded: 1647147600000,
			MsgKey: 'User rrichards@example.com was activated.',
			Action: 'Activated',
			Info: {
				deviceId: 'A1122334400013',
				deviceName: '558'
			}
		},
		{
			SK: 'log-14',
			Type: 'user',
			severity: 'low',
			DateAdded: 1647230400000,
			MsgKey: 'User kpryde@example.com was blocked.',
			Action: 'Blocked',
			Info: {
				type: 'change-admin-password',
				id: '243000008-7g3uemBjwKNCVuzjes',
				status: 'completed',
				deviceId: 'A1122334400014',
				deviceName: 'C300'
			}
		},
		{
			SK: 'log-17',
			Type: 'user',
			severity: 'low',
			DateAdded: 1647403200000,
			MsgKey: 'User ebrooks@example.com was blocked and failed.',
			Action: 'Error',
			Info: {
				msgParm1: 'autodoc',
				msgParm2: 'Day3',
				msgParm3: 'Day7',
				deviceId: 'A1122334400017',
				deviceName: '558',
				policyFails: {
					msg: "I can't do that, Dave",
					deviceSetting: 120,
					msgKey: 'encryptpdfFail',
					requiredSetting: '15',
					policyItemKey: 'encryptpdf'
				}
			}
		},
		{
			SK: 'log-19',
			Type: 'user',
			severity: 'low',
			DateAdded: 1647489600000,
			MsgKey: 'User hmccoy@example.com accepted user invite.',
			Action: 'Invited',
			Info: {
				msgParm1: 'ssltls',
				msgParm2: "{'ssl3': 'false', 'tls13': 'true', 'tls12': 'true', 'tls11': 'true', 'tls10': 'false'}",
				msgParm3: "{'ssl3': 'false', 'tls13': 'true', 'tls12': 'true', 'tls11': 'false', 'tls10': 'false'}",
				deviceId: 'A1122334400019',
				deviceName: 'C300'
			}
		},
		{
			SK: 'log-20',
			Type: 'device',
			severity: 'low',
			DateAdded: 1000,
			MsgKey: 'I cannot do that, Dave.',
			Action: 'Error',
			Info: {
				msgParm1: 'ssltls',
				msgParm2: "{'ssl3': 'false', 'tls13': 'true', 'tls12': 'true', 'tls11': 'true', 'tls10': 'false'}",
				msgParm3: "{'ssl3': 'false', 'tls13': 'true', 'tls12': 'true', 'tls11': 'false', 'tls10': 'false'}",
				deviceId: 'A1122334400019',
				deviceName: 'C300'
			}
		}
	];
	const mpDevices = [
		{
			id: 'mock-mp-device-guid-1',
			name: 'C558',
			family: 'ZeusSBK',
			generation: 'IT5',
			productLine: 'bizhub',
			serial: 'mock-mp-device-guid-1',
			friendlyName: 'Second Floor - Dev'
		},
		{
			id: 'mock-mp-device-guid-2',
			name: 'C556',
			family: 'ZeusSBK',
			generation: 'IT5',
			productLine: 'bizhub',
			serial: 'mock-mp-device-guid-2',
			friendlyName: 'Second Floor - QA'
		},
		{
			id: 'mock-mp-device-guid-3',
			name: 'C554',
			family: 'VenusMLK',
			generation: 'IT5',
			productLine: 'bizhub',
			serial: 'mock-mp-device-guid-3',
			friendlyName: 'Second Floor - Lab'
		},
		{
			id: 'mock-mp-device-guid-4',
			name: 'C358',
			family: 'Sparrow',
			generation: 'IT6',
			productLine: 'bizhub',
			serial: 'mock-mp-device-guid-4'
		},
		{
			id: 'mock-mp-device-guid-5',
			name: 'C356',
			family: 'Sparrow',
			generation: 'IT6',
			productLine: 'bizhub',
			serial: 'mock-mp-device-guid-5'
		},
		{
			id: 'mock-mp-device-guid-6',
			name: 'C354',
			family: 'Sparrow',
			generation: 'IT6',
			productLine: 'bizhub',
			serial: 'mock-mp-device-guid-6'
		},
		{
			id: 'mock-mp-device-guid-7',
			name: 'C666',
			family: 'Sparrow',
			generation: 'IT6',
			productLine: 'bizhub',
			serial: 'mock-mp-device-guid-7'
		},
		{
			id: 'mock-mp-device-guid-8',
			name: 'C667',
			family: 'Sparrow',
			generation: 'IT6',
			productLine: 'bizhub',
			serial: 'mock-mp-device-guid-8'
		},
		{
			id: 'mock-mp-device-guid-9',
			name: 'C668',
			family: 'Sparrow',
			generation: 'IT6',
			productLine: 'bizhub',
			serial: 'mock-mp-device-guid-9'
		}
	];

	// COMMENT ME TO A BE A USER
	// mock.onGet('/api/v1/tenant').reply(401, {
	if (localStorage.getItem('mockWorkflows')) {
		const wfxApiUrl = getWfxUrl('us-east-1');

		mock.onGet(`${wfxApiUrl}/api/wfx`).reply(200, workflows);

		mock.onGet(`${wfxApiUrl}/api/zip?password=false&id=workflow-21`).reply(
			200,
			Buffer.from('arrayBufferOfWorkflowDataFake')
		);
		mock.onGet(`${wfxApiUrl}/api/groups`).reply(200, workflowGroups);
	}

	if (localStorage.getItem('mockForms')) {
		const formsApiUrl = getFormsUrl(user.awsRegion);
		mock.onGet(`${formsApiUrl}/api/groups`).reply(200, [
			{
				id: '4976d6b2fccd40ce9b825c1eb12a5434',
				title: 'form-group-2',
				sortOrder: 2
			},
			{
				id: 'eabdc55208d842619bccb6397680930a',
				title: 'group-2',
				sortOrder: 3
			}
		]);

		mock.onGet(`${formsApiUrl}/api/forms`).reply(200, [
			{
				id: '1a2b3c4d5e6f7g8h',
				title: 'Project X',
				creator: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
				editor: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
				valid: true,
				created: 1696000000000,
				updated: 1696100000000,
				formStatus: 'published',
				formGroup: {
					id: '00000'
				}
			},
			{
				id: '2b3c4d5e6f7g8h9i',
				title: 'Task Management 2',
				creator: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
				editor: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
				valid: false,
				created: 1696100000000,
				updated: 1696200000000,
				formStatus: 'draft',
				formGroup: {
					id: '00000'
				}
			},
			{
				id: '3c4d5e6f7g8h9i0j',
				title: 'Product Launch 3',
				creator: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
				editor: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
				valid: true,
				created: 1696200000000,
				updated: 1696300000000,
				formStatus: 'published',
				formGroup: {
					id: '00000'
				}
			},
			{
				id: '4d5e6f7g8h9i0j1k',
				title: 'Meeting Notes 4',
				creator: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
				editor: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
				valid: true,
				created: 1696300000000,
				updated: 1696400000000,
				formStatus: 'published',
				formGroup: {
					id: '00000'
				}
			},
			{
				id: '5e6f7g8h9i0j1k2l',
				title: 'Expense Report 5',
				creator: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
				editor: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
				valid: true,
				created: 1696400000000,
				updated: 1696500000000,
				formStatus: 'published',
				formGroup: {
					id: '00000'
				}
			},
			{
				id: '6f7g8h9i0j1k2l3m',
				title: 'Project Y',
				creator: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
				editor: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
				valid: false,
				created: 1696500000000,
				updated: 1696600000000,
				formStatus: 'draft',
				formGroup: {
					id: '00000'
				}
			},
			{
				id: '7g8h9i0j1k2l3m4n',
				title: 'Survey Results 7',
				creator: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
				editor: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
				valid: true,
				created: 1696600000000,
				updated: 1696700000000,
				formStatus: 'published',
				formGroup: {
					id: '00000'
				}
			},
			{
				id: '8h9i0j1k2l3m4n5o',
				title: 'Marketing Campaign 8',
				creator: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
				editor: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
				valid: true,
				created: 1696700000000,
				updated: 1696800000000,
				formStatus: 'published',
				formGroup: {
					id: '00000'
				}
			},
			{
				id: '3c58f69b470f42178b77d9249e2f3989',
				title: 'nmassey',
				creator: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
				editor: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
				valid: true,
				created: 1695312048179,
				updated: 1695312065423,
				formStatus: 'published',
				formGroup: {
					id: '00000'
				}
			},
			{
				id: 'b555c9e7f8fa41dfbc5a79683e22dd43',
				title: 'Test 1',
				creator: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
				editor: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
				valid: true,
				created: 1694832174280,
				updated: 1694832778817,
				formStatus: 'published',
				formGroup: {
					id: '00000'
				}
			}
		]);
	}

	if (localStorage.getItem('mock')) {
		mock.onGet('/api/tenants').reply(200, _.keyBy(managedLicenseGroups, 'id'));
		mock.onGet('/api/tenants/tenant-manager-groups').reply(200, _.keyBy(tenantLicenseGroups, 'id'));
		mock.onPost('/api/tenants/tenant-manager-groups').reply(async config => {
			const { name, parentId } = JSON.parse(config.data);
			const parentGroup = tenantLicenseGroups.filter(item => item.id === parentId)[0];

			const rsp: any = tenantLicenseGroups;
			rsp.push({
				name,
				// eslint-disable-next-line prefer-template
				id: '' + Math.random() * 100,
				parentId,
				parentName: parentGroup ? parentGroup.name : undefined
			});
			return [200, rsp];
		});
		mock.onPut('/api/tenants/tenant-manager-groups').reply(async config => {
			const { name, parentId, id } = JSON.parse(config.data);
			const currentIndex = tenantLicenseGroups.findIndex(item => item.id === id);

			const [parentName] = tenantLicenseGroups.filter(item => item.id === parentId);
			const rsp = tenantLicenseGroups;
			tenantLicenseGroups.splice(currentIndex, 1);
			rsp.push({
				name,
				parentId: parentId ?? undefined,
				id,
				parentName: parentName?.name,
				managerEmails: []
			});
			return [200, _.keyBy(rsp, 'id')];
		});
		mock.onPut(/\/api\/tenants\/edit-tenant-details\/[^/]+$/).reply(async config => {
			const id = config.url!.split('/').pop()!;
			const { name, parentId } = JSON.parse(config.data);
			const currentIndex = licenseGroups.findIndex(item => item.id === id);

			const rsp: any = licenseGroups
				.filter(({ isManager }) => isManager)
				.map(licenseGroup => _.omit(licenseGroup, ['isAdmin', 'isManager']));
			const currentTenant = licenseGroups.splice(currentIndex, 1);
			// @ts-ignore
			rsp.push({
				...currentTenant[0],
				name,
				parent: parentId,
				id
			});
			return [200, _.keyBy(rsp, 'id')];
		});

		mock.onGet(/\/api\/tenants\/tenant-manager-group\/[^/]+$/).reply(async config => {
			return [200, users];
		});

		mock.onPost(/\/api\/tenants\/[^/]+\/notifications/).reply(async config => {
			const { obj } = JSON.parse(config.data);
			const id = config.url?.split('/')[3];
			obj.groupId = id;
			return [200, obj];
		});

		mock.onPost(/\/api\/tenants\/[^/]+\/suspension/).reply(async config => {
			const { obj } = JSON.parse(config.data);
			const id = config.url?.split('/')[3];
			obj.tenantId = id;
			return [200, obj];
		});
		mock.onPatch(/\/api\/tenants\/tenant-group\/[^/]+\/invite-manager/).reply(async config => {
			const id = config.url?.split('/')[4];
			const { managerEmail } = JSON.parse(config.data);
			const update = tenantLicenseGroups.reduce((prev: any, item: any) => {
				if (item.id === id) {
					const copy = item;
					// eslint-disable-next-line no-unused-expressions
					copy.managerEmails?.push({ email: managerEmail, status: 'PENDING' });
					return [...prev, copy];
				}
				return [...prev, item];
			}, []);

			return [200, update];
		});
		mock.onGet(/\/api\/tenants\/slug\/[^/]+$/).reply(config => {
			const slug = config.url!.split('/').pop()!;
			const requestedLicenseGroup = licenseGroups.find(licenseGroup => licenseGroup.slug === slug);
			return requestedLicenseGroup ? [200, { id: requestedLicenseGroup.id }] : [404];
		});

		// mock.onGet(`/api/v1/tenant/${selectedLicenseGroup.id}`).reply(200, selectedLicenseGroup);
		mock.onGet(`/api/tenants/${selectedLicenseGroup.id}`).reply(200, {
			details: selectedLicenseGroup,
			users: _.keyBy(users, 'id'),
			pendingUsers: _.keyBy(pendingUsers, 'id'),
			userGroups: _.keyBy(userGroups, 'id'),
			devices: _.keyBy(devices, 'id'),
			deviceGroups: _.keyBy(deviceGroups, 'id'),
			forms: _.keyBy(forms, 'id'),
			formGroups: _.keyBy(formGroups, 'id'),
			roles: _.keyBy(roles, 'id')
			// workflows: _.keyBy(workflows, 'id'),
			// workflowGroups: _.keyBy(workflowGroups, 'id')
			// logs: _.keyBy(logs, 'id')
		});
		mock.onGet(`/api/user/${selectedLicenseGroup.id}/user/entitlements`).reply(200, entitlements);
		const wfxApiUrl = getWfxUrl('us-east-1');
		mock.onGet(`${wfxApiUrl}/api/groups`).reply(200, workflowGroups);
		mock.onGet(`/api/tenants/logs/${selectedLicenseGroup.id}`).reply(200, _.keyBy(logs, 'SK'));
		mock.onGet(`/api/device/user-devices`).reply(200, _.keyBy(mpDevices, 'id'));

		mock.onGet(`/api/user/user-tenants`).reply(200, adminedLicenseGroups);

		mock.onPost('/api/v1/nine/settings').reply(config => {
			// TODO: fixme or remove me!
			// HACK::use callback to trigger side effects of modifying mock tenant data
			// const selectedLicenseGroupId = config.url && config.url.split('/').filter(Boolean).at(-1); // EDIT::or maybe `config.url.split('/').filter(Boolean).at(-1)` ... they both kinda suck
			// let newRegion = config.data.region;
			console.log('config = ', config);
			// newRegion = licenseGroups;

			return [200];
		});

		mock.onGet(`/api/user/${selectedLicenseGroup.id}/preferences`).reply(200, {
			quickLinks: ['invite-users', 'add-task', 'create-workflow'],
			startPage: 'home',
			navbarBackgroundOpacity: 80,
			appearance: 'light',
			siteBackground: 'tropical-image.jpg',
			pageHeaderBackgroundOpacity: 80,
			tableHeaderColorBackgroundOpacity: 80,
			language: 'en',
			tableHeaderBackground: 'standard',
			tableHeaderStandardBackgroundOpacity: 80,
			homePageLayout: 'rows'
		});

		// WORKFLOW APIS
		mock.onGet(`${wfxApiUrl}/api/wfx`).reply(200, workflows);

		mock.onGet(`${wfxApiUrl}/api/zip?password=false&id=workflow-21`).reply(
			200,
			Buffer.from('arrayBufferOfWorkflowDataFake')
		);

		const logApiUrl = getLogApiUrl('us-east-1');
		const workflowLogsUrl = new RegExp(
			`${logApiUrl.replace(
				/[.*+?^${}()|[\]\\]/g,
				'\\$&'
			)}/log/.*/message\\?limit=.*&from=.*&to=.*&type=workflow&id=.*`
		);
		mock.onGet(workflowLogsUrl).reply(config => {
			const { id: workflowId } = qs.parse(new URL(config.url!).search, { ignoreQueryPrefix: true });
			return [
				200,
				{
					Items: [
						{
							DateAdded: 1683578664753,
							SK: '2387330135247-2jcd5RiuzQ8jTKoLEBxgJL',
							Action: 'checkin',
							Type: 'workflow',
							Info: {
								user: {
									name: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
									email: 'qa@kmbs.konicaminolta.us',
									id: '6f436e4e-564a-4227-a5a1-fedaccc8f075'
								},
								workflow: {
									id: workflowId,
									name: 'Issue_78',
									status: 'stopped'
								}
							},
							MsgKey: 'wfxCheckIn',
							LogLevel: 'info'
						},
						{
							DateAdded: 1683578640081,
							SK: '2387330159919-4LUvtVdQRXPitBFgcGH3ki',
							Action: 'checkout',
							Type: 'workflow',
							Info: {
								user: {
									name: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
									email: 'qa@kmbs.konicaminolta.us',
									id: '6f436e4e-564a-4227-a5a1-fedaccc8f075'
								},
								workflow: {
									id: workflowId,
									name: 'Issue_78',
									status: 'stopped'
								}
							},
							MsgKey: 'wfxCheckOut',
							LogLevel: 'info'
						},
						{
							DateAdded: 1683578636217,
							SK: '2387330163784-ML9d9Bx3wyaDhSoBLfzoXV',
							Action: 'stop',
							Type: 'workflow',
							Info: {
								status: 'stopped',
								user: {
									name: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
									email: 'qa@kmbs.konicaminolta.us',
									id: '6f436e4e-564a-4227-a5a1-fedaccc8f075'
								},
								workflow: {
									id: workflowId,
									name: 'Issue_78',
									status: 'stopped'
								}
							},
							MsgKey: 'wfxUpdateStatus',
							LogLevel: 'info'
						},
						{
							DateAdded: 1683578557703,
							SK: '2387330242297-3zE2sE6yBCYRhFJuUhJgm1',
							Action: 'stop',
							Type: 'instance',
							Info: {
								fileName: '20230508T164059813690.pdf',
								filePath: 'root/STS_April23/May 23/05-08',
								nodeName: 'Google Drive Connector'
							},
							MsgKey: 'fileUploadSuccess',
							LogLevel: 'info'
						},
						{
							DateAdded: 1683578553448,
							SK: '2387330246552-3vp8YJnJb13gUsHAEPhQnh',
							Action: 'start',
							Type: 'instance',
							Info: {
								fileName: '20230508T164059813690.pdf',
								filePath: 'root/STS_April23/May 23/05-08',
								nodeName: 'Google Drive Connector'
							},
							MsgKey: 'fileUploadStart',
							LogLevel: 'info'
						},
						{
							DateAdded: 1683578551601,
							SK: '2387330248400-XaYni11s6PFDVys3PWXm1h',
							Action: 'error',
							Type: 'instance',
							Info: {
								fileName: '20230508T164059813690.pdf',
								filePath: 'TC_Test/05-03',
								nodeName: 'WebDAV Connector'
							},
							MsgKey: 'fileUploadFailOnInsufficientStorage',
							LogLevel: 'error'
						},
						{
							DateAdded: 1683578551448,
							SK: '2387330248553-MwsXgc2Q5P42C4ozsbqsH2',
							Action: 'start',
							Type: 'instance',
							Info: {
								fileName: '20230508T164059813690.pdf',
								filePath: 'root/TC_Test/05-03',
								nodeName: 'WebDAV Connector'
							},
							MsgKey: 'fileUploadStart',
							LogLevel: 'info'
						},
						{
							DateAdded: 1683578520743,
							SK: '2387330279257-EdGogaB5grLwcn6GbSRFWW',
							Action: 'added',
							Type: 'instance',
							Info: {
								arn: 'c65d03e6-4546-4f33-9a2d-fe09e820c0b5',
								filename: '20230508T164059813690.pdf',
								size: 2725,
								workflowName: 'Issue_78'
							},
							MsgKey: 'fileAdded',
							LogLevel: 'info'
						},
						{
							DateAdded: 1683577752000,
							SK: '2387331048000-1ipseq7EpZdjPSbyiAnM7b',
							Action: 'start',
							Type: 'workflow',
							Info: {
								status: 'running',
								user: {
									name: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
									email: 'qa@kmbs.konicaminolta.us',
									id: '6f436e4e-564a-4227-a5a1-fedaccc8f075'
								},
								workflow: {
									id: workflowId,
									name: 'Issue_78',
									status: 'running'
								}
							},
							MsgKey: 'wfxUpdateStatus',
							LogLevel: 'info'
						},
						{
							DateAdded: 1683577746991,
							SK: '2387331053009-W3ph2WNWnFYR9K1uUBsK18',
							Action: 'checkin',
							Type: 'workflow',
							Info: {
								user: {
									name: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
									email: 'qa@kmbs.konicaminolta.us',
									id: '6f436e4e-564a-4227-a5a1-fedaccc8f075'
								},
								workflow: {
									id: workflowId,
									name: 'Issue_78',
									status: 'stopped'
								}
							},
							MsgKey: 'wfxCheckIn',
							LogLevel: 'info'
						},
						{
							DateAdded: 1683577628303,
							SK: '2387331171697-KzuCwVcrXCuWNJnZSHsk84',
							Action: 'checkout',
							Type: 'workflow',
							Info: {
								user: {
									name: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
									email: 'qa@kmbs.konicaminolta.us',
									id: '6f436e4e-564a-4227-a5a1-fedaccc8f075'
								},
								workflow: {
									id: workflowId,
									name: 'Issue_78',
									status: 'stopped'
								}
							},
							MsgKey: 'wfxCheckOut',
							LogLevel: 'info'
						},
						{
							DateAdded: 1683577618111,
							SK: '2387331181889-1WbUraJiW8WMjTMdnusU3a',
							Action: 'stop',
							Type: 'workflow',
							Info: {
								status: 'stopped',
								user: {
									name: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
									email: 'qa@kmbs.konicaminolta.us',
									id: '6f436e4e-564a-4227-a5a1-fedaccc8f075'
								},
								workflow: {
									id: workflowId,
									name: 'Issue_78',
									status: 'stopped'
								}
							},
							MsgKey: 'wfxUpdateStatus',
							LogLevel: 'info'
						},
						{
							DateAdded: 1683576985830,
							SK: '2387331814170-4qoxg2aWxKDtHydcodCBCx',
							Action: 'error',
							Type: 'instance',
							Info: {
								fileName: '20230508T161502928775.pdf',
								filePath: 'Skaur',
								nodeName: 'WebDAV Connector'
							},
							MsgKey: 'fileUploadFailOnInsufficientStorage',
							LogLevel: 'error'
						},
						{
							DateAdded: 1683576985662,
							SK: '2387331814338-2djLmAe7Hhd773JA3CKGwJ',
							Action: 'start',
							Type: 'instance',
							Info: {
								fileName: '20230508T161502928775.pdf',
								filePath: 'root/Skaur',
								nodeName: 'WebDAV Connector'
							},
							MsgKey: 'fileUploadStart',
							LogLevel: 'info'
						},
						{
							DateAdded: 1683576983794,
							SK: '2387331816206-YCfS6XqKeMzyn9a9Qwu9Hi',
							Action: 'error',
							Type: 'instance',
							Info: {
								fileName: '20230508T161502928775.pdf',
								filePath: 'TC_Test/05-03',
								nodeName: 'WebDAV Connector'
							},
							MsgKey: 'fileUploadFailOnInsufficientStorage',
							LogLevel: 'error'
						},
						{
							DateAdded: 1683576983623,
							SK: '2387331816378-84E19kP9FTknZ6x7qWVc4k',
							Action: 'start',
							Type: 'instance',
							Info: {
								fileName: '20230508T161502928775.pdf',
								filePath: 'root/TC_Test/05-03',
								nodeName: 'WebDAV Connector'
							},
							MsgKey: 'fileUploadStart',
							LogLevel: 'info'
						},
						{
							DateAdded: 1683576963629,
							SK: '2387331836371-SD1CGNQRDcUVhNsMgCMqes',
							Action: 'added',
							Type: 'instance',
							Info: {
								arn: '1b0e0290-0084-45a8-b210-929a8303210c',
								filename: '20230508T161502928775.pdf',
								size: 2613,
								workflowName: 'Issue_78'
							},
							MsgKey: 'fileAdded',
							LogLevel: 'info'
						},
						{
							DateAdded: 1683576915637,
							SK: '2387331884363-8zFerDFpyfUn5a4EdmVvc7',
							Action: 'start',
							Type: 'workflow',
							Info: {
								status: 'running',
								user: {
									name: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
									email: 'qa@kmbs.konicaminolta.us',
									id: '6f436e4e-564a-4227-a5a1-fedaccc8f075'
								},
								workflow: {
									id: workflowId,
									name: 'Issue_78',
									status: 'running'
								}
							},
							MsgKey: 'wfxUpdateStatus',
							LogLevel: 'info'
						},
						{
							DateAdded: 1683576911617,
							SK: '2387331888383-Er5iAzCz2zkbPZzTtxnF8Q',
							Action: 'checkin',
							Type: 'workflow',
							Info: {
								user: {
									name: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
									email: 'qa@kmbs.konicaminolta.us',
									id: '6f436e4e-564a-4227-a5a1-fedaccc8f075'
								},
								workflow: {
									id: workflowId,
									name: 'Issue_78',
									status: 'stopped'
								}
							},
							MsgKey: 'wfxCheckIn',
							LogLevel: 'info'
						},
						{
							DateAdded: 1683576885401,
							SK: '2387331914599-TpgXowSXt8JioL8CcKocv6',
							Action: 'checkout',
							Type: 'workflow',
							Info: {
								user: {
									name: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
									email: 'qa@kmbs.konicaminolta.us',
									id: '6f436e4e-564a-4227-a5a1-fedaccc8f075'
								},
								workflow: {
									id: workflowId,
									name: 'Issue_78',
									status: 'stopped'
								}
							},
							MsgKey: 'wfxCheckOut',
							LogLevel: 'info'
						},
						{
							DateAdded: 1683576882887,
							SK: '2387331917113-LQst81pesyswvzSiWpwTnD',
							Action: 'stop',
							Type: 'workflow',
							Info: {
								status: 'stopped',
								user: {
									name: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
									email: 'qa@kmbs.konicaminolta.us',
									id: '6f436e4e-564a-4227-a5a1-fedaccc8f075'
								},
								workflow: {
									id: workflowId,
									name: 'Issue_78',
									status: 'stopped'
								}
							},
							MsgKey: 'wfxUpdateStatus',
							LogLevel: 'info'
						},
						{
							DateAdded: 1683576870562,
							SK: '2387331929438-SRuusgvFsEcbc7LffXxWrm',
							Action: 'start',
							Type: 'workflow',
							Info: {
								status: 'running',
								user: {
									name: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
									email: 'qa@kmbs.konicaminolta.us',
									id: '6f436e4e-564a-4227-a5a1-fedaccc8f075'
								},
								workflow: {
									id: workflowId,
									name: 'Issue_78',
									status: 'running'
								}
							},
							MsgKey: 'wfxUpdateStatus',
							LogLevel: 'info'
						},
						{
							DateAdded: 1683576857909,
							SK: '2387331942092-31o6qMvUs7ZYzWQwje3Uua',
							Action: 'checkin',
							Type: 'workflow',
							Info: {
								user: {
									name: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
									email: 'qa@kmbs.konicaminolta.us',
									id: '6f436e4e-564a-4227-a5a1-fedaccc8f075'
								},
								workflow: {
									id: workflowId,
									name: 'Issue_78',
									status: 'stopped'
								}
							},
							MsgKey: 'wfxCheckIn',
							LogLevel: 'info'
						}
					],
					Count: 23,
					qs: [null]
				}
			];
		});

		mock.onGet('/api/mp/catalog/pricing?region=US').reply(200, {
			region: 'US',
			currencyCode: 'USD',
			pricing: {
				// '009EC905-B759-41E8-943E-C9B6913E18F8': {
				// 	publicId: '009EC905-B759-41E8-943E-C9B6913E18F8',
				// 	name: 'Personalize Service - Starter Plan',
				// 	showPrice: true,
				// 	priceRanges: [
				// 		{
				// 			sapId: 'PS001M1',
				// 			amount: 0,
				// 			min: 1
				// 		}
				// 	],
				// 	billing: {
				// 		name: 'One Time Fee',
				// 		uid: 'bonce',
				// 		duration: 0,
				// 		months: false,
				// 		gracePeriod: 0
				// 	}
				// },
				[PublicId.StarterMonthly]: {
					publicId: PublicId.StarterMonthly,
					name: 'Dispatcher ScanTrip Cloud Service - Starter Plan (per device, monthly)',
					showPrice: true,
					priceRanges: [
						{ sapId: 'SGAAA11', amount: 2.5, min: 1 },
						{ sapId: 'SGAAA12', amount: 1.5, min: 11 },
						{ sapId: 'SGAAA13', amount: 1.3, min: 50 },
						{ sapId: 'SGAAA14', amount: 1.1, min: 250 },
						{ sapId: 'SGAAA15', amount: 0.8, min: 1000 }
					],
					billing: {
						name: 'Monthly',
						uid: 'bmonth',
						duration: 1,
						months: true,
						gracePeriod: 0
					},
					counter: {
						min: 10,
						max: 1000000,
						step: 1,
						lock: false
					}
				},
				[PublicId.BusinessMonthly]: {
					publicId: PublicId.BusinessMonthly,
					name: 'Dispatcher ScanTrip Cloud Service - Business Plan (per device, monthly)',
					showPrice: true,
					priceRanges: [
						{ sapId: 'SGAAA06', amount: 2.75, min: 1 },
						{ sapId: 'SGAAA07', amount: 1.65, min: 11 },
						{ sapId: 'SGAAA08', amount: 1.43, min: 50 },
						{ sapId: 'SGAAA09', amount: 1.21, min: 250 },
						{ sapId: 'SGAAA10', amount: 0.88, min: 1000 }
					],
					billing: {
						name: 'Monthly',
						uid: 'bmonth',
						duration: 1,
						months: true,
						gracePeriod: 0
					},
					counter: {
						min: 10,
						max: 1000000,
						step: 1,
						lock: false
					}
				},
				[PublicId.EnterpriseMonthly]: {
					publicId: PublicId.EnterpriseMonthly,
					name: 'Dispatcher ScanTrip Cloud Service - Enterprise Plan (per device, monthly)',
					showPrice: true,
					priceRanges: [
						{ sapId: 'SGAAA01', amount: 3, min: 1 },
						{ sapId: 'SGAAA02', amount: 1.8, min: 11 },
						{ sapId: 'SGAAA03', amount: 1.56, min: 50 },
						{ sapId: 'SGAAA04', amount: 1.32, min: 250 },
						{ sapId: 'SGAAA05', amount: 0.96, min: 1000 }
					],
					billing: {
						name: 'Monthly',
						uid: 'bmonth',
						duration: 1,
						months: true,
						gracePeriod: 0
					},
					counter: {
						min: 10,
						max: 1000000,
						step: 1,
						lock: false
					}
				},
				[PublicId.StarterYearlyTerm]: {
					publicId: PublicId.StarterYearlyTerm,
					name: 'Dispatcher ScanTrip Cloud Service - Starter Plan (per device, 1-year term)',
					showPrice: true,
					priceRanges: [
						{ sapId: 'SGAAA16', amount: 42, min: 1 },
						{ sapId: 'SGAAA17', amount: 30, min: 11 },
						{ sapId: 'SGAAA18', amount: 27, min: 50 },
						{ sapId: 'SGAAA19', amount: 25.2, min: 250 },
						{ sapId: 'SGAAA20', amount: 21.6, min: 1000 }
					],
					billing: {
						name: 'One Time Fee',
						uid: 'bonce',
						duration: 0,
						months: false,
						gracePeriod: 0
					},
					counter: {
						min: 10,
						max: 1000000,
						step: 1,
						lock: false
					}
				},
				[PublicId.BusinessYearlyTerm]: {
					publicId: PublicId.BusinessYearlyTerm,
					name: 'Dispatcher ScanTrip Cloud Service - Business Plan (per device, 1-year term)',
					showPrice: true,
					priceRanges: [
						{ sapId: 'SGAAA21', amount: 45, min: 1 },
						{ sapId: 'SGAAA22', amount: 31.8, min: 11 },
						{ sapId: 'SGAAA23', amount: 29.16, min: 50 },
						{ sapId: 'SGAAA24', amount: 26.52, min: 250 },
						{ sapId: 'SGAAA25', amount: 22.56, min: 1000 }
					],
					billing: {
						name: 'One Time Fee',
						uid: 'bonce',
						duration: 0,
						months: false,
						gracePeriod: 0
					},
					counter: {
						min: 10,
						max: 1000000,
						step: 1,
						lock: false
					}
				},
				[PublicId.EnterpriseYearlyTerm]: {
					publicId: PublicId.EnterpriseYearlyTerm,
					name: 'Dispatcher ScanTrip Cloud Service - Enterprise Plan (per device, 1-year term)',
					showPrice: true,
					priceRanges: [
						{ sapId: 'SGAAA26', amount: 48, min: 1 },
						{ sapId: 'SGAAA27', amount: 33.6, min: 11 },
						{ sapId: 'SGAAA28', amount: 30.72, min: 50 },
						{ sapId: 'SGAAA29', amount: 27.84, min: 250 },
						{ sapId: 'SGAAA30', amount: 23.52, min: 1000 }
					],
					billing: {
						name: 'One Time Fee',
						uid: 'bonce',
						duration: 0,
						months: false,
						gracePeriod: 0
					},
					counter: {
						min: 10,
						max: 1000000,
						step: 1,
						lock: false
					}
				},
				[PublicId.StarterYearly]: {
					publicId: PublicId.StarterYearly,
					name: 'Dispatcher ScanTrip Cloud Service - Starter Plan (per device, yearly)',
					showPrice: true,
					priceRanges: [
						{ sapId: 'FAKEA16', amount: 42, min: 1 },
						{ sapId: 'FAKEA17', amount: 30, min: 11 },
						{ sapId: 'FAKEA18', amount: 27, min: 50 },
						{ sapId: 'FAKEA19', amount: 25.2, min: 250 },
						{ sapId: 'FAKEA20', amount: 21.6, min: 1000 }
					],
					billing: {
						name: 'Yearly',
						uid: 'byear',
						duration: 12,
						months: true,
						gracePeriod: 0
					},
					counter: {
						min: 10,
						max: 100,
						step: 1,
						lock: false
					}
				},
				[PublicId.BusinessYearly]: {
					publicId: PublicId.BusinessYearly,
					name: 'Dispatcher ScanTrip Cloud Service - Business Plan (per device, yearly)',
					showPrice: true,
					priceRanges: [
						{ sapId: 'FAKEA21', amount: 45, min: 1 },
						{ sapId: 'FAKEA22', amount: 31.8, min: 11 },
						{ sapId: 'FAKEA23', amount: 29.16, min: 50 },
						{ sapId: 'FAKEA24', amount: 26.52, min: 250 },
						{ sapId: 'FAKEA25', amount: 22.56, min: 1000 }
					],
					billing: {
						name: 'Yearly',
						uid: 'byear',
						duration: 12,
						months: true,
						gracePeriod: 0
					},
					counter: {
						min: 10,
						max: 100,
						step: 1,
						lock: false
					}
				},
				[PublicId.EnterpriseYearly]: {
					publicId: PublicId.EnterpriseYearly,
					name: 'Dispatcher ScanTrip Cloud Service - Enterprise Plan (per device, yearly)',
					showPrice: true,
					priceRanges: [
						{ sapId: 'FAKEA26', amount: 48, min: 1 },
						{ sapId: 'FAKEA27', amount: 33.6, min: 11 },
						{ sapId: 'FAKEA28', amount: 30.72, min: 50 },
						{ sapId: 'FAKEA29', amount: 27.84, min: 250 },
						{ sapId: 'FAKEA30', amount: 23.52, min: 1000 }
					],
					billing: {
						name: 'Yearly',
						uid: 'byear',
						duration: 12,
						months: true,
						gracePeriod: 0
					},
					counter: {
						min: 10,
						max: 100,
						step: 1,
						lock: false
					}
				}
				// [PublicId.DeviceApp]: {
				// 	publicId: PublicId.DeviceApp,
				// 	name: 'Dispatcher ScanTrip Cloud Agent',
				// 	showPrice: true,
				// 	priceRanges: [
				// 		{
				// 			sapId: 'moar',
				// 			amount: 0,
				// 			min: 1
				// 		}
				// 	],
				// 	billing: {
				// 		name: 'One Time Fee',
				// 		uid: 'bonce',
				// 		duration: 0,
				// 		months: false,
				// 		gracePeriod: 0
				// 	},
				// 	counter: {
				// 		min: 1,
				// 		max: 1,
				// 		step: 1,
				// 		lock: true
				// 	}
				// }
			}
		});

		const metadataApiUrl = getMetadataUrl(user.awsRegion);
		mock.onPut(`${metadataApiUrl}/api/v1/user/${user.id}`).reply(200);

		const formsApiUrl = getFormsUrl(user.awsRegion);
		mock.onGet(`${formsApiUrl}/api/groups`).reply(200, [
			{
				id: '4976d6b2fccd40ce9b825c1eb12a5434',
				title: 'form-group-2',
				sortOrder: 2
			},
			{
				id: 'eabdc55208d842619bccb6397680930a',
				title: 'group-2',
				sortOrder: 3
			}
		]);

		mock.onGet(`${formsApiUrl}/api/forms`).reply(200, [
			{
				id: '1a2b3c4d5e6f7g8h',
				title: 'Project X',
				creator: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
				editor: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
				valid: true,
				created: 1696000000000,
				updated: 1696100000000,
				formStatus: 'published',
				formGroup: {
					id: '00000'
				}
			},
			{
				id: '2b3c4d5e6f7g8h9i',
				title: 'Task Management 2',
				creator: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
				editor: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
				valid: false,
				created: 1696100000000,
				updated: 1696200000000,
				formStatus: 'draft',
				formGroup: {
					id: '00000'
				}
			},
			{
				id: '3c4d5e6f7g8h9i0j',
				title: 'Product Launch 3',
				creator: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
				editor: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
				valid: true,
				created: 1696200000000,
				updated: 1696300000000,
				formStatus: 'published',
				formGroup: {
					id: '00000'
				}
			},
			{
				id: '4d5e6f7g8h9i0j1k',
				title: 'Meeting Notes 4',
				creator: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
				editor: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
				valid: true,
				created: 1696300000000,
				updated: 1696400000000,
				formStatus: 'published',
				formGroup: {
					id: '00000'
				}
			},
			{
				id: '5e6f7g8h9i0j1k2l',
				title: 'Expense Report 5',
				creator: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
				editor: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
				valid: true,
				created: 1696400000000,
				updated: 1696500000000,
				formStatus: 'published',
				formGroup: {
					id: '00000'
				}
			},
			{
				id: '6f7g8h9i0j1k2l3m',
				title: 'Project Y',
				creator: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
				editor: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
				valid: false,
				created: 1696500000000,
				updated: 1696600000000,
				formStatus: 'draft',
				formGroup: {
					id: '00000'
				}
			},
			{
				id: '7g8h9i0j1k2l3m4n',
				title: 'Survey Results 7',
				creator: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
				editor: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
				valid: true,
				created: 1696600000000,
				updated: 1696700000000,
				formStatus: 'published',
				formGroup: {
					id: '00000'
				}
			},
			{
				id: '8h9i0j1k2l3m4n5o',
				title: 'Marketing Campaign 8',
				creator: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
				editor: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
				valid: true,
				created: 1696700000000,
				updated: 1696800000000,
				formStatus: 'published',
				formGroup: {
					id: '00000'
				}
			},
			{
				id: '3c58f69b470f42178b77d9249e2f3989',
				title: 'nmassey',
				creator: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
				editor: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
				valid: true,
				created: 1695312048179,
				updated: 1695312065423,
				formStatus: 'published',
				formGroup: {
					id: '00000'
				}
			},
			{
				id: 'b555c9e7f8fa41dfbc5a79683e22dd43',
				title: 'Test 1',
				creator: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
				editor: 'KonicaMinoltaMarketPlace_f4c9b1c3db8f093fd72f4f67febf1f951c25d0c2',
				valid: true,
				created: 1694832174280,
				updated: 1694832778817,
				formStatus: 'published',
				formGroup: {
					id: '00000'
				}
			}
		]);
	}

	const mockRegion = localStorage.getItem('mockRegion') ?? (localStorage.getItem('mock') ? 'US' : undefined);
	if (mockRegion) {
		mock.onGet('https://static.getpersonalize.com/geolocation').reply(200, mockRegion);
	}

	mock.onPatch(`/api/v1/tenant/${selectedLicenseGroup.id}/settings`).reply(200);
	mock.onPatch(`/api/v1/tenant/${selectedLicenseGroup.id}/copy`).reply(200);
	mock.onPatch(`/api/v1/tenant/${selectedLicenseGroup.id}/upgrade-demo-license`).reply(config => {
		const { orderNumber } = JSON.parse(config.data);
		// Odd/even check to simulate success/failure, until we have a real API
		if (orderNumber % 2 === 0) {
			return [200, { message: 'Success: (orderNumber is even)' }];
		}
		return [400, { message: 'Error: orderNumber is not even' }];
	});

	mock.onPost('/api/v1/invitation').reply(200, {
		invitationId: 'mock-invitation-id'
	});

	mock.onPost('/api/v1/invitation/mock-invitation-id/link').reply(200, {
		transactionId: 'mock-transaction-id'
	});

	mock.onPatch(/\/api\/v1\/user\/[^/]+/).reply(200, []);

	mock.onPost(/\/api\/v1\/user/).reply(200, []);

	mock.onPatch(/\/api\/v1\/devicegroup\/[^/]+\/[^/]+/).reply(200, []);

	mock.onPatch(/\/api\/v1\/device\/[^/]+\/[^/]+/).reply(200, []);

	mock.onPatch('/api/license-groups/mock-admin-invite/devices').reply(200);

	mock.onPost(`/api/v1/tenant/${selectedLicenseGroup.id}/registration`).reply(200, []);

	mock.onGet('/api/v1/user/changeMeId').reply(200, users);

	mock.onGet(/\/api\/v1\/workflow\/[^/]+\/[^/]+\/logs/).reply(200, [
		{
			id: 'log-w-1',
			type: 'workflow',
			severity: 'low',
			dateAdded: 1646110800000,
			event: 'Workflow submitted',
			msgKey: 'Check in',
			info: {
				deviceId: 'A112233440001',
				deviceName: '558'
			}
		},
		{
			id: 'log-w-2',
			type: 'workflow',
			severity: 'low',
			dateAdded: 1646110600000,
			event: 'Workflow stopped',
			msgKey: 'Check in',
			info: {
				deviceId: 'A112233440001',
				deviceName: '558'
			}
		},
		{
			id: 'log-w-3',
			type: 'workflow',
			severity: 'low',
			dateAdded: 1646110400000,
			event: 'Workflow edited',
			msgKey: 'Check in',
			info: {
				deviceId: 'A112233440001',
				deviceName: '558'
			}
		},
		{
			id: 'log-w-4',
			type: 'workflow',
			severity: 'low',
			dateAdded: 1646110200000,
			event: 'Workflow started',
			msgKey: 'Check in',
			info: {
				deviceId: 'A112233440001',
				deviceName: '558'
			}
		}
	]);

	if (localStorage.getItem('mockUser')) {
		mock.onGet('/api/sso/session').reply(200, {
			user
		});
		// mock.onGet('/api/sso/session').reply(401, null);
	}

	// mock.onGet('/api/v1/user/changeMeId/mock-user-id/devices').reply(200, [
	// mock.onGet(/\/api\/v1\/user\/changeMeId\/[^/]+\/devices/).reply(200, _.keyBy(mpDevices, 'id'));

	// mock.onGet('/api/v1/user/changeMeId/mock-user-id/devices').reply(200, [
	mock.onGet(/\/api\/v1\/user\/changeMeId\/[^/]+\/forms/).reply(200, [
		{
			id: 'mock-mp-form-guid-1',
			name: 'C558',
			family: 'ZeusSBK',
			generation: 'IT5',
			productLine: 'bizhub',
			serial: 'mock-mp-form-guid-1',
			friendlyName: 'Second Floor - Dev'
		},
		{
			id: 'mock-mp-form-guid-2',
			name: 'C556',
			family: 'ZeusSBK',
			generation: 'IT5',
			productLine: 'bizhub',
			serial: 'mock-mp-form-guid-2',
			friendlyName: 'Second Floor - QA'
		},
		{
			id: 'mock-mp-form-guid-3',
			name: 'C554',
			family: 'VenusMLK',
			generation: 'IT5',
			productLine: 'bizhub',
			serial: 'mock-mp-form-guid-3',
			friendlyName: 'Second Floor - Lab'
		},
		{
			id: 'mock-mp-form-guid-4',
			name: 'C358',
			family: 'Sparrow',
			generation: 'IT6',
			productLine: 'bizhub',
			serial: 'mock-mp-form-guid-4'
		},
		{
			id: 'mock-mp-form-guid-5',
			name: 'C356',
			family: 'Sparrow',
			generation: 'IT6',
			productLine: 'bizhub',
			serial: 'mock-mp-form-guid-5'
		},
		{
			id: 'mock-mp-form-guid-6',
			name: 'C354',
			family: 'Sparrow',
			generation: 'IT6',
			productLine: 'bizhub',
			serial: 'mock-mp-form-guid-6'
		},
		{
			id: 'mock-mp-form-guid-7',
			name: 'C666',
			family: 'Sparrow',
			generation: 'IT6',
			productLine: 'bizhub',
			serial: 'mock-mp-form-guid-7'
		}
	]);

	const alertSubscriptions = [
		{
			id: 'mock-report-device-status-alert-subscription-1',
			logsubId: 'mock-report-device-status-alert-subscription-1',
			userId: user.id,
			type: 'report-device-status',
			severity: '',
			alertEmail: [user.email],
			parameters: {
				reportTime: '03:35',
				assessedNotSecure: true,
				assessedOffline: true,
				notAssessed: true
			},
			alertCell: [],
			createdAt: 1592659304896,
			updatedAt: 1592659304896
		},
		{
			id: 'mock-alert-subscription-1',
			logsubId: 'mock-alert-subscription-1',
			userId: user.id,
			type: 'device',
			severity: 'high',
			alertEmail: [user.email],
			alertCell: [],
			createdAt: 1592659304896,
			updatedAt: 1592659304896
		}
	];

	// api/v1/alert
	mock.onGet(/\/api\/v1\/alert\/changeMeId\/user\/[^/]+\/subscriptions/).reply(
		200,
		_.keyBy(alertSubscriptions, 'id')
	);

	// mock.onPatch('/api/license-groups/changeMeId/users').reply(200, {
	// 	errors: {
	// 		add: [
	// 			{ type: 'already-associated', data: { email: 'fake1@email.com' } },
	// 			{ type: 'already-associated', data: { email: 'fake2@email.com' } }
	// 		],
	// 		delete: []
	// 	}
	// });
	mock.onPatch('/api/license-groups/changeMeId/users').reply(200, {
		errors: { add: [], delete: [] }
	});
	mock.onPatch('/api/license-groups/changeMeId/devices').reply(200, { success: 'true' });
	mock.onPatch('/api/license-groups/changeMeId/settings').reply(200, { success: 'true' });
	// mock.onPatch('/api/license-groups/changeMeId/settings').reply(401, { success: 'true' });
	// mock.onPatch('/api/license-groups/changeMeId/settings').reply(200, {
	// 	errors: [
	// 		{
	// 			output: {
	// 				statusCode: 400
	// 			}
	// 		}
	// 	]
	// });

	if (localStorage.getItem('mock')) {
		mock.onGet(`/api/mp/catalog/pricing/${selectedLicenseGroup.id}?publicId=${PublicId.EnterpriseMonthly}`).reply(
			200,
			{
				total: 5 * 50,
				currencyCode: 'USD'
			}
		);

		mock.onGet(
			`/api/mp/catalog/pricing/${selectedLicenseGroup.id}?publicId=${PublicId.EnterpriseMonthly}&quantity=50`
		).reply(200, {
			total: 5 * 50,
			currencyCode: 'USD'
		});

		mock.onGet(
			`/api/mp/catalog/pricing/${selectedLicenseGroup.id}?publicId=${PublicId.EnterpriseMonthly}&quantity=60`
		).reply(200, {
			total: 5 * 60,
			currencyCode: 'USD'
		});

		mock.onGet(`/api/mp/catalog/pricing/${selectedLicenseGroup.id}?publicId=${PublicId.BusinessMonthly}`).reply(
			200,
			{
				total: 4 * 50,
				currencyCode: 'USD'
			}
		);

		mock.onGet(
			`/api/mp/catalog/pricing/${selectedLicenseGroup.id}?publicId=${PublicId.BusinessMonthly}&quantity=50`
		).reply(200, {
			total: 4 * 50,
			currencyCode: 'USD'
		});

		mock.onGet(
			`/api/mp/catalog/pricing/${selectedLicenseGroup.id}?publicId=${PublicId.BusinessMonthly}&quantity=60`
		).reply(200, {
			total: 4 * 60,
			currencyCode: 'USD'
		});

		mock.onGet(`/api/mp/catalog/pricing/${selectedLicenseGroup.id}?publicId=${PublicId.StarterMonthly}`).reply(
			200,
			{
				total: 3 * 50,
				currencyCode: 'USD'
			}
		);

		mock.onGet(
			`/api/mp/catalog/pricing/${selectedLicenseGroup.id}?publicId=${PublicId.StarterMonthly}&quantity=50`
		).reply(200, {
			total: 3 * 50,
			currencyCode: 'USD'
		});

		mock.onGet(
			`/api/mp/catalog/pricing/${selectedLicenseGroup.id}?publicId=${PublicId.StarterMonthly}&quantity=60`
		).reply(200, {
			total: 3 * 60,
			currencyCode: 'USD'
		});

		mock.onPatch(/\/api\/tenants\/[^/]+\/configure/).reply(200);
	}

	mock.onGet('/api/user/functions').reply(200, {
		static: {
			w00000001: { name: 'Copy', class: 'icn-copy' },
			w00000002: { name: 'Scan To Email', class: 'icn-email' },
			w00000003: { name: 'Scan To Box', class: 'icn-box' },
			w00000004: { name: 'Scan To PC', class: 'icn-scanpc' },
			w00000005: { name: 'Internet Fax', class: 'icn-inetfax' },
			w00000006: { name: 'IP Address Fax', class: 'icn-ipfax' },
			w00000007: { name: 'Fax', class: 'icn-fax' },
			w00000016: { name: 'IP Fax (SIP)', class: 'icn-ipaddrfax' },
			w00000017: { name: 'Quick Copy', class: 'icn-quick-copy' },
			w00000101: { name: 'Web Browser', class: 'icn-web' },
			w00000102: {
				name: 'MarketPlace',
				class: 'icn-mpinstall'
			}, // pre-install.
			w00000201: { name: 'Address Book', class: 'icn-address' },
			w00000202: { name: 'USB Mem. Print', class: 'icn-usb' },
			w00000203: { name: 'Classic Style', class: 'icn-classic' },
			w00000204: { name: 'Classic Copy', class: 'icn-classic-copy' },
			w00000205: { name: 'Classic Scan', class: 'icn-classic-scan' },
			w00000206: { name: 'Classic Fax', class: 'icn-classic-fax' },
			w00000207: { name: 'Box', class: 'icn-classic-box' },
			w00000208: { name: 'Serverless Pull Printing', skip: true },
			w00000301: { name: 'ID & Print', class: 'icn-idprint' },
			w00000302: { name: 'Paper', class: 'icn-paper' },
			w00000303: { name: 'Card Shot', class: 'icn-idcard' },
			w00000304: { name: 'Scan to Me', class: 'icn-scanme' },
			w00000401: { name: 'APP (OpenAPI)', class: 'icn-app' }
		}
	});

	mock.onGet('/api/reporting/license-group-usage?from=2020-11-01&to=2020-11-10&grp=device&id=changeMeId').reply(200, [
		{ device_name: 'Alpha Ronald 100', device_id: '13fhhi70ehf', users_using_ct: '11230', auth_count: '3133' },
		{ device_name: 'Bravo', device_id: '241eih11499', users_using_ct: '1340', auth_count: '3203' },
		{ device_name: 'Charlie', device_id: '30jgij44b5d', users_using_ct: '140', auth_count: '3138' },
		{ device_name: 'Delta', device_id: '4fcech302hc', users_using_ct: '104', auth_count: '3281' },
		{ device_name: 'Elos', device_id: '5fifbha1e53', users_using_ct: '1044', auth_count: '3295' }
	]);

	mock.onGet('/api/reporting/license-group-usage?from=2020-11-09&to=2020-11-10&grp=device&id=changeMeId').reply(200, [
		{ device_name: 'Alpha Ronald 10', device_id: '13fhhi70ehf', users_using_ct: '11230', auth_count: '3133' },
		{ device_name: 'Bravo', device_id: '241eih11499', users_using_ct: '1340', auth_count: '3203' },
		{ device_name: 'Charlie', device_id: '30jgij44b5d', users_using_ct: '140', auth_count: '3138' },
		{ device_name: 'Delta', device_id: '4fcech302hc', users_using_ct: '104', auth_count: '3281' },
		{ device_name: 'Elos', device_id: '5fifbha1e53', users_using_ct: '1044', auth_count: '3295' }
	]);

	mock.onGet('/api/reporting/license-group-usage?from=2020-11-01&to=2020-11-10&grp=user&id=changeMeId').reply(200, [
		{
			user_name: 'alice',
			user_id: '001b5d8815gif5290900ahjg081i1aac5bdhf3d7',
			devices_used_ct: '005',
			auth_count: '1604'
		},
		{
			user_name: 'ben',
			user_id: '002b5d8815gif5290900ahjg081i1aac5bdhf3d7',
			devices_used_ct: '123125',
			auth_count: '1659'
		},
		{
			user_name: 'casay',
			user_id: '003b5d80ahjg08815gif5290901i1aac5bdhf3d7',
			devices_used_ct: '655',
			auth_count: '1545'
		},
		{
			user_name: 'derek',
			user_id: '004b5d8815gif5290900ahjg081i1aac5bdhf3d7',
			devices_used_ct: '45',
			auth_count: '1516'
		},
		{
			user_name: 'evan',
			user_id: '005b5d8815gif5290900ahjg081i1aac5bdhf3d7',
			devices_used_ct: '335',
			auth_count: '1639'
		},
		{
			user_name: 'frank',
			user_id: '006b5d8815gif5290900ahjg081i1aac5bdhf3d7',
			devices_used_ct: '15',
			auth_count: '1578'
		},
		{
			user_name: 'george',
			user_id: '007b5d8815gif5290900ahjg081i1aac5bdhf3d7',
			devices_used_ct: '25',
			auth_count: '1635'
		},
		{
			user_name: 'henry',
			user_id: '008b5d8815gif5290900ahjg081i1aac5bdhf3d7',
			devices_used_ct: '56',
			auth_count: '1585'
		},
		{
			user_name: 'issac',
			user_id: '009b5d8815gif5290900ahjg081i1aac5bdhf3d7',
			devices_used_ct: '54',
			auth_count: '1639'
		},
		{
			user_name: 'jerry',
			user_id: '010b5d8815gif5290900ahjg081i1aac5bdhf3d7',
			devices_used_ct: '51',
			auth_count: '1650'
		}
	]);

	mock.onGet('/api/reporting/license-group-usage?from=2020-11-09&to=2020-11-10&grp=user&id=changeMeId').reply(200, [
		{
			user_name: 'alice1',
			user_id: '001b5d8815gif5290900ahjg081i1aac5bdhf3d7',
			devices_used_ct: '05',
			auth_count: '164'
		},
		{
			user_name: 'ben2',
			user_id: '002b5d8815gif5290900ahjg081i1aac5bdhf3d7',
			devices_used_ct: '5',
			auth_count: '1659'
		},
		{
			user_name: 'casay',
			user_id: '003b5d80ahjg08815gif5290901i1aac5bdhf3d7',
			devices_used_ct: '5',
			auth_count: '155'
		},
		{
			user_name: 'derek1',
			user_id: '004b5d8815gif5290900ahjg081i1aac5bdhf3d7',
			devices_used_ct: '5',
			auth_count: '16'
		},
		{
			user_name: 'evan',
			user_id: '005b5d8815gif5290900ahjg081i1aac5bdhf3d7',
			devices_used_ct: '5',
			auth_count: '5'
		},
		{
			user_name: 'frank',
			user_id: '006b5d8815gif5290900ahjg081i1aac5bdhf3d7',
			devices_used_ct: '15',
			auth_count: '78'
		},
		{
			user_name: 'george',
			user_id: '007b5d8815gif5290900ahjg081i1aac5bdhf3d7',
			devices_used_ct: '25',
			auth_count: '35'
		},
		{
			user_name: 'henry',
			user_id: '008b5d8815gif5290900ahjg081i1aac5bdhf3d7',
			devices_used_ct: '56',
			auth_count: '1585'
		},
		{
			user_name: 'issac',
			user_id: '009b5d8815gif5290900ahjg081i1aac5bdhf3d7',
			devices_used_ct: '54',
			auth_count: '1639'
		},
		{
			user_name: 'jerry',
			user_id: '010b5d8815gif5290900ahjg081i1aac5bdhf3d7',
			devices_used_ct: '51',
			auth_count: '1650'
		}
	]);

	mock.onGet('/api/reporting/license-group-usage?from=2020-11-01&to=2020-11-10&grp=user&by=week&id=changeMeId').reply(
		200,
		[
			{
				user_name: 'alice',
				user_id: '001b5d8815gif5290900ahjg081i1aac5bdhf3d7',
				devices_used_ct: '5',
				auth_count: '14',
				by_week: '2020-10-19T00:00:00.000Z'
			},
			{
				user_name: 'alice',
				user_id: '001b5d8815gif5290900ahjg081i1aac5bdhf3d7',
				devices_used_ct: '5',
				auth_count: '12',
				by_week: '2020-10-26T00:00:00.000Z'
			},
			{
				user_name: 'ben',
				user_id: '002b5d8815gif5290900ahjg081i1aac5bdhf3d7',
				devices_used_ct: '5',
				auth_count: '19',
				by_week: '2020-10-19T00:00:00.000Z'
			},
			{
				user_name: 'ben',
				user_id: '002b5d8815gif5290900ahjg081i1aac5bdhf3d7',
				devices_used_ct: '5',
				auth_count: '10',
				by_week: '2020-10-26T00:00:00.000Z'
			},
			{
				user_name: 'casay',
				user_id: '003b5d8815gif5290900ahjg081i1aac5bdhf3d7',
				devices_used_ct: '5',
				auth_count: '131',
				by_week: '2020-10-19T00:00:00.000Z'
			},
			{
				user_name: 'casay',
				user_id: '003b5d8815gif5290900ahjg081i1aac5bdhf3d7',
				devices_used_ct: '5',
				auth_count: '14',
				by_week: '2020-10-26T00:00:00.000Z'
			},
			{
				user_name: 'derek',
				user_id: '004b5d8815gif5290900ahjg081i1aac5bdhf3d7',
				devices_used_ct: '5',
				auth_count: '16',
				by_week: '2020-10-19T00:00:00.000Z'
			},
			{
				user_name: 'derek',
				user_id: '004b5d8815gif5290900ahjg081i1aac5bdhf3d7',
				devices_used_ct: '5',
				auth_count: '10',
				by_week: '2020-10-26T00:00:00.000Z'
			},
			{
				user_name: 'evan',
				user_id: '005b5d8815gif5290900ahjg081i1aac5bdhf3d7',
				devices_used_ct: '5',
				auth_count: '11',
				by_week: '2020-10-19T00:00:00.000Z'
			},
			{
				user_name: 'evan',
				user_id: '005b5d8815gif5290900ahjg081i1aac5bdhf3d7',
				devices_used_ct: '5',
				auth_count: '18',
				by_week: '2020-10-26T00:00:00.000Z'
			},
			{
				user_name: 'frank',
				user_id: '006b5d8815gif5290900ahjg081i1aac5bdhf3d7',
				devices_used_ct: '5',
				auth_count: '46',
				by_week: '2020-10-19T00:00:00.000Z'
			},
			{
				user_name: 'frank',
				user_id: '006b5d8815gif5290900ahjg081i1aac5bdhf3d7',
				devices_used_ct: '5',
				auth_count: '32',
				by_week: '2020-10-26T00:00:00.000Z'
			},
			{
				user_name: 'george',
				user_id: '007b5d8815gif5290900ahjg081i1aac5bdhf3d7',
				devices_used_ct: '5',
				auth_count: '25',
				by_week: '2020-10-19T00:00:00.000Z'
			},
			{
				user_name: 'george',
				user_id: '007b5d8815gif5290900ahjg081i1aac5bdhf3d7',
				devices_used_ct: '5',
				auth_count: '10',
				by_week: '2020-10-26T00:00:00.000Z'
			},
			{
				user_name: 'henry',
				user_id: '008b5d8815gif5290900ahjg081i1aac5bdhf3d7',
				devices_used_ct: '5',
				auth_count: '186',
				by_week: '2020-10-19T00:00:00.000Z'
			},
			{
				user_name: 'henry',
				user_id: '008b5d8815gif5290900ahjg081i1aac5bdhf3d7',
				devices_used_ct: '5',
				auth_count: '9',
				by_week: '2020-10-26T00:00:00.000Z'
			},
			{
				user_name: 'issac',
				user_id: '009b5d8815gif5290900ahjg081i1aac5bdhf3d7',
				devices_used_ct: '5',
				auth_count: '12',
				by_week: '2020-10-19T00:00:00.000Z'
			},
			{
				user_name: 'issac',
				user_id: '009b5d8815gif5290900ahjg081i1aac5bdhf3d7',
				devices_used_ct: '5',
				auth_count: '17',
				by_week: '2020-10-26T00:00:00.000Z'
			},
			{
				user_name: 'jerry',
				user_id: '010b5d8815gif5290900ahjg081i1aac5bdhf3d7',
				devices_used_ct: '5',
				auth_count: '32',
				by_week: '2020-10-19T00:00:00.000Z'
			},
			{
				user_name: 'jerry',
				user_id: '010b5d8815gif5290900ahjg081i1aac5bdhf3d7',
				devices_used_ct: '5',
				auth_count: '1',
				by_week: '2020-10-26T00:00:00.000Z'
			}
		]
	);

	mock.onGet(
		'/api/reporting/license-group-usage?from=2020-11-01&to=2020-11-10&grp=device&by=week&id=changeMeId'
	).reply(200, [
		{
			device_name: 'Alpha',
			device_id: '13fhhi70ehf',
			users_using: '10',
			users_using_ct: '10',
			auth_count: '2883',
			by_week: '2020-10-19T00:00:00.000Z'
		},
		{
			device_name: 'Alpha',
			device_id: '13fhhi70ehf',
			users_using: '10',
			users_using_ct: '10',
			auth_count: '250',
			by_week: '2020-10-26T00:00:00.000Z'
		},
		{
			device_name: 'Bravo',
			device_id: '241eih11499',
			users_using: '10',
			users_using_ct: '10',
			auth_count: '2976',
			by_week: '2020-10-19T00:00:00.000Z'
		},
		{
			device_name: 'Bravo',
			device_id: '241eih11499',
			users_using: '10',
			users_using_ct: '10',
			auth_count: '227',
			by_week: '2020-10-26T00:00:00.000Z'
		},
		{
			device_name: 'Charlie',
			device_id: '30jgij44b5d',
			users_using: '10',
			users_using_ct: '10',
			auth_count: '2899',
			by_week: '2020-10-19T00:00:00.000Z'
		},
		{
			device_name: 'Charlie',
			device_id: '30jgij44b5d',
			users_using: '10',
			users_using_ct: '10',
			auth_count: '239',
			by_week: '2020-10-26T00:00:00.000Z'
		},
		{
			device_name: 'Delta',
			device_id: '4fcech302hc',
			users_using: '10',
			users_using_ct: '10',
			auth_count: '3043',
			by_week: '2020-10-19T00:00:00.000Z'
		},
		{
			device_name: 'Delta',
			device_id: '4fcech302hc',
			users_using: '10',
			users_using_ct: '10',
			auth_count: '238',
			by_week: '2020-10-26T00:00:00.000Z'
		},
		{
			device_name: 'Elos',
			device_id: '5fifbha1e53',
			users_using: '10',
			users_using_ct: '10',
			auth_count: '3049',
			by_week: '2020-10-19T00:00:00.000Z'
		}
	]);

	mock.onGet(
		'/api/reporting/license-group-usage?from=2020-11-09&to=2020-11-10&grp=device&by=day&id=changeMeId'
	).reply(200, [
		{
			device_name: 'Alpha',
			device_id: '13fhhi70ehf',
			users_using: '3',
			users_using_ct: '3',
			auth_count: '283',
			by_day: '2020-10-19T00:00:00.000Z'
		},
		{
			device_name: 'Alpha',
			device_id: '13fhhi70ehf',
			users_using: '10',
			users_using_ct: '10',
			auth_count: '22',
			by_day: '2020-10-26T00:00:00.000Z'
		},
		{
			device_name: 'Bravo',
			device_id: '241eih11499',
			users_using: '3',
			users_using_ct: '3',
			auth_count: '29',
			by_day: '2020-10-19T00:00:00.000Z'
		},
		{
			device_name: 'Bravo',
			device_id: '241eih11499',
			users_using: '10',
			users_using_ct: '10',
			auth_count: '22',
			by_day: '2020-10-26T00:00:00.000Z'
		},
		{
			device_name: 'Charlie',
			device_id: '30jgij44b5d',
			users_using: '1',
			users_using_ct: '1',
			auth_count: '28',
			by_day: '2020-10-19T00:00:00.000Z'
		},
		{
			device_name: 'Charlie',
			device_id: '30jgij44b5d',
			users_using: '3',
			users_using_ct: '3',
			auth_count: '23',
			by_day: '2020-10-26T00:00:00.000Z'
		},
		{
			device_name: 'Delta',
			device_id: '4fcech302hc',
			users_using: '10',
			users_using_ct: '10',
			auth_count: '3043',
			by_day: '2020-10-19T00:00:00.000Z'
		},
		{
			device_name: 'Delta',
			device_id: '4fcech302hc',
			users_using: '10',
			users_using_ct: '10',
			auth_count: '238',
			by_day: '2020-10-26T00:00:00.000Z'
		},
		{
			device_name: 'Elos',
			device_id: '5fifbha1e53',
			users_using: '10',
			users_using_ct: '10',
			auth_count: '3049',
			by_day: '2020-10-19T00:00:00.000Z'
		}
	]);

	mock.onGet(
		'/api/reporting/license-group-usage?from=2020-11-01&to=2020-11-10&grp=device&by=week&devicefilt=13fhhi70ehf&id=changeMeId'
	).reply(200, [
		{
			device_name: 'Alpha',
			device_id: '13fhhi70ehf',
			users_using: '10',
			users_using_ct: '10',
			auth_count: '2883',
			by_week: '2020-10-19T00:00:00.000Z'
		},
		{
			device_name: 'Alpha',
			device_id: '13fhhi70ehf',
			users_using: '10',
			users_using_ct: '10',
			auth_count: '250',
			by_week: '2020-10-26T00:00:00.000Z'
		}
	]);

	mock.onGet(
		'/api/reporting/license-group-usage?from=2020-11-01&to=2020-11-10&grp=device&by=week&devicefilt=241eih11499&id=changeMeId'
	).reply(200, [
		{
			device_name: 'Alpha',
			device_id: '13fhhi70ehf',
			users_using: '14',
			users_using_ct: '11',
			auth_count: '83',
			by_week: '2020-10-01T00:00:00.000Z'
		},
		{
			device_name: 'Alpha',
			device_id: '13fhhi70ehf',
			users_using: '22',
			users_using_ct: '13',
			auth_count: '250',
			by_week: '2020-10-0500:00:00.000Z'
		},
		{
			device_name: 'Alpha',
			device_id: '13fhhi70ehf',
			users_using: '22',
			users_using_ct: '33',
			auth_count: '20',
			by_week: '2020-10-12T00:00:00.000Z'
		},
		{
			device_name: 'Alpha',
			device_id: '13fhhi70ehf',
			users_using: '22',
			users_using_ct: '23',
			auth_count: '10',
			by_week: '2020-10-19T00:00:00.000Z'
		},
		{
			device_name: 'Alpha',
			device_id: '13fhhi70ehf',
			users_using: '22',
			users_using_ct: '3',
			auth_count: '52',
			by_week: '2020-10-26T00:00:00.000Z'
		}
	]);

	mock.onGet('/api/license-policy').reply(200, {
		[PublicId.StarterMonthly]: ['admin-dashboard', 'add-users', 'add-devices'],
		[PublicId.BusinessMonthly]: ['admin-dashboard', 'add-users', 'add-devices', 'panel-editor'],
		[PublicId.EnterpriseMonthly]: [
			'admin-dashboard',
			'add-users',
			'add-devices',
			'panel-editor',
			'assign-devices-by-user',
			'assign-users-by-device',
			'assign-users-by-domain',
			'admin-dashboard-ext',
			'reports'
		]
	});

	mock.onGet(`/api/user/${selectedLicenseGroup.id}/group-order/user`).reply(200, [
		'userGroup-1',
		'userGroup-3',
		'',
		'userGroup-2'
	]);

	mock.onGet(`/api/user/${selectedLicenseGroup.id}/group-order/device`).reply(200, [
		'deviceGroup-8',
		'deviceGroup-1',
		'deviceGroup-3',
		'deviceGroup-4',
		'not-a-real-one',
		'fake-ronald',
		'deviceGroup-9',
		'deviceGroup-6',
		'deviceGroup-7',
		'deviceGroup-8',
		'00000000-4444-4444-4444-000000000000',
		'deviceGroup-5'
	]);

	mock.onGet(`/api/user/${selectedLicenseGroup.id}/group-order/form`).reply(200, [
		'deviceGroup-1',
		'deviceGroup-3',
		'',
		'deviceGroup-2'
	]);

	mock.onGet(`/api/user/${selectedLicenseGroup.id}/group-order/workflow`).reply(200, [
		'userGroup-1',
		'workflowGroup-2',
		'userGroup-3',
		'workflowGroup-1',
		'userGroup-2',
		'workflowGroup-3'
	]);

	// APIs for the customer feedback
	// mock.onPost(`/api/feedback/file`).reply(200, {
	// 	status: 'success',
	// 	key: '9a61ebfec1117533c9243f7ced95aeb3/2023-03-21_15-05.png'
	// });

	// mock.onPost(`/api/feedback/email`).reply(200, {
	// 	status: 'success'
	// });

	// APIs for the contact us form
	// mock.onPost('/api/contact').reply(200, {
	// 	status: 'success'
	// });
	//
	// APIs for the upgrade license
	// mock.onPatch('/api/tenants/${selectedLicenseGroup.id}/upgrade').reply(200, {
	// 	success: true,
	// 	isMoreThanNewCapacity: true,
	// 	unassigned: true
	// });
};

export default mocking;
