import _ from '@lodash';
import React from 'react';
import i18next from 'i18next';
import * as yup from 'yup';
import { LicenseGroupData, Log } from 'app/store/types';
import { Translation, useTranslation } from 'react-i18next';

export const DEFAULT_WORKFLOWS_GROUP_ID = '10000001100110011001100000000001'; // '11111111111111111111111111111111'; // 10000001-1001-1001-1001-100000000001

// TODO::switch to `react-time-ago` component (need to solve importing locales)
export const daysAgo = (date: number) => {
	const numberOfDaysAgo = Math.round((new Date().getTime() - date) / (1000 * 60 * 60 * 24));
	switch (numberOfDaysAgo) {
		case 0:
			return <Translation>{t => t('dashboard:today')}</Translation>;
		case 1:
			return <Translation>{t => t('dashboard:yesterday')}</Translation>;
		default:
			return <Translation>{t => t('dashboard:days ago', { numberOfDaysAgo })}</Translation>;
	}
};

export const isValidEmail = (value: string) =>
	yup
		.string()
		.email()
		.required()
		.isValidSync(value);

// test for special characters and whitespace
export const isValidName = (value: string) => {
	if (noSpecialCharacters(value) && !isOnlyWhitespace(value)) {
		return true;
	}
	return false;
};

export const isValidPhoneNumber = (value: string) => /^[0-9.-]*$/.test(value);
// FIXME::Regex should be /^([0-9]+-?)*$/

export const isValidSlug = (value: string) => /^[a-zA-Z0-9]+([a-zA-Z0-9-]+[a-zA-Z0-9]+)?$/.test(value);

export const isOnlyWhitespace = (value: string) => value.match(/^ *$/) !== null;
export const noSpecialCharacters = (value: string) => !/[~`!#$%^&*+=\-[\]\\';,/{}|\\":<>?]/g.test(value);

export const getSubdomainsInReverseOrder = (url: string) => {
	url = url.replace(/(localhost|\[::1\]|127(?:\.[0-9]+){0,2}\.[0-9]+)(:\d+)?$/, 'localhost.com'); // fake "normal" localhost URL for easier parsing

	const { hostname } = new URL(url);

	const parts = hostname.split('.');

	const subdomainsInReverseOrder = parts.slice(0, -2).reverse();

	// treat `www` off the root as a fake subdomain
	if (subdomainsInReverseOrder[0] === 'www') {
		subdomainsInReverseOrder.shift();
	}

	return subdomainsInReverseOrder;
};

export const isRootUrl = (url: string) => getSubdomainsInReverseOrder(url).length === 0;
export const isLicenseGroupUrl = (url: string) => {
	const subdomains = getSubdomainsInReverseOrder(url);
	return subdomains.length === 2 && subdomains[0] === 'tenant';
};

export const sanitizeFilename = (name: string) => name.trim().replace(/[^a-z0-9\s]+/gi, '');

// export const getGenerationByFamily = (family: string): Device['generation'] => {
// 	// only use beginning of family name (for some reason it seems the family sometimes has a longer name)
// 	switch (family.split('_')[0]) {
// 		case 'Kronos':
// 		case 'Venus':
// 			return 'A4';
// 		case 'Amur':
// 		case 'Donau':
// 		case 'DonauBK':
// 		case 'Taiga':
// 			return 'IT3';
// 		case 'KronosS':
// 		case 'Poseidon':
// 		case 'ZeusS':
// 		case 'Helios':
// 		case 'MinervaSBK':
// 		case 'ZeusSZX0':
// 		case 'ZeusSBK':
// 		case 'VenusMLK':
// 			return 'IT5';
// 		case 'Altair':
// 		case 'Cypress':
// 		case 'DenebMLK':
// 		case 'EagleBKH':
// 		case 'EagleBKL':
// 		case 'EagleL':
// 		case 'EagleH':
// 		case 'EagleZPlus':
// 		case 'HeliosMLK':
// 		case 'Maple':
// 		case 'Rosewood':
// 		case 'Sparrow':
// 		case 'SparrowBK':
// 			return 'IT6';
// 		case 'WHUB':
// 			return 'workplacehub';
// 		default:
// 			return 'IT4';
// 	}
// };

export const arrayify = (value: any) => (Array.isArray(value) ? value : [value]);

// regular display: "11/2/2017, 12:13:42 PM"
// friendlyDisplay: "November 2, 2017"

export const getLocalTime = (
	date: ConstructorParameters<typeof Date>[0],
	friendlyDisplay = false,
	timezoneOffset?: number | null // expected to be in minutes
) => {
	if (date === undefined || date === null) {
		return undefined;
	}

	let localDate: Date;

	if (typeof timezoneOffset === 'number') {
		const timestamp = typeof date === 'number' ? date : new Date(date).getTime();
		const localTimestamp = timestamp + timezoneOffset * 60000; // apply offset, convert to milliseconds
		localDate = new Date(localTimestamp);
	} else {
		localDate = new Date(date);
	}

	let formattedDate: string;
	if (friendlyDisplay) {
		const year = localDate.getUTCFullYear();
		const month = localDate.getUTCMonth() + 1;
		const day = localDate.getUTCDate();

		const options: Intl.DateTimeFormatOptions = {
			year: 'numeric',
			month: 'long',
			day: 'numeric',
			timeZone: 'UTC'
		};

		formattedDate = new Date(Date.UTC(year, month - 1, day)).toLocaleDateString(i18next.language, options);
	} else {
		const options: Intl.DateTimeFormatOptions = {
			timeZone: 'UTC'
		};
		formattedDate = localDate.toLocaleString(i18next.language, options);
	}
	return formattedDate;
};

const deepOmitNullish = (obj: Record<string, any>): Record<string, any> =>
	Object.entries(obj)
		.filter(([_key, value]) => !_.isNil(value))
		.reduce(
			(acc, [key, value]) => ({
				...acc,
				[key]: _.isObject(value) ? deepOmitNullish(value) : value
			}),
			{}
		);

export const isEqualOmitNullish = (a: Record<string, any>, b: Record<string, any>) =>
	_.isEqual(deepOmitNullish(a), deepOmitNullish(b));

export const toHHMM = (time: string) => {
	let [HH, MM] = time.split(':');
	if (null || undefined) {
		return '';
	}
	if (Number(HH) < 10 && HH[0] !== '0') {
		HH = `0${HH}`;
	} else if (Number(HH) === 0) {
		HH = '00';
	}
	if (Number(MM) < 10 && MM[0] !== '0') {
		MM = `0${MM}`;
	} else if (Number(MM) === 0) {
		MM = '00';
	}

	return `${HH}:${MM}`;
};

// FIXME::need to combine `responseError` and `responseErrors` or just...make this less nonsense
type ResponseErrorsTypeA = { data: { returnCode: string }[] }[];
type ResponseErrorsTypeB = { data: { errors?: { add?: string[]; delete?: string[] } } }[];
const isResponseErrorsTypeA = (
	responses: ResponseErrorsTypeA | ResponseErrorsTypeB | any
): responses is ResponseErrorsTypeA => !!responses?.[0]?.data?.[0]?.returnCode;
const isResponseErrorsTypeB = (
	responses: ResponseErrorsTypeA | ResponseErrorsTypeB | any
): responses is ResponseErrorsTypeB => {
	const errors = responses?.[0]?.data?.errors;
	return !!(errors?.add || errors?.delete);
};
export const responseErrors = (responses: ResponseErrorsTypeA | ResponseErrorsTypeB | unknown) => {
	responses = arrayify(responses); // HACK-ish::just turn into an array if not one (this may need to be smartened up) - we can get away with this due to the `unknown` type
	if (isResponseErrorsTypeA(responses)) {
		const errors = responses
			.map(({ data }) => data)
			.flat()
			.filter(({ returnCode }) => `${returnCode}`[0] !== '2');
		if (errors.length) console.error(errors);
		return errors;
	}
	if (isResponseErrorsTypeB(responses)) {
		const errors = responses
			.map(({ data }) => (data.errors ? [...(data.errors.add || []), ...(data.errors.delete || [])] : []))
			.flat();
		if (errors.length) console.error(errors);
		return errors;
	}
	return [];
};

type ErrorOutput = {
	payload: {
		error: string;
		message: string;
		statusCode: number;
	};
	statusCode: number;
};
type ResponseError = { output: ErrorOutput }[] | null;
export const responseError = ({ errors }: { errors: ResponseError }) => {
	const error = errors?.[0].output;
	console.error(error);
	return error;
};

export const TLog = ({ log: { messageKey, info } }: { log: Pick<Log, 'messageKey' | 'info'> }) => {
	const { t: tL } = useTranslation('log');

	// if (messageKey.startsWith('taskStatus')) {
	// 	messageKey = `taskStatus${info!.status!.toUpperCase()}`;
	// 	// @ts-ignore
	// 	info = { ...info, task: info.task?.toLowerCase() };
	// }

	return <>{tL(messageKey, info)}</>;
};

const DEMO_FILE_COUNT_MAX = 50;
const DEMO_FILE_SIZE_MAX = 10485760; // 10MB (10,485,760 bytes)

export const getLicenseCapacityExceeded = (licenseGroupData: LicenseGroupData) => {
	const { licenseUsage, orderType } = licenseGroupData;
	if (!licenseUsage || orderType !== 'DEMO') {
		return false;
	}

	// Get 0:00AM on based on the browser timezone
	const today = new Date().setHours(0, 0, 0, 0);

	const isLicenseCapacityExceeded = !!(
		licenseUsage?.dateUpdated &&
		licenseUsage?.dateUpdated > today &&
		(licenseUsage?.fileCount > DEMO_FILE_COUNT_MAX || licenseUsage?.fileSizeTotal > DEMO_FILE_SIZE_MAX)
	);

	return isLicenseCapacityExceeded;
};

export const EXPIRED_GRACE_PERIOD: Record<LicenseGroupData['orderType'], number> = {
	PRODUCTION: 0,
	DEMO: 7 * (24 * 3600 * 1000), // 8 days
	NFR: 0
};

export const reviewAppSettings = () => {
	let formBuilderService = 'formbuilder';
	let formsService = 'forms-service';
	let wfxService = 'wfx';
	let metadataService = 'metadata-api';

	if (localStorage) {
		const settingsJson = localStorage.getItem('sec.review.portal.settings');
		if (settingsJson) {
			const settings = JSON.parse(settingsJson);
			if (settings?.formBuilderService) formBuilderService = settings.formBuilderService;
			if (settings?.formsService) formsService = settings.formsService;
			if (settings?.wfxService) wfxService = settings.wfxService;
			if (settings?.metadataService) metadataService = settings.metadataService;
		}
	}

	return {
		formBuilderService,
		formsService,
		metadataService,
		wfxService
	} as const;
};

export const getWfxUrl = (region = 'us-east-1'): string => {
	const supportedRegions = ['ap-northeast-1', 'eu-central-1', 'us-east-1'];
	const reviewApp = reviewAppSettings();

	if (region && supportedRegions.includes(region)) {
		return `https://${region}.${reviewApp.wfxService}.${process.env.REACT_APP_DOMAIN_NAME}`;
	}

	return process.env.REACT_APP_WFX_URL ?? `https://${reviewApp.wfxService}.${process.env.REACT_APP_DOMAIN_NAME}`;
};

export const getFormsUrl = (region = 'us-east-1'): string => {
	const supportedRegions = ['ap-northeast-1', 'eu-central-1', 'us-east-1'];
	const reviewApp = reviewAppSettings();

	if (region && supportedRegions.includes(region)) {
		return `https://${region}.${reviewApp.formsService}.${process.env.REACT_APP_DOMAIN_NAME}`;
	}

	return process.env.REACT_APP_FORMS_URL ?? `https://${reviewApp.formsService}.${process.env.REACT_APP_DOMAIN_NAME}`;
};

export const getMetadataUrl = (region = 'us-east-1'): string => {
	const supportedRegions = ['ap-northeast-1', 'eu-central-1', 'us-east-1'];
	const reviewApp = reviewAppSettings();

	if (region && supportedRegions.includes(region)) {
		return `https://${region}.${reviewApp.metadataService}.${process.env.REACT_APP_DOMAIN_NAME}`;
	}

	return (
		process.env.REACT_APP_METADATA_URL ??
		`https://${reviewApp.metadataService}.${process.env.REACT_APP_DOMAIN_NAME}`
	);
};

export const getLogApiUrl = (region = 'us-east-1'): string => {
	const logUrl = process.env.REACT_APP_LOGS_URL || 'https://74xi8a8vqk.execute-api.us-east-1.amazonaws.com/dev/v1';
	switch (process.env.REACT_APP_ENVIRONMENT) {
		case 'development':
			switch (region) {
				case 'ap-northeast-1':
					return 'https://0yuqzvrg23.execute-api.ap-northeast-1.amazonaws.com/dev/v1';
				case 'eu-central-1':
					return 'https://24lh9difwd.execute-api.eu-central-1.amazonaws.com/dev/v1';
				case 'us-east-1':
					return 'https://74xi8a8vqk.execute-api.us-east-1.amazonaws.com/dev/v1';
				default:
					return logUrl;
			}
		case 'staging':
			switch (region) {
				case 'ap-northeast-1':
					return 'https://a122mxfva7.execute-api.ap-northeast-1.amazonaws.com/beta/v1';
				case 'eu-central-1':
					return 'https://6rolr48wla.execute-api.eu-central-1.amazonaws.com/beta/v1';
				case 'us-east-1':
					return 'https://iih2e252sf.execute-api.us-east-1.amazonaws.com/beta/v1';
				default:
					return logUrl;
			}
		case 'production':
			switch (region) {
				case 'ap-northeast-1':
					return 'https://u865zi80h6.execute-api.ap-northeast-1.amazonaws.com/prod/v1';
				case 'eu-central-1':
					return 'https://a4b4sj539k.execute-api.eu-central-1.amazonaws.com/prod/v1';
				case 'us-east-1':
					return 'https://3oxcn76zx3.execute-api.us-east-1.amazonaws.com/prod/v1';
				default:
					return logUrl;
			}
		default:
			return logUrl;
	}
};

const checkDiff = (role: any, keys: any) => {
	if (typeof role === 'boolean') {
		return !role
	}

	for (const key of keys) {
		if (!checkDiff(role[key], Object.keys(role[key]))) {
			return false
		}
	}
	return true
}

const getKeys = (permissions1: any, permissions2: any, keys: any) => {
	if (typeof permissions1 === 'boolean' && typeof permissions2 === 'boolean') {
		return (permissions1 || (!permissions1 && !permissions2))
	}
	for (const key of keys) {
		const keys1 = Object.keys(permissions1[key]);
		const keys2 = Object.keys(permissions2[key]);
		const diff = _.difference(keys2, keys1);
		const same = _.difference(keys2, diff);
		if (diff.length && !checkDiff(permissions2[key], diff)) {
			return false
		}
		if (!getKeys(permissions1[key], permissions2[key], same)) {
			return false
		}
	}
	return true
}

export const canAssignRole = (permissions1: any, permissions2: any): boolean => {
	const keys1 = Object.keys(permissions1);
	const keys2 = Object.keys(permissions2);
	const diff = _.difference(keys2, keys1);
	const same = _.difference(keys2, diff);
	if (diff.length && !checkDiff(permissions2, diff)) {
		return false
	}
	return getKeys(permissions1, permissions2, same)
}