import _ from '@lodash';
import axios from 'app/client';
import { AxiosError } from 'axios';
import { getRegion } from 'app/store/reducers';
import { AppThunk } from 'app/store';
import { CatalogItem, PublicId } from 'app/store/types';
import * as appActions from './app.actions';

export const GET_CATALOG_SUCCESS = 'GET_CATALOG_SUCCESS';
export const GET_IS_SUPPORTED_REGION_SUCCESS = 'GET_IS_SUPPORTED_REGION_SUCCESS';

// HACK::publicId can come in upper- or lower-case
const normalizePublicId = (obj: { [publicId in PublicId]: CatalogItem }) =>
	Object.entries(obj).reduce(
		(acc, [key, value]) => ({
			...acc,
			[key.toUpperCase() as PublicId]: {
				...value,
				publicId: value.publicId.toUpperCase() as PublicId
			}
		}),
		{}
	);

export const getCatalog = (): AppThunk => async (dispatch, getState) => {
	const region = getRegion(getState());

	try {
		const {
			data: { currencyCode, pricing }
		} = await axios.get(`/api/mp/catalog/pricing?region=${region.length > 2 ? 'US' : region}`);
		dispatch({
			type: GET_IS_SUPPORTED_REGION_SUCCESS,
			payload: {
				isSupportedRegion: true
			}
		});
		dispatch({
			type: GET_CATALOG_SUCCESS,
			payload: {
				currency: currencyCode,
				catalog: _.mapValues(
					normalizePublicId(pricing),
					// for simplicity, add "defaultPrice" since ATM Personalize has no price ranges
					(catalogItem: CatalogItem) => ({
						...catalogItem,
						defaultPrice: catalogItem.priceRanges![0]!.amount
					})
				)
			}
		});
	} catch (error) {
		// if banned or unknown region
		if (error instanceof AxiosError &&
			(error.response?.status === 403 || error.response?.status === 404)) {
			dispatch({
				type: GET_IS_SUPPORTED_REGION_SUCCESS,
				payload: {
					isSupportedRegion: false
				}
			});
			return;
		}
		dispatch(appActions.handleError(error));
		// re-throw error for handling in <InitializeApp />
		throw error;
	}
};

type TStartCheckoutOptions = { quantity: number };
export const startCheckout = (publicId: PublicId, { quantity }: TStartCheckoutOptions): AppThunk => async dispatch => {
	try {
		const {
			data: { checkoutUrl }
		} = await axios.post('/api/mp/checkout/session', {
			catalogItems: {
				[publicId]: { quantity }
			}
		});
		window.location.assign(checkoutUrl);
	} catch (error) {
		if (error instanceof AxiosError && error.response?.status === 401) {
			// if user not logged in
			const redirectPath = `/pricing?checkout=${JSON.stringify({ publicId, quantity })}`;
			window.location.assign(`/api/sso/login?redirect=${encodeURIComponent(redirectPath)}`);
			return;
		}

		dispatch(appActions.handleError(error));
	}
};
