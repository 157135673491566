import localForage from 'localforage';
import { persistReducer } from 'redux-persist';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { Form, FormsState, FormsActionTypes } from 'app/store/types';
import * as Actions from '../actions';

const initialState: FormsState = {};

const formsReducer = (state = initialState, action: FormsActionTypes) => {
	switch (action.type) {
		case Actions.GET_LICENSE_GROUP_DATA_SUCCESS: {
			const {
				licenseGroupId,
				data: { forms }
			} = action.payload;

			return {
				...state,
				[licenseGroupId]: {
					byId: forms
				}
			};
		}
		case Actions.LOGGED_OUT_USER:
		case Actions.PURGE_STATE:
			return initialState;
		default: {
			return state;
		}
	}
};

// reducer
// const selectedFormReducer = (state: { [licenseGroupId: string]: Form } = {}, action: any) => {
// 	switch (action.type) {
// 		case GET_SELECTED_FORM_SUCCESS:
// 			return {
// 				...state,
// 				[action.payload.licenseGroupId]: action.payload.form
// 			};
// 		// ... other cases ...
// 		default:
// 			return state;
// 	}
// };

export default persistReducer(
	{
		key: 'forms',
		storage: localForage
	},
	formsReducer
);

// Selectors
export const getFormsById = ({ app: { selectedLicenseGroupId }, forms }: RootState) =>
	forms[selectedLicenseGroupId].byId;

export const getForms = createSelector([getFormsById], FormsById => Object.values(FormsById));

export const getFormById = (formId: Form['id']) => (state: RootState) => getFormsById(state)[formId];