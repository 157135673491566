import React from 'react';
import { connect } from 'app/modules/react-redux';
import ErrorMessageView from 'app/components/ErrorMessageView';
import * as Actions from 'app/store/actions';

const ERROR_ALREADY_SENT_PARAM = 'errorSent';

class ErrorBoundary extends React.Component {
	state = {
		error: false
	};

	componentDidCatch(error, info) {
		const { dispatch } = this.props;

		console.error({
			message: error.toString(),
			stack: error.stack,
			info
		});

		const errorAlreadySent = new URLSearchParams(window.location.search).has(ERROR_ALREADY_SENT_PARAM);
		if (!errorAlreadySent) {
			// TODO::setup API
			// dispatch(
			// 	Actions.errorLog({
			// 		message: error.toString(),
			// 		stack: error.stack,
			// 		info
			// 	})
			// );
		}

		this.setState({ error: true });
		dispatch(Actions.alert('something went wrong', 'error'));
		if (process.env.REACT_APP_ENVIRONMENT === 'production') {
			dispatch(Actions.purgeState());
		}
	}

	render() {
		const { children } = this.props;

		const errorAlreadySent = new URLSearchParams(window.location.search).has(ERROR_ALREADY_SENT_PARAM);

		if (this.state.error) {
			if (process.env.REACT_APP_ENVIRONMENT === 'production') {
				if (!errorAlreadySent) {
					window.location.assign(`/?${ERROR_ALREADY_SENT_PARAM}`); // try refreshing page (without resending error alert)
				}
			}
			return <ErrorMessageView />;
		}

		return children;
	}
}

export default connect()(ErrorBoundary);
