import ListItem from '@material-ui/core/ListItem';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import * as Actions from 'app/store/actions';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'app/modules/react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { IconButton, Tooltip } from '@material-ui/core';
import { Mood, MailOutline, Add } from '@material-ui/icons';

import HelpIconLine from '@material-ui/icons/HelpOutline';
import { useSelector } from 'react-redux';
import { getUserPreferences } from 'app/store/reducers';
import { UserPreferences } from 'app/store/types';
import { Link } from 'react-router-dom';
import { AddJobIcon, CreateWorkflowIcon, QuickLinksIcon } from 'app/components/Icons';

const useStyles = makeStyles(theme => ({
	item: {
		height: 40,
		borderRadius: 0,
		paddingRight: 12,
		paddingLeft: 24,
		border: '1px solid transparent', // required to prevent reflow when `active` class is added
		backgroundColor: 'transparent !important', // prevent navbar's hover effects
		// '&.active': {
		// 	border: '1px solid #7070705E',
		// 	backgroundColor: '#231E245E',
		// 	color: `${theme.palette.secondary.contrastText}!important`,
		// 	transition: 'border-radius .15s cubic-bezier(0.4,0.0,0.2,1)',
		// 	'& .list-item-text-primary': {
		// 		color: '#A48ADB',
		// 		fontWeight: 700
		// 	},
		// 	'& .list-item-icon': {
		// 		color: '#A48ADB'
		// 	}
		// },
		'& .list-item-icon': {
			marginRight: 16,
			transform: 'scale(1.5) translateX(-1px)' // HACK-ish::required to re-center the icon dues to the 1px border
		},
		'& .list-item-text': {},
		// cursor: 'pointer',
		textDecoration: 'none!important',
		'& .fill-icon': {
			display: 'none'
		},
		'&:hover .fill-icon, &.active .fill-icon': {
			display: 'inline-block'
		},
		'&:hover .line-icon, &.active .line-icon': {
			display: 'none'
		}
	}
}));

function NavVerticalQuickLinks() {
	// const userRole = useSelector(({ auth }) => auth.user.role);
	const dispatch = useDispatch();

	const theme = useTheme();
	const mdDown = useMediaQuery(theme.breakpoints.down('md'));
	const classes = useStyles();
	const { t, i18n } = useTranslation();

	const { quickLinks } = useSelector(getUserPreferences);

	const quickLinksMap: Record<
		NonNullable<UserPreferences['quickLinks'][number]>,
		{
			title: string;
			icon: React.ReactNode;
		} & (
			| { onClick: () => void; to?: never; href?: never }
			| { onClick?: never; to: string; href?: never }
			| { onClick?: never; to?: never; href: string }
		)
	> = {
		'add-task': {
			title: t('quick link:add-task'),
			icon: <AddJobIcon />,
			onClick: () => alert('CHANGEME::add task')
		},
		'create-workflow': {
			title: t('quick link:create-workflow'),
			icon: <CreateWorkflowIcon />,
			to: '/admin/workflows?createWorkflow'
		},
		'import-devices': {
			title: t('quick link:import-devices'),
			icon: <Add />,
			to: '/admin/devices?addDevices'
		},
		'invite-users': {
			title: t('quick link:invite-users'),
			icon: <MailOutline />,
			to: '/admin/users?addUsers'
		},
		customize: {
			title: t('quick link:customize'),
			icon: <Mood />,
			to: '/admin/profile'
		},
		help: {
			title: t('quick link:help'),
			icon: <HelpIconLine />,
			href: `${process.env.REACT_APP_HELP_URL}?locale=${i18n.language}`
		}
	};

	// const hasPermission = useMemo(() => FuseUtils.hasPermission(item.auth, userRole), [item.auth, userRole]);

	// if (!hasPermission) {
	// 	return null;
	// }

	// const IconComponent = item.Icon;
	const LineIconComponent = QuickLinksIcon;
	const FillIconComponent = QuickLinksIcon;
	if (!quickLinks.length) {
		return <></>;
	}

	return (
		<ListItem
			// button
			// component={NavLinkAdapter}
			// to={item.url}
			// activeClassName="active"
			className={clsx(classes.item, 'list-item mt-12 starter:text-white')}
			onClick={() => mdDown && dispatch(Actions.navbarCloseMobile())}
			// exact={item.exact}
		>
			{quickLinks.length > 1 && (
				<>
					{/* <IconComponent className="list-item-icon text-16 flex-shrink-0 transform scale-150" color="action" /> */}
					<LineIconComponent className="list-item-icon text-16 flex-shrink-0 transform scale-150 line-icon" />
					<FillIconComponent className="list-item-icon text-16 flex-shrink-0 transform scale-150 fill-icon" />
				</>
			)}

			<div
				style={{
					marginLeft: quickLinks.length > 1 ? undefined : -17 // align quick link with other navbar icons
				}}
			>
				{quickLinks.map(quickLink => {
					const { title, icon, onClick, to, href } = quickLinksMap[quickLink];
					if (onClick) {
						return (
							<Tooltip key={title} title={title}>
								<IconButton onClick={onClick}>{icon}</IconButton>
							</Tooltip>
						);
					}
					if (to) {
						return (
							<Tooltip key={title} title={title}>
								<IconButton component={Link} role="button" to={to}>
									{icon}
								</IconButton>
							</Tooltip>
						);
					}
					return (
						<Tooltip key={title} title={title}>
							<IconButton
								component="a"
								target="_blank"
								rel="noreferrer noopener"
								role="button"
								href={href}
							>
								{icon}
							</IconButton>
						</Tooltip>
					);
				})}
			</div>
		</ListItem>
	);
}

NavVerticalQuickLinks.propTypes = {
	item: PropTypes.shape({
		id: PropTypes.string.isRequired,
		title: PropTypes.node,
		icon: PropTypes.string,
		url: PropTypes.string
	})
};

NavVerticalQuickLinks.defaultProps = {};

// const NavVerticalItem = withRouter(React.memo(NavVerticalQuickLinks));
// const NavVerticalQuickLinks = withRouter(React.memo(NavVerticalQuickLinks)) as typeof NavVerticalQuickLinks;

export default NavVerticalQuickLinks;
