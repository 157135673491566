import React from 'react';

const FormsPageConfig = {
	settings: {
		layout: {
			config: {
				adminPage: true
			}
		}
	},
	routes: [
		{
			path: '/admin/forms',
			exact: true,
			component: React.lazy(() => import('./FormsPage'))
		},
		{
			path: '/admin/forms/create',
			exact: true,
			component: React.lazy(() => import('./FormPage'))
		}
	]
};

export default FormsPageConfig;
