import FuseAnimate from '@fuse/core/FuseAnimate';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { Link } from 'react-router-dom';
import usePageTitle from 'app/hooks/usePageTitle';
import { useTranslation } from 'react-i18next';

function Error404Page() {
	const { t } = useTranslation();
	usePageTitle('404');

	return (
		<div className="flex flex-col flex-1 items-center justify-center p-16">
			<div className="max-w-512 text-center">
				<FuseAnimate animation="transition.expandIn" delay={100}>
					<Typography variant="h1" color="inherit" className="font-medium mb-16">
						404
					</Typography>
				</FuseAnimate>

				<FuseAnimate delay={500}>
					<Typography variant="h5" color="textSecondary" className="mb-48">
						{t('error404:title')}
					</Typography>
				</FuseAnimate>

				<Link className="font-medium" to="/">
					{t('go back')}
				</Link>
			</div>
		</div>
	);
}

export default Error404Page;
