/* eslint-disable import/no-cycle */
import axios from 'app/client';
import { getProfile, getSelectedLicenseGroupId, getWorkflows, getWorkflowsById } from 'app/store/reducers';
import { getLogApiUrl, getWfxUrl, responseErrors, sanitizeFilename } from 'app/utils/helpers';

import { AppThunk } from 'app/store';
import { Schedule, User, Workflow, WorkflowData, WorkflowGroup } from 'app/store/types';
import React from 'react';
import * as appActions from './app.actions';
import * as licenseGroupsActions from './licenseGroups.actions';

export const GET_SELECTED_WORKFLOW_LOGS = 'GET_SELECTED_WORKFLOW_LOGS';
export const GET_WORKFLOW_DATA = 'GET_WORKFLOW_DATA';

export const prepNavFn = () => {
	const el = document.querySelector('#main-content');
	return el?.scrollTop;
};

export const navFn = (yCoord?: number) => {
	const el = document.querySelector('#main-content');
	if (el && yCoord) {
		el.scrollTo(0, yCoord);
	}
};

export const addWorkflowsToWorkflowGroup = (
	workflowIds: string[],
	workflowGroupId: string | undefined,
	saveWorkflows?: boolean
): AppThunk => async (dispatch, getState) => {
	const profile = getProfile(getState());
	const wfxApiUrl = getWfxUrl(profile?.awsRegion);

	const params = new URLSearchParams();
	params.append('group', workflowGroupId ?? '');

	const yPos = prepNavFn();

	try {
		const responses = await Promise.all(
			workflowIds.map(workflowId =>
				axios.post(`${wfxApiUrl}/api/wfx/${workflowId}/group`, params, {
					transformRequest: data => data,
					headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
				})
			)
		);

		if (responseErrors(responses).length) {
			dispatch(appActions.alert('failed to add some workflows to workflow group', 'warning'));
		} else {
			dispatch(
				appActions.alert(
					saveWorkflows ? 'group deleted and workflows moved' : 'workflows added to workflow group',
					'success'
				)
			);
		}
		dispatch(licenseGroupsActions.getSelectedLicenseGroupData(() => navFn(yPos)));
	} catch (error) {
		dispatch(appActions.handleError(error));
	}
};

// export const addWorkflows = (workflowIds: string[]): AppThunk => async (dispatch, getState) => {
// 	const state = getState();
// 	const licenseGroupId = getSelectedLicenseGroupId(state);

// 	const workflowsData = getWorkflowsDataHack(licenseGroupId, workflowIds, getMpWorkflows(getState())!);

// 	try {
// 		const responses = await Promise.all(
// 			_.chunk(workflowsData, 25).map(chunk => axios.post(`/api/v1/workflow`, chunk))
// 		);

// 		// TODO::we're not handling `responseError` vs `responseErrors` correct in general - both in the shape they expect and which we use per action
// 		if (responseErrors(responses).length) {
// 			if (
// 				responseErrors(responses).some(
// 					(error: string | { returnCode: string }) =>
// 						typeof error !== 'string' && `${error.returnCode}` === '402'
// 				)
// 			) {
// 				dispatch(appActions.alert('failed to add some workflows due to workflow limit', 'warning'));
// 			} else {
// 				dispatch(appActions.alert('failed to add some workflows', 'warning'));
// 			}
// 		} else {
// 			dispatch(appActions.alert('workflows added', 'success'));
// 		}
// 		dispatch(licenseGroupsActions.getSelectedLicenseGroupData());
// 	} catch (error) {
// 		dispatch(appActions.handleError(error));
// 	}
// };

export const removeWorkflows = (workflowIds: string[]): AppThunk => async (dispatch, getState) => {
	const profile = getProfile(getState());
	const wfxApiUrl = getWfxUrl(profile?.awsRegion);

	const yPos = prepNavFn();

	try {
		// const responses = await Promise.all(
		// 	_.chunk(workflowIds, 25).map(chunk =>
		// 		axios.delete(`/api/v1/workflow/${licenseGroupId}`, {
		// 			params: { workflowId: chunk },
		// 			paramsSerializer: params => qs.stringify(params, { arrayWorkflowat: 'brackets' })
		// 		})
		// 	)
		// );
		// TEMP::missing bulk delete so looping per action
		const responses = await Promise.all(
			workflowIds.map(workflowId => axios.delete(`${wfxApiUrl}/api/wfx/${workflowId}`))
		);
		if (responseErrors(responses).length) {
			dispatch(appActions.alert('failed to remove some workflows', 'warning'));
		} else {
			dispatch(licenseGroupsActions.getSelectedLicenseGroupData(() => navFn(yPos)));
			dispatch(appActions.alert(workflowIds.length > 1 ? 'workflows removed' : 'workflow removed', 'success'));
		}
		// dispatch(licenseGroupsActions.getSelectedLicenseGroupData());
	} catch (error) {
		dispatch(appActions.handleError(error));
	}
};

export const getSelectedWorkflowsData = (workflowIds: Workflow['id'][]): AppThunk => async (dispatch, getState) => {
	const profile = getProfile(getState());
	const wfxApiUrl = getWfxUrl(profile?.awsRegion);

	try {
		const responses = await Promise.all(
			workflowIds.map(workflowId => axios.get(`${wfxApiUrl}/api/wfx/${workflowId}.json`))
		);
		const licenseGroupId = getSelectedLicenseGroupId(getState());
		const workflows = getWorkflows(getState());

		const massageRawWorkflowData = (rawWorkflowData: any, workflowId: string) => {
			const workflowData: WorkflowData = {
				...licenseGroupsActions.massageRawWorkflow(rawWorkflowData),
				...workflows.find(x => x.id === workflowId),
				schedule: rawWorkflowData.schedules[0]
			};

			return workflowData;
		};

		responses.map((response, i) => {
			return dispatch({
				type: GET_WORKFLOW_DATA,
				payload: {
					licenseGroupId,
					workflowId: workflowIds[i],
					data: massageRawWorkflowData(response.data, workflowIds[i])
				}
			});
		});
	} catch (error) {
		dispatch(appActions.alert('failed to retrieve workflow data', 'warning'));
	}
};

export const updateWorkflowsSchedule = (workflowIds: Workflow['id'][], schedules: Schedule[]): AppThunk => async (
	dispatch,
	getState
) => {
	const profile = getProfile(getState());
	const wfxApiUrl = getWfxUrl(profile?.awsRegion);
	try {
		const unifiedSchedulesBasedOnFirst = schedules.map(schedule => ({
			...schedules[0],
			id: schedule.id
		}));
		const responses = await Promise.all(
			workflowIds.map((workflowId, index) =>
				axios.post(`${wfxApiUrl}/api/wfx/${workflowId}/schedule`, [unifiedSchedulesBasedOnFirst[index]])
			)
		);
		if (responseErrors(responses).length) {
			dispatch(appActions.alert('failed to update some workflows schedule', 'warning'));
		} else {
			dispatch(appActions.alert('workflows schedule updated', 'success'));
		}
		dispatch(getSelectedWorkflowsData(workflowIds));
	} catch (error) {
		dispatch(appActions.handleError(error));
	}
};

export const getSelectedWorkflowLogs = (
	workflowId: Workflow['id'],
	fetchMore = false,
	allDataFetchedCallback?: () => void
): AppThunk => async (dispatch, getState) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());
	const selectedWorkflowLogs = getWorkflowsById(getState())[workflowId].logs;
	const profile = getProfile(getState());
	const logApiUrl = getLogApiUrl(profile?.awsRegion);

	const dateLimit = !fetchMore
		? new Date()
		: selectedWorkflowLogs
		? new Date(new Date(selectedWorkflowLogs[selectedWorkflowLogs.length - 1].dateCreated).getTime() - 5) // HACK-ish: subtract 5 milliseconds to get older logs (not using `1` due to a slight timing issue difference that can allow duplicate logs through otherwise)
		: new Date();

	try {
		const response = await axios.get(
			`${logApiUrl}/log/${licenseGroupId}/message?limit=50&from=${new Date(
				0
			).toISOString()}&to=${dateLimit.toISOString()}&type=workflow&id=${workflowId}`
		);
		const newLogs = response.data.Items.map((x: any) => {
			return {
				messageKey: x.MsgKey,
				type: x.Type,
				id: x.SK,
				dateCreated: x.DateAdded,
				event: `${x.Action[0].toUpperCase()}${x.Action.substring(1)}`,
				info: x.Info || {},
				logLevel: x.LogLevel
			};
		});
		const allDataFetched = !response.data.LastEvaluatedKey;
		if (allDataFetched) {
			allDataFetchedCallback?.();
		}
		dispatch({
			type: GET_SELECTED_WORKFLOW_LOGS,
			payload: {
				licenseGroupId,
				workflowId,
				data: fetchMore ? [...(selectedWorkflowLogs ?? []), ...newLogs] : newLogs
			}
		});
	} catch (error) {
		dispatch(appActions.alert('failed to retrieve workflow logs', 'warning'));
	}
};

export const changeWorkflowStatus = (
	workflowIds: Workflow['id'][],
	status: Workflow['status'],
	drafts: number = 0
): AppThunk => async (dispatch, getState) => {
	const profile = getProfile(getState());
	const wfxApiUrl = getWfxUrl(profile?.awsRegion);
	const yPos = prepNavFn();
	try {
		const params = new URLSearchParams();
		params.append('status', status);
		const responses = await Promise.all(
			workflowIds.map(workflowId =>
				axios.post(`${wfxApiUrl}/api/wfx/${workflowId}/status`, params, {
					transformRequest: data => data,
					headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
				})
			)
		);

		if (responseErrors(responses).length) {
			dispatch(appActions.alert('failed to change some workflow statuses', 'warning'));
		} else {
			dispatch(
				appActions.alert(
					drafts === 0 ? 'workflow status updated' : 'workflow status update draft unchanged',
					'success'
				)
			);
		}
		dispatch(licenseGroupsActions.getSelectedLicenseGroupData(() => navFn(yPos)));
	} catch (error) {
		dispatch(appActions.handleError(error));
	}
};

export const changeWorkflowOwner = (workflowIds: Workflow['id'][], ownerId: User['id']): AppThunk => async (
	dispatch,
	getState
) => {
	const state = getState();
	const profile = getProfile(getState());
	const wfxApiUrl = getWfxUrl(profile?.awsRegion);
	const yPos = prepNavFn();
	try {
		const responses = await Promise.all(
			workflowIds.map(workflowId => {
				const prevAcl = getWorkflowsById(state)[workflowId].acl;
				const acl = {
					...prevAcl,
					users: [
						...prevAcl.users
							// remove the new owner's old role (if they happened to have one)
							.filter(({ name }) => name !== ownerId)
							// remove the old owner
							.filter(({ role }) => role !== 'owner'),
						// add the new owner
						{
							name: ownerId,
							role: 'owner' as const
						}
					]
				};
				return axios.post(`${wfxApiUrl}/api/wfx/${workflowId}/acl`, acl);
			})
		);

		if (responseErrors(responses).length) {
			dispatch(appActions.alert('failed to change Workflow owner', 'warning'));
		} else {
			dispatch(appActions.alert('workflow owner updated', 'success'));
		}
		dispatch(licenseGroupsActions.getSelectedLicenseGroupData(() => navFn(yPos)));
	} catch (error) {
		dispatch(appActions.handleError(error));
	}
};

export const shareWorkflow = (
	workflowIds: Workflow['id'][],
	{
		users,
		userGroups,
		devices,
		deviceGroups
	}: {
		users: { [userId: string]: { role: Workflow['acl']['users'][0]['role'] } };
		userGroups: { [userGroupId: string]: { role: Workflow['acl']['groups'][0]['role'] } };
		devices?: { [deviceId: string]: { role: Workflow['acl']['devices'][0]['role'] } };
		deviceGroups?: { [deviceGroupId: string]: { role: Workflow['acl']['deviceGroups'][0]['role'] } };
	}
): AppThunk => async (dispatch, getState) => {
	const state = getState();
	const profile = getProfile(getState());
	const wfxApiUrl = getWfxUrl(profile?.awsRegion);

	try {
		const responses = await Promise.all(
			workflowIds.map(workflowId => {
				const prevAcl = getWorkflowsById(state)[workflowId].acl;
				const acl = {
					...prevAcl,
					users: [
						...Object.entries(users).map(([userId, { role }]) => ({
							name: userId,
							role
						})),
						// also include the owner
						prevAcl.users.find(user => user.role === 'owner')!
					],
					groups: Object.entries(userGroups).map(([userGroupId, { role }]) => ({
						name: userGroupId,
						role
					})),
					devices: devices
						? Object.entries(devices).map(([deviceId, { role }]) => ({
								name: deviceId,
								role
						  }))
						: [],
					deviceGroups: deviceGroups
						? Object.entries(deviceGroups).map(([deviceGroupId, { role }]) => ({
								name: deviceGroupId,
								role
						  }))
						: []
				};
				return axios.post(`${wfxApiUrl}/api/wfx/${workflowId}/acl`, acl);
			})
		);

		if (responseErrors(responses).length) {
			dispatch(appActions.alert('failed to share workflows', 'warning'));
			return true;
		}
		dispatch(appActions.alert('workflows shared', 'success'));
		return true;
	} catch (error) {
		dispatch(appActions.handleError(error));
	}

	return false;
};

export const unshareWorkflow = (
	workflowIds: Workflow['id'][],
	userId: string,
	successFn?: () => void
): AppThunk => async (dispatch, getState) => {
	const profile = getProfile(getState());
	const wfxApiUrl = getWfxUrl(profile?.awsRegion);
	const data: any[] = [];
	workflowIds.forEach(workflowId => {
		data.push({ id: workflowId });
	});
	const response = await axios.post(`${wfxApiUrl}/api/acl`, data, {
		params: { name: userId, role: 'none', kind: 'user' }
	});
	if (responseErrors(response).length) {
		dispatch(appActions.alert('failed to unshare workflows', 'warning'));
	} else {
		dispatch(appActions.alert('workflows unshared', 'success'));
		dispatch(licenseGroupsActions.getSelectedLicenseGroupData(successFn));
	}
	// dispatch(licenseGroupsActions.getSelectedLicenseGroupData());
};

export const importWorkflow = (
	file: any,
	group?: string,
	setLoading: React.Dispatch<React.SetStateAction<boolean>> = () => {},
	setFile: React.Dispatch<React.SetStateAction<any>> = () => {}
): AppThunk => async (dispatch, getState) => {
	const profile = getProfile(getState());
	const wfxApiUrl = getWfxUrl(profile?.awsRegion);
	const yPos = prepNavFn();
	// upload
	const convertToFormData = (obj: { [name: string]: string }) => {
		const formData = new FormData();
		Object.entries(obj).forEach(([name, value]) => formData.append(name, value));
		return formData;
	};
	try {
		const data = {
			file,
			group
		};
		if (!group) {
			delete data.group;
		}
		const response = await axios
			.post(`${wfxApiUrl}/api/zip`, data, {
				transformRequest: convertToFormData, // data => data,
				headers: { 'Content-Type': 'multipart/form-data' } // { 'Content-Type': 'application/x-www-form-urlencoded' }
			})
			.then(res => {
				setLoading(false);
				setFile(null);
				return res;
			});
		if (responseErrors(response).length) {
			dispatch(appActions.alert('failed to import workflows', 'warning'));
		} else {
			dispatch(appActions.alert('workflow imported', 'success'));
		}
		dispatch(licenseGroupsActions.getSelectedLicenseGroupData(() => navFn(yPos)));
		return response;
	} catch (error) {
		setLoading(false);
		dispatch(appActions.handleError(error));
	}

	return null;
};

export const exportWorkflow = (workflowIds: string[], keepPassword: boolean): AppThunk => async (
	dispatch,
	getState
) => {
	const profile = getProfile(getState());
	const wfxApiUrl = getWfxUrl(profile?.awsRegion);
	const workflowsById = getWorkflowsById(getState());
	// download
	try {
		const mapQuery = (arr: string[]) => {
			return arr.map(str => `id=${str}`).join('&');
		};
		const endpoint = `${wfxApiUrl}/api/zip?password=${keepPassword}&${mapQuery(workflowIds)}`;
		const response = await axios.get(endpoint);
		if (responseErrors(response).length) {
			dispatch(appActions.alert('failed to export workflows', 'warning'));
		} else {
			downloadFile(
				endpoint,
				workflowIds.length === 1
					? sanitizeFilename(workflowsById[workflowIds[0]].name)
					: `My Workflows_${new Date().toISOString().split('T')[0]}`
			);
			dispatch(appActions.alert(workflowIds.length > 1 ? 'workflows exported' : 'workflow exported', 'success'));
		}
	} catch (error) {
		dispatch(appActions.handleError(error));
	}
};

const downloadFile = (url: string, name?: string) =>
	axios.get(url, { responseType: 'blob' }).then(response => {
		const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
		const link = document.createElement('a');
		link.href = downloadUrl;
		link.setAttribute('download', name ? `${name}.wfxc` : '');
		document.body.appendChild(link);
		link.click();
		link.remove();
	});

export const getWorkflow = async (workflowId: string, awsRegion = 'us-east-1') => {
	const wfxApiUrl = getWfxUrl(awsRegion);
	try {
		const endpoint = `${wfxApiUrl}/api/zip?password=false&id=${workflowId}`;
		return await axios.get(endpoint, { responseType: 'blob' });
	} catch (error) {
		console.error('error:', error);
	}
};

export const cloneWorkflows = (
	workflowIds: string[],
	workflowGroupId: WorkflowGroup['id'] | undefined
): AppThunk => async (dispatch, getState) => {
	const profile = getProfile(getState());
	const wfxApiUrl = getWfxUrl(profile?.awsRegion);
	const yPos = prepNavFn();

	try {
		const responses = await Promise.all(
			// workflowIds.map(workflowId => axios.post(`${process.env.REACT_APP_WFX_URL}/api/wfx/${workflowId}`))
			workflowIds.map(workflowId =>
				axios.request({
					method: 'COPY' as any,
					url: `${wfxApiUrl}/api/wfx/${workflowId}?group=${workflowGroupId}`
				})
			)
		);
		if (responseErrors(responses).length) {
			dispatch(appActions.alert('failed to clone some workflows', 'warning'));
		} else {
			dispatch(appActions.alert('workflows cloned', 'success'));
		}
		dispatch(licenseGroupsActions.getSelectedLicenseGroupData(() => navFn(yPos)));
	} catch (error) {
		dispatch(appActions.handleError(error));
	}
};
